import { isDonor, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { AttenderListType, ExportJobViewBy, useGivingDashboardDonorDevOnlyQuery } from '@src/graphql/generated';
import { useBuildGaugeData, usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';

import { useDonorDevOnlyDefinitions } from './useDonorDevOnlyDefinitions';
import { useDonorRecentNewData } from '../useDonorRecentNewData';
import { useLastAndPreviousDates } from '../useLastAndPreviousDates';
import { buildSnapshotData, getGivingCount } from '../utils';

export const useGivingDashboardDonorDevOnly = (viewByMode: ExportJobViewBy) => {
  const { key } = useMyOrganization();

  const getPeopleListFilters = usePeopleListFilters();
  const { filters: unarchivedIndividualsFilters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);
  const { previousWeekComparedToTodayDateRange } = useLastAndPreviousDates();
  const donorDevOnlyDefinitions = useDonorDevOnlyDefinitions(viewByMode);
  const buildGaugeData = useBuildGaugeData();

  const donorPreviousDates = {
    startDate: previousWeekComparedToTodayDateRange.startDate,
    endDate: previousWeekComparedToTodayDateRange.endDate,
  };
  const { donorRecentNewLastDates, donorRecentNewFilters, donorRecentNewPrevDates } = useDonorRecentNewData();

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const { data, error, loading } = useGivingDashboardDonorDevOnlyQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: 0,
      },
      donorRecentNewLastParams: {
        endDate: donorRecentNewLastDates.endDate,
        filters: donorRecentNewFilters,
        startDate: donorRecentNewLastDates.startDate,
      },
      donorRecentNewPrevParams: {
        endDate: donorRecentNewPrevDates.endDate,
        filters: donorRecentNewFilters,
        startDate: donorRecentNewPrevDates.startDate,
      },
      donorLastQueryParams: {
        filters: [...unarchivedIndividualsFilters, isDonor],
      },
      donorPreviousQueryParams: {
        endDate: donorPreviousDates.endDate,
        filters: [...unarchivedIndividualsFilters, isDonor],
        startDate: donorPreviousDates.startDate,
      },
      allIndividualsQueryParams: {
        filters: unarchivedIndividualsFilters,
      },
    },
  });

  const allEligible =
    data && data.AllInsightEligibleIndividuals?.communityMembersCursor?.aggregations.All !== undefined
      ? data.AllInsightEligibleIndividuals?.communityMembersCursor?.aggregations.All
      : null;
  const givingCount = getGivingCount(data);

  return {
    error,
    loading,
    data: {
      gauge: buildGaugeData(ViewByDomain.Donors, allEligible, givingCount),
      snapshots: buildSnapshotData(ViewByDomain.Donors, donorDevOnlyDefinitions, loading, data, viewByMode),
    },
  };
};
