import { DonorListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { usePeopleListFilters } from '../usePeopleListFilters';

export const useDonorRecentNewData = () => {
  const getPeopleListFilters = usePeopleListFilters();
  const {
    startDate: donorRecentNewStartDate,
    endDate: donorRecentNewEndDate,
    filters: donorRecentNewFilters,
  } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.RecentNew);
  const donorRecentNewLastDates = { startDate: donorRecentNewStartDate, endDate: donorRecentNewEndDate };
  const donorRecentNewPrevDates = {
    startDate: dateFns.subWeeks(donorRecentNewStartDate as Date, 1),
    endDate: dateFns.subWeeks(donorRecentNewEndDate as Date, 1),
  };

  return {
    donorRecentNewLastDates,
    donorRecentNewFilters,
    donorRecentNewPrevDates,
  };
};