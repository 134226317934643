import * as React from 'react';

import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { GaugeData } from '@src/components/SnapshotsCard/types';
import colors, { graphGrey } from '@src/constants/colors';
import useTranslation from '@src/translations';

export const useBuildGaugeData = () => {
  const { translate } = useTranslation();

  const getDomainLabels = React.useCallback(
    (domain: ViewByDomain) => {
      switch (domain) {
        case ViewByDomain.Attenders:
          return {
            primaryLabel: translate(`ratioChart.attenders.attending.label`),
            secondaryLabel: translate(`ratioChart.attenders.notAttending.label`),
            percentageLabel: translate(`ratioChart.attenders.label`),
          };
        case ViewByDomain.Donors:
          return {
            primaryLabel: translate(`ratioChart.donors.giving.label`),
            secondaryLabel: translate(`ratioChart.donors.notGiving.label`),
            percentageLabel: translate(`ratioChart.donors.label.CommunityMember`),
          };
        case ViewByDomain.Volunteers:
          return {
            primaryLabel: translate(`ratioChart.volunteers.serving.label`),
            secondaryLabel: translate(`ratioChart.volunteers.notServing.label`),
            percentageLabel: translate(`ratioChart.volunteers.label`),
          };
        default:
          throw Error(`Unknown domain: ${domain}`);
      }
    },
    [translate]
  );

  const buildGaugeData = React.useCallback(
    (domain: ViewByDomain, allEligibleIndividuals: number | null, activeStagesCount: number | null): GaugeData => {
      const gaugeColor = colors[domain].graph;

      const { primaryLabel, secondaryLabel, percentageLabel } = getDomainLabels(domain);

      return {
        percentageLabel,
        primaryValue: {
          color: gaugeColor,
          id: 'primary',
          label: primaryLabel,
          value: activeStagesCount,
        },
        secondaryValue: {
          color: graphGrey,
          id: 'secondary',
          label: secondaryLabel,
          value:
            allEligibleIndividuals !== null && activeStagesCount !== null
              ? allEligibleIndividuals - activeStagesCount
              : null,
        },
      };
    },
    [getDomainLabels]
  );

  return buildGaugeData;
};
