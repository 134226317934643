import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { ExportJobViewBy } from '@src/graphql/generated';

import { useAttenderPeopleListTotalsLazyQuery } from './hooks/useAttenderPeopleListTotalsLazyQuery';
import { useDonorPeopleListTotalsLazyQuery } from './hooks/useDonorPeopleListTotalsLazyQuery';
import { useVolunteerPeopleListTotalsLazyQuery } from './hooks/useVolunteerPeopleListTotalsLazyQuery/useVolunteerPeopleListTotalsLazyQuery';

export type PeopleListTotals = Partial<Record<ListType, number>>;
export type PeopleListTotalsResult = {
  loading: boolean;
  error: ApolloError[];
  totals: PeopleListTotals;
};

export function usePeopleListTotalsLazyQuery(
  viewByDomain: ViewByDomain,
  viewByMode: ExportJobViewBy
): [() => Promise<PeopleListTotalsResult>, PeopleListTotalsResult] {
  const [error, setError] = React.useState([] as ApolloError[]);
  const [loading, setLoading] = React.useState(false);
  const [totals, setTotals] = React.useState({} as PeopleListTotals);
  const [getAttenderTotals] = useAttenderPeopleListTotalsLazyQuery(viewByMode);
  const [getDonorTotals] = useDonorPeopleListTotalsLazyQuery(viewByMode);
  const [getVolunteerTotals] = useVolunteerPeopleListTotalsLazyQuery(viewByMode);

  const getPeopleListTotals = React.useCallback(async () => {
    setLoading(true);
    let result: PeopleListTotalsResult;
    switch (viewByDomain) {
      case ViewByDomain.Attenders:
        result = await getAttenderTotals();
        break;
      case ViewByDomain.Donors:
        result = await getDonorTotals();
        break;
      case ViewByDomain.Volunteers:
        result = await getVolunteerTotals();
        break;
      default:
        throw new Error('Unknown domain');
    }

    setError(result.error);
    setTotals(result.totals);
    setLoading(false);

    return { loading: false, error: result.error, totals: result.totals };
  }, [getAttenderTotals, getDonorTotals, getVolunteerTotals, viewByDomain]);

  return [
    getPeopleListTotals,
    {
      loading,
      error,
      totals,
    },
  ];
}
