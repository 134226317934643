import * as React from 'react';

import { TrendProps } from '@pushpay/charts';

import useTranslation from '@src/translations';
import { Direction } from '@src/types/Trend';

export const useGetTrendProps = () => {
  const { translate } = useTranslation();

  const getGoodDirection = React.useCallback(
    (directionToCheck: Direction) => (directionToCheck === Direction.Flat ? undefined : directionToCheck),
    []
  );

  const buildTrendData = React.useCallback(
    (current: number, goodDirection: Direction, previous: number, trendUnavailable = false): TrendProps => ({
      fromValue: previous,
      directionIsGood: getGoodDirection(goodDirection),
      labels: {
        increase: translate('trend.label.increase'),
        decrease: translate('trend.label.decrease'),
        noChange: translate('trend.label.noChange'),
        noComparableData: translate('trend.label.noComparableData'),
      },
      tooltip: {
        content: trendUnavailable
          ? translate('trend.tooltip.noComparisonPossible')
          : translate('trend.tooltip.default'),
        placement: 'top',
        preventOnClickWhenTouched: true,
      },
      toValue: current,
    }),
    [getGoodDirection, translate]
  );

  const getTrendProps = React.useCallback(
    (direction: Direction, last: number | number[], previous: number | number[], trendUnavailable = false) => {
      if (Array.isArray(last) && Array.isArray(previous)) {
        return buildTrendData(last[0], direction, previous[0], trendUnavailable);
      }

      if (typeof last === 'number' && typeof previous === 'number') {
        return buildTrendData(last, direction, previous, trendUnavailable);
      }

      return undefined;
    },
    [buildTrendData]
  );

  return getTrendProps;
};
