import * as React from 'react';

import { DateConfigContext } from '@src/contexts/DateConfigContext';

export function useDateConfig() {
  const context = React.useContext(DateConfigContext);
  if (!context) {
    throw new Error('useDateConfig must be used within a DateConfigProvider');
  }
  return context;
}
