import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useServingDashboardTilesQuery } from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

import { useVolunteerTileDefinitions } from './useVolunteerTileDefinitions';
import { useLastAndPreviousDates } from '../useLastAndPreviousDates';
import { buildSnapshotData } from '../utils';

export const useServingTiles = () => {
  const { key } = useMyOrganization();
  const volunteerTileDefinitions = useVolunteerTileDefinitions();

  const { selectedMembershipTypes } = useDataCustomization();

  const { lastCompletedWeekDateRange, previousCompletedWeekDateRange } = useLastAndPreviousDates();

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const { data, error, loading } = useServingDashboardTilesQuery({
    variables: {
      organizationKey: key,
      lastServingSnapshotsInput: {
        dateRange: lastCompletedWeekDateRange,
        filters: { membershipTypeIds: selectedMembershipTypes },
      },
      previousServingSnapshotsInput: {
        dateRange: previousCompletedWeekDateRange,
        filters: { membershipTypeIds: selectedMembershipTypes },
      },
    },
  });

  return {
    error,
    loading,
    data: buildSnapshotData(ViewByDomain.Volunteers, volunteerTileDefinitions, loading, data),
  };
};
