import { differenceInDays } from 'date-fns';

import { PaymentQueryGranularity } from '@src/graphql/generated';

export const getGranularity = (endDate: Date, startDate: Date): PaymentQueryGranularity => {
  const dateDifferenceInDays = differenceInDays(endDate, startDate);

  // Business rule: 31 days is cutoff for daily granularity
  if (dateDifferenceInDays <= 31) {
    return PaymentQueryGranularity.Day;
  }

  // Business rule: 26 weeks is cutoff for weekly granularity
  const weekLimitInDays = 26 * 7;
  if (dateDifferenceInDays <= weekLimitInDays) {
    return PaymentQueryGranularity.Week;
  }

  return PaymentQueryGranularity.Month;
};
