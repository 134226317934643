import { Frequency } from '@churchcommunitybuilder/js-utils';

import { ChartData } from '@src/types/ChartAndHighlights';

import { generateChartLabelsAndDates, getLargerChartData, getPointTooltip } from './chartUtils';
import { ChartKey, LineChartLineData, TooltipData } from '../types';

export const getTransformedLineChartData = (
  frequency: Frequency,
  lastChartData: ChartData,
  previousChartData?: ChartData
): { tooltips: TooltipData[]; lineChartData: LineChartLineData[] } => {
  if (!lastChartData.length && !previousChartData?.length) {
    return { tooltips: [], lineChartData: [] };
  }

  const largerChartData = getLargerChartData(lastChartData, previousChartData);
  const labelsAndDates = generateChartLabelsAndDates(frequency, lastChartData[0].endDate, largerChartData.length);
  const tooltips = largerChartData.map((_, index) => getPointTooltip(lastChartData[index], previousChartData?.[index]));

  const lastLineChartData = lastChartData.map((point, index) => ({
    x: labelsAndDates[index].label,
    y: point.value,
    date: labelsAndDates[index].date,
  }));

  const previousLineChartData = previousChartData?.map((point, index) => ({
    x: labelsAndDates[index].label,
    y: point.value,
    date: labelsAndDates[index].date,
  }));

  if (previousLineChartData) {
    return {
      tooltips,
      lineChartData: [
        {
          id: ChartKey.Previous,
          data: previousLineChartData,
        },
        {
          id: ChartKey.Last,
          data: lastLineChartData,
        },
      ],
    };
  }

  return {
    tooltips,
    lineChartData: [
      {
        id: ChartKey.Last,
        data: lastLineChartData,
      },
    ],
  };
};
export const getLinePointIndex = (point: string) => parseInt(point.split('.')[1], 10); // @nivo/line format 'line_key.index'
