import * as React from 'react';

import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { GivingDashboardTilesQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { getAverageDonationSnapshotValue, getOnlineOfflineGiving } from '@src/utils/calculateSnapshots';

import { useHasChmsAccess } from '../../../hooks/permissions/useHasChmsAccess';
import { SnapshotDefinition } from '../types';

export const useDonorTileDefinitions = (): SnapshotDefinition<GivingDashboardTilesQuery>[] => {
  const { translate } = useTranslation();
  const hasChmsAccess = useHasChmsAccess();
  return React.useMemo(
    () => [
      {
        title: translate(`snapshots.${ViewByDomain.Donors}.onlineOfflineGiving`),
        tooltip: translate(`infoText.giving.ratioOnlineOffline`),
        getValue: data =>
          getOnlineOfflineGiving(
            data.Donors?.communityMembersCursor?.aggregations.Digital,
            data.Donors?.communityMembersCursor?.aggregations.Offline
          ),
      },
      {
        title: translate(`snapshots.${ViewByDomain.Donors}.givingPerAttendee`),
        tooltip: translate(`infoText.giving.avgPerAttendee`),
        getValue: data =>
          getAverageDonationSnapshotValue(
            data.organizationAttendanceOverview?.highlights.totalAttendance,
            data.Donors?.CurrentGivingSummaries?.periods
          ),
        isPromotional: !hasChmsAccess,
      },
    ],
    [hasChmsAccess, translate]
  );
};
