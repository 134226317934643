import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';
import { camelCaseToTitleCase } from '@churchcommunitybuilder/js-utils';

import Filters, { FilterConfig, FiltersValue, TimeRangeFilterSelection } from '@pushpay/filters';

import { defaultTimeFilterState } from '@src/components/FilterMenu/consts';
import { EventStartTimeInput, TimeFilterOperator } from '@src/graphql/generated';
import { AvailableFilters } from '@src/types/ChartAndHighlights';
import { AttendanceFilters } from '@src/types/Filters';
import { getTimeFilterOperator, isToggleTypeValues, noMembershipTypeId } from '@src/utils/filters';

import { emptyFilters } from './AttendanceOverview/consts';

export function convertAttendanceTimeFilterToCLTimeFilter<T>(
  key: Extract<keyof T, string>,
  attendanceFilter?: EventStartTimeInput | null
): FilterConfig<T> {
  const defaultFilterProps = {
    type: 'time',
    field: key,
    defaultLabel: camelCaseToTitleCase(key),
    showSearch: true,
    update: () => null,
    isMobileLayout: false,
    timeIncrement: 15,
  } as const;

  switch (attendanceFilter?.operator) {
    case TimeFilterOperator.Is:
      return { ...defaultFilterProps, filterState: { relationship: 'is', times: attendanceFilter.times || [] } };
    case TimeFilterOperator.IsAfter:
      return {
        ...defaultFilterProps,
        filterState: { relationship: 'isAfter', minTime: attendanceFilter.minTime || '' },
      };
    case TimeFilterOperator.IsBefore:
      return {
        ...defaultFilterProps,
        filterState: { relationship: 'isBefore', maxTime: attendanceFilter.maxTime || '' },
      };
    case TimeFilterOperator.IsBetween:
      return {
        ...defaultFilterProps,
        filterState: {
          relationship: 'isBetween',
          minTime: attendanceFilter.minTime || '',
          maxTime: attendanceFilter.maxTime || '',
        },
      };
    default:
      return { ...defaultFilterProps, filterState: defaultTimeFilterState };
  }
}

export const convertCLFiltersToAttendanceFilters = (
  filters: FiltersValue<AvailableFilters>,
  timeZoneId: string
): AttendanceFilters => {
  const filterValues = Object.entries(filters);

  return filterValues.reduce((acc, [key, value]) => {
    if (key === 'membershipTypeIds' && isToggleTypeValues(value)) {
      return {
        ...acc,
        membershipTypeIds: {
          values: value.values
            ?.filter(membershipTypeId => membershipTypeId !== noMembershipTypeId)
            .map(membershipTypeId => parseInt(membershipTypeId, 10)),
          isEmpty: !!value.values?.includes(noMembershipTypeId),
        } as MembershipTypeIdFilter,
      };
    }

    if (Filters.isOptionFilterValue(value)) {
      return { ...acc, [key]: value.values };
    }

    if (Filters.isTimeFilterValue(value)) {
      if (!Filters.isTimeFilterValueSelected(value)) {
        return { ...acc, [key]: undefined };
      }

      return {
        ...acc,
        [key]: {
          maxTime: value.maxTime,
          minTime: value.minTime,
          operator: getTimeFilterOperator((value as TimeRangeFilterSelection<string>).relationship),
          timeZoneId,
          times: value.times,
        },
      };
    }

    // else handle other filter types
    return acc;
  }, emptyFilters);
};
