import { TagExportJobSortColumns } from '@churchcommunitybuilder/insights-people-list-filtering';
import { camelCaseToSnakeCase } from '@churchcommunitybuilder/js-utils';

import { Campus, ExportJobSortDirection, SortOrder } from '@src/graphql/generated';
import { SortingState } from '@src/pages/PeopleList/components/PeopleListProvider/types';

export const getSort = (sortingState?: SortingState) => {
  if (!sortingState) {
    return undefined;
  }

  const order = sortingState.sortDirection === ExportJobSortDirection.Asc ? SortOrder.Asc : SortOrder.Desc;
  const tagColumns = (Object.values(TagExportJobSortColumns) as string[]).map(
    column => column.charAt(0).toLowerCase() + column.slice(1)
  );

  let field: string;

  if (sortingState?.sortColumnName && tagColumns.includes(sortingState.sortColumnName)) {
    field = `pushpay:${camelCaseToSnakeCase(sortingState.sortColumnName)}`;
  } else if (sortingState.sortColumnName === 'homeCampusName') {
    field = 'campusName';
  } else {
    field = sortingState.sortColumnName;
  }

  return [{ field, order }];
};

export const mapCampusKeyToName = (campusKey?: string | null, campusOptions?: Campus[]): string => {
  if (!campusKey || !campusOptions) return '';
  const campusOption = campusOptions.find(option => option.key === campusKey);
  return campusOption ? campusOption.name : '';
};
