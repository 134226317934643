import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { ViewByDomain, VolunteerListType } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useToggle } from '@pushpay/utils';

import { useCommunityMemberVolunteerListTotalsLazyQuery, usePeopleCountLazyQuery } from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { usePeopleList } from '@src/pages/PeopleList/components/PeopleListProvider';
import { convertClFilterConfigsToFilterMenuFilters } from '@src/pages/PeopleList/components/PeopleListProvider/utils';

import { emptyTotals, VolunteerPeopleListTotalsQueryResult, VolunteerPeopleListTotals } from '../types';

const isApolloError = (item: ApolloError | undefined | null): item is ApolloError => !!item;

export const useCommunityMemberVolunteerPeopleListTotalsLazyQuery = (): [
  () => Promise<VolunteerPeopleListTotalsQueryResult>,
  VolunteerPeopleListTotalsQueryResult
] => {
  const [error, setError] = React.useState([] as ApolloError[]);
  const { isOn: loading, toggle: setLoading } = useToggle();
  const [totals, setTotals] = React.useState<VolunteerPeopleListTotals>(emptyTotals);
  const getPeopleListFilters = usePeopleListFilters();
  const { clFilterConfigs } = usePeopleList();
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);

  const { key } = useMyOrganization();

  const { filters: allVolunteerFilters } = getPeopleListFilters(
    ViewByDomain.Volunteers,
    VolunteerListType.All,
    filterMenuFilters
  );
  const [getVolunteerListTotals] = useCommunityMemberVolunteerListTotalsLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: allVolunteerFilters,
      },
    },
  });

  const { filters: nonVolunteerFilters } = getPeopleListFilters(
    ViewByDomain.Volunteers,
    VolunteerListType.NonVolunteers,
    filterMenuFilters
  );
  const [getNonVolunteerCount] = usePeopleCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: nonVolunteerFilters,
      },
    },
  });

  const { filters: potentialVolunteerFilters } = getPeopleListFilters(
    ViewByDomain.Volunteers,
    VolunteerListType.Potential,
    filterMenuFilters
  );
  const [getVolunteerTotals] = usePeopleCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: potentialVolunteerFilters,
      },
    },
  });

  const getTotals = React.useCallback(async () => {
    setLoading(true);
    const { error: nonVolunteerError, data: nonVolunteerData } = await getNonVolunteerCount();
    const { error: listTotalsError, data: listTotalsData } = await getVolunteerListTotals();
    const { error: potentialVolunteerError, data: potentialVolunteerData } = await getVolunteerTotals();
    const newError = [nonVolunteerError, listTotalsError, potentialVolunteerError].filter(isApolloError);
    setError(newError);

    const nonVolunteerCount = nonVolunteerData?.organization?.communityMembersCursor?.paging.totalItemCount;
    const aggregations = listTotalsData?.organization?.communityMembersCursor?.aggregations;
    let newTotals = emptyTotals;
    if (nonVolunteerCount) {
      newTotals = {
        ...newTotals,
        NonVolunteers: nonVolunteerCount,
      };
    }

    if (aggregations) {
      newTotals = {
        ...newTotals,
        All: aggregations.allPeople ?? 0,
        AtRisk: aggregations.atRiskVolunteers ?? 0,
        Curious: aggregations.curiousVolunteers ?? 0,
        FirstTime: aggregations.firstTimeVolunteers ?? 0,
        Lapsed: aggregations.lapsedVolunteers ?? 0,
        Occasional: aggregations.occasionalVolunteers ?? 0,
        Potential: potentialVolunteerData?.organization?.communityMembersCursor?.paging.totalItemCount ?? 0,
        RecentNew: aggregations.recentNewVolunteers ?? 0,
        Regular: aggregations.regularVolunteers ?? 0,
        Returning: aggregations.returningVolunteers ?? 0,
        SecondTime: aggregations.secondTimeVolunteers ?? 0,
      };
    }

    setTotals(newTotals);
    setLoading(false);

    return { loading: false, error: newError, totals: newTotals };

    // Do not add setLoading as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVolunteerListTotals, getNonVolunteerCount]);

  return [
    getTotals,
    {
      error,
      loading,
      totals,
    },
  ];
};
