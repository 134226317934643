import {
  getOneCompletedWeekForNWeeksAgo,
  getLastNCompletedWeeks,
} from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { useUserTimeZone } from '../useUserTimeZone';

const today = Date.now();

export const useLastAndPreviousDates = () => {
  const timeZoneId = useUserTimeZone();

  const { startDate: lastCompletedWeekStartDate, endDate: lastCompletedWeekEndDate } = getLastNCompletedWeeks(1);
  const { startDate: previousWeekStartDate, endDate: previousWeekEndDate } = getOneCompletedWeekForNWeeksAgo(2);

  const previousWeekComparedToTodayDateTime = dateFns.subWeeks(today, 1);

  const lastCompletedWeekDateRange = {
    startDate: lastCompletedWeekStartDate,
    endDate: lastCompletedWeekEndDate,
    timeZoneId,
  };
  const previousCompletedWeekDateRange = {
    startDate: previousWeekStartDate,
    endDate: previousWeekEndDate,
    timeZoneId,
  };
  const previousWeekComparedToTodayDateRange = {
    startDate: dateFns.subMilliseconds(previousWeekComparedToTodayDateTime, 1),
    endDate: previousWeekComparedToTodayDateTime,
    timeZoneId,
  };

  return {
    lastCompletedWeekDateRange,
    previousCompletedWeekDateRange,
    previousWeekComparedToTodayDateRange,
  };
};
