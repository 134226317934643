import { DateFormats, RangeType } from '@churchcommunitybuilder/js-utils';
import { parseISO } from 'date-fns';

import { GranularTimeRangeInput, PaymentQueryGranularity, StartOfWeek } from '@src/graphql/generated';
import { ChartData, ChartDataItem } from '@src/types/ChartAndHighlights';
import { DateConfig } from '@src/types/DateConfig';
import { DateRangeOptionType } from '@src/types/DateRangeSelector';
import { OrganizationStagesOverview } from '@src/types/OrganizationStagesOverview';
import { formatDate } from '@src/utils';
import { getGranularity } from '@src/utils/getDateInformation';
import { getDateRange, getPreviousDateRange } from '@src/utils/getDateRange';

const returnDateObject = (input: string | Date) => (typeof input === 'string' ? parseISO(input) : input);

export const getChartDataFromOrganizationStagesOverview = (data: OrganizationStagesOverview): ChartData =>
  data.map(overviewData => {
    switch (overviewData.__typename) {
      case 'ServingOpportunitySummary':
      case 'EventAttendanceSummary':
      case 'GroupParticipationSummary':
        return {
          startDate: new Date(overviewData.startDate),
          endDate: new Date(overviewData.endDate),
          value: overviewData.total,
        } as ChartDataItem;
      case 'OrganizationGivingPeriodSummaryNew':
        return {
          startDate: returnDateObject(overviewData.fromDate),
          endDate: returnDateObject(overviewData.toDate),
          value: Number(overviewData.paymentsAggregate.totalValue.amount),
        } as ChartDataItem;
      default:
        return {} as ChartDataItem;
    }
  });

export const getGivingChartDateRanges = (
  dateConfig: DateConfig,
  dateRange: DateRangeOptionType
): {
  currentStartDate: Date;
  currentEndDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
} => {
  let currentStartDate;
  let currentEndDate;
  let previousStartDate = new Date(); // these aren't used for custom date ranges, but are required by the API
  let previousEndDate = new Date(); // these aren't used for custom date ranges, but are required by the API

  if (dateConfig.rangeType === RangeType.Custom) {
    currentStartDate = dateConfig.startDate;
    currentEndDate = dateConfig.endDate;
  } else {
    const currentRanges = getDateRange(dateRange);
    const previousRanges = getPreviousDateRange(dateRange, dateConfig.comparison);

    currentStartDate = currentRanges.startDate;
    currentEndDate = currentRanges.endDate;
    previousStartDate = previousRanges.startDate;
    previousEndDate = previousRanges.endDate;
  }

  return {
    currentStartDate,
    currentEndDate,
    previousStartDate,
    previousEndDate,
  };
};

export const getGivingChartTimeRanges = (dateConfig: DateConfig, dateRange: DateRangeOptionType, timezone: string) => {
  const { currentStartDate, currentEndDate, previousStartDate, previousEndDate } = getGivingChartDateRanges(
    dateConfig,
    dateRange
  );
  const granularity = getGranularity(currentEndDate, currentStartDate);

  const currentTimeRange: GranularTimeRangeInput = {
    granularity,
    fromDate: formatDate(currentStartDate, DateFormats.YYYY_MM_DD),
    startDay: granularity === PaymentQueryGranularity.Week ? StartOfWeek.Monday : StartOfWeek.Unknown,
    timeZoneId: timezone,
    toDate: formatDate(currentEndDate, DateFormats.YYYY_MM_DD),
  };
  const previousTimeRange: GranularTimeRangeInput = {
    granularity,
    fromDate: formatDate(previousStartDate, DateFormats.YYYY_MM_DD),
    startDay: granularity === PaymentQueryGranularity.Week ? StartOfWeek.Monday : StartOfWeek.Unknown,
    timeZoneId: timezone,
    toDate: formatDate(previousEndDate, DateFormats.YYYY_MM_DD),
  };

  return { currentTimeRange, previousTimeRange };
};
