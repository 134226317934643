import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { AppStudioTheme, ChMSTheme, EngagementAnalyticsTheme, GivingTheme } from '@pushpay/theming';

export const graphGrey = EngagementAnalyticsTheme.colors['grey-500'];

const colors = {
  [ViewByDomain.Attenders]: {
    base: EngagementAnalyticsTheme.colors['productBrand-700'],
    graph: ChMSTheme.colors['productBrand-500'],
    light: EngagementAnalyticsTheme.colors['productBrand-200'],
    medium: EngagementAnalyticsTheme.colors['productBrand-300'],
    dark: EngagementAnalyticsTheme.colors['productBrand-700'],
  },
  [ViewByDomain.Donors]: {
    base: GivingTheme.colors['productBrand-500'],
    graph: GivingTheme.colors['productBrand-500'],
    light: GivingTheme.colors['productBrand-200'],
    medium: GivingTheme.colors['productBrand-300'],
    dark: GivingTheme.colors['productBrand-700'],
  },
  [ViewByDomain.GroupParticipants]: {
    base: EngagementAnalyticsTheme.colors['productBrand-700'],
    graph: ChMSTheme.colors['productBrand-500'],
    light: EngagementAnalyticsTheme.colors['productBrand-200'],
    medium: EngagementAnalyticsTheme.colors['productBrand-300'],
    dark: EngagementAnalyticsTheme.colors['productBrand-700'],
  },
  [ViewByDomain.Volunteers]: {
    base: AppStudioTheme.colors['productBrand-500'],
    graph: AppStudioTheme.colors['productBrand-500'],
    light: AppStudioTheme.colors['productBrand-200'],
    medium: AppStudioTheme.colors['productBrand-300'],
    dark: AppStudioTheme.colors['productBrand-700'],
  },
};

export default colors;
