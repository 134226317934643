import * as React from 'react';

import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useLocalStorage } from '@pushpay/utils';

import { MembershipType } from '@src/graphql/generated';
import { useChmsMembershipTypes } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { allMembershipTypesAreSelected } from '@src/utils/dataCustomization';
import { getMembershipTypeOptionsForCLFilters } from '@src/utils/filters';

interface DataCustomizationContextValue {
  globallyFilteredMembershipTypeOptions: Map<string, string>;
  membershipTypeOptions: MembershipType[];
  resiStreamingAttendanceEnabled: boolean;
  selectedMembershipTypes: MembershipTypeIdFilter | null;
  setResiStreamingAttendanceEnabled: (value: boolean) => void;
  setSelectedMembershipTypes: (membershipTypeIdFilter: MembershipTypeIdFilter | null) => void;
}

type DataCustomizationProviderProps = React.PropsWithChildren;

const DataCustomizationContext = React.createContext<DataCustomizationContextValue | undefined>(undefined);

export const DataCustomizationProvider = ({ children }: DataCustomizationProviderProps) => {
  const chmsMembershipTypeOptions = useChmsMembershipTypes();
  const organization = useMyOrganization();
  const { productInformation } = organization.details ?? {};
  const isResiAvailable = !!productInformation?.streamingInformation.id;

  const [selectedMembershipTypes, setSelectedMembershipTypesLocalStorage] =
    useLocalStorage<MembershipTypeIdFilter | null>(`insights/membershipTypes`, null);

  const [resiStreamingAttendanceEnabled, setResiStreamingAttendanceEnabledLocalStorage] = useLocalStorage<boolean>(
    `insights/resiStreaming`,
    isResiAvailable
  );

  React.useEffect(() => {
    if (!isResiAvailable) {
      setResiStreamingAttendanceEnabledLocalStorage(false);
    }
  }, [isResiAvailable, setResiStreamingAttendanceEnabledLocalStorage]);

  const setSelectedMembershipTypes = React.useCallback(
    (newMembershipTypeIdFilter: MembershipTypeIdFilter | null) => {
      if (allMembershipTypesAreSelected(chmsMembershipTypeOptions, newMembershipTypeIdFilter)) {
        setSelectedMembershipTypesLocalStorage(null);
        return;
      }
      setSelectedMembershipTypesLocalStorage(newMembershipTypeIdFilter);
    },
    [chmsMembershipTypeOptions, setSelectedMembershipTypesLocalStorage]
  );

  const setResiStreamingAttendanceEnabled = React.useCallback(
    (value: boolean) => {
      setResiStreamingAttendanceEnabledLocalStorage(value);
    },
    [setResiStreamingAttendanceEnabledLocalStorage]
  );

  const value = {
    globallyFilteredMembershipTypeOptions: getMembershipTypeOptionsForCLFilters(
      chmsMembershipTypeOptions,
      selectedMembershipTypes
    ),
    membershipTypeOptions: chmsMembershipTypeOptions,
    selectedMembershipTypes,
    setSelectedMembershipTypes,
    resiStreamingAttendanceEnabled,
    setResiStreamingAttendanceEnabled,
  } as DataCustomizationContextValue;
  return <DataCustomizationContext.Provider value={value}>{children}</DataCustomizationContext.Provider>;
};

export const useDataCustomization = () => {
  const context = React.useContext(DataCustomizationContext);

  if (context === undefined) {
    throw new Error('useDataCustomization must be used within a DataCustomizationProvider');
  }

  return context;
};
