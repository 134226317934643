import * as React from 'react';

import { DomainSpecificExportJobType, getApiJobType } from '@churchcommunitybuilder/insights-people-list-filtering';
import { capitalize } from '@churchcommunitybuilder/js-utils';

import { AttendanceStatus, DonorStatus, ExportJobSortColumns, VolunteerStatus } from '@src/graphql/generated';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';
import { usePeopleList } from '@src/pages/PeopleList/components/PeopleListProvider';
import { convertClFilterConfigsToFilterMenuFilters } from '@src/pages/PeopleList/components/PeopleListProvider/utils';

import { useQueueExportJob } from './useQueueExportJob';

function isExportJobSortColumn(columnName: string): columnName is ExportJobSortColumns {
  return !!Object.values(ExportJobSortColumns).find(col => col === columnName);
}

export const useQueueExportJobForCurrentPageSettings = () => {
  const { clFilterConfigs, listType, searchTerm, sort, viewByDomain, viewByMode } = usePeopleList();

  const capitalizedSortColumn = sort ? capitalize(sort.sortColumnName, true) : '';
  const { selectedMembershipTypes: globallySelectedMembershipTypes } = useDataCustomization();
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);

  const selectedMembershipTypes = React.useMemo(() => {
    if (filterMenuFilters && filterMenuFilters?.membershipTypeIds?.values.length !== 0) {
      return filterMenuFilters.membershipTypeIds;
    }

    return globallySelectedMembershipTypes;
  }, [filterMenuFilters, globallySelectedMembershipTypes]);

  const selectedCampuses = React.useMemo(() => {
    if (filterMenuFilters?.campusKeys) {
      return filterMenuFilters.campusKeys;
    }
    return undefined;
  }, [filterMenuFilters]);

  const selectedAttendanceStatuses = React.useMemo(() => {
    if (filterMenuFilters && filterMenuFilters?.attendanceStatus?.length !== 0) {
      return filterMenuFilters.attendanceStatus as AttendanceStatus[] | undefined;
    }

    return undefined;
  }, [filterMenuFilters]);

  const selectedDonorStatuses = React.useMemo(() => {
    if (filterMenuFilters && filterMenuFilters?.donorStatus?.length !== 0) {
      return filterMenuFilters.donorStatus as DonorStatus[] | undefined;
    }

    return undefined;
  }, [filterMenuFilters]);

  const selectedVolunteerStatuses = React.useMemo(() => {
    if (filterMenuFilters && filterMenuFilters?.servingStatus?.length !== 0) {
      return filterMenuFilters.servingStatus as VolunteerStatus[] | undefined;
    }

    return undefined;
  }, [filterMenuFilters]);

  const currentPageExportJob = React.useMemo(
    () => ({
      type: getApiJobType({ domain: viewByDomain, listType } as DomainSpecificExportJobType),
      membershipTypeIds: selectedMembershipTypes,
      searchTerm,
      sort:
        sort && isExportJobSortColumn(capitalizedSortColumn)
          ? {
              columnName: capitalizedSortColumn,
              direction: sort.sortDirection,
            }
          : undefined,
      viewBy: viewByMode,
      attendanceStatus: selectedAttendanceStatuses,
      campusKeys: selectedCampuses,
      donorStatus: selectedDonorStatuses,
      volunteerStatus: selectedVolunteerStatuses,
    }),
    [
      capitalizedSortColumn,
      listType,
      searchTerm,
      selectedAttendanceStatuses,
      selectedCampuses,
      selectedDonorStatuses,
      selectedMembershipTypes,
      selectedVolunteerStatuses,
      sort,
      viewByDomain,
      viewByMode,
    ]
  );

  const onQueueExportJob = useQueueExportJob();

  const onQueueExportJobForCurrentPageSettings = React.useCallback(
    () => onQueueExportJob(currentPageExportJob),
    [currentPageExportJob, onQueueExportJob]
  );

  return onQueueExportJobForCurrentPageSettings;
};
