import {
  DateComparisonType,
  RangeType,
  getFrequencyFromDateRange,
  getPreviousDateRange,
} from '@churchcommunitybuilder/js-utils';

import { Granularity } from '@src/graphql/generated';
import { useDateConfig, useUserTimeZone } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';
import { getDateRangeFromRangeType } from '@src/utils';
import { getDateRangeSelectorConfigFromDateConfig } from '@src/utils/getDateRangeSelectorConfig';

export function usePrefetchChartParams() {
  const { key: organizationKey } = useMyOrganization();

  if (!organizationKey) {
    throw new Error('No organization key provided.');
  }

  const { dateConfig } = useDateConfig();

  const { startDate, endDate } = getDateRangeFromRangeType(dateConfig);
  const granularity = getFrequencyFromDateRange(startDate, endDate) as unknown as Granularity;

  const timezone = useUserTimeZone();

  const { comparison } = getDateRangeSelectorConfigFromDateConfig(dateConfig);
  let isComparing = false;
  let previousEndDate = new Date();
  let previousStartDate = new Date();

  if (dateConfig.rangeType !== RangeType.Custom && comparison !== DateComparisonType.None) {
    const { startDate: prevStartDate, endDate: prevEndDate } = getPreviousDateRange(
      dateConfig.comparison,
      dateConfig.duration,
      dateConfig.includeCurrent,
      dateConfig.period
    );

    isComparing = true;

    previousEndDate = prevEndDate;
    previousStartDate = prevStartDate;
  }

  const lastDateRange = {
    startDate,
    endDate,
    timeZoneId: timezone,
  };

  const previousDateRange = {
    startDate: previousStartDate,
    endDate: previousEndDate,
    timeZoneId: timezone,
  };

  const { selectedMembershipTypes } = useDataCustomization();

  return {
    granularity,
    isComparing,
    lastDateRange,
    selectedMembershipTypes,
    organizationKey,
    previousDateRange,
  };
}
