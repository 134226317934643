import * as React from 'react';

import { useForceUpdate } from '@pushpay/utils';

type CustomBreakpoint = string;

const listenersCache: { [breakpoint in CustomBreakpoint]?: MediaQueryList } = {};

/**
 *  Returns true when the screen size matches the given breakpoint
 */
export function useCustomBreakpoint(breakpoint: string) {
  const forceUpdate = useForceUpdate();

  function getCachedListener() {
    if (breakpoint === 'NEVER') {
      return null;
    }
    if (!listenersCache[breakpoint]) {
      listenersCache[breakpoint] = window.matchMedia(breakpoint);
    }
    return listenersCache[breakpoint] as MediaQueryList;
  }

  const listener = getCachedListener();

  React.useLayoutEffect(
    function forceUpdateOnBreakpointChange() {
      if (listener) {
        if (typeof listener.addEventListener === 'function') {
          listener.addEventListener('change', forceUpdate);
          return () => {
            listener.removeEventListener('change', forceUpdate);
          };
        }
        // Deprecated but needed to support Safari < 14
        // eslint-disable-next-line deprecation/deprecation
        listener.addListener(forceUpdate);
        // eslint-disable-next-line deprecation/deprecation
        return listener.removeListener(forceUpdate);
      }
      return undefined;
    },
    [forceUpdate, listener]
  );

  return listener?.matches ?? false;
}
