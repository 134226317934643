import * as React from 'react';

// eslint-disable-next-line camelcase
import { Chms_CursorPagingInput, CursorPagingOutput, useProcessesLazyQuery } from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';
import { ProcessWithoutQueues } from '@src/types/Processes';

export const useChmsProcesses = () => {
  const { key: organizationKey } = useMyOrganization();
  const [getProcesses] = useProcessesLazyQuery();

  return React.useCallback(async () => {
    const processes: ProcessWithoutQueues[] = [];
    // eslint-disable-next-line camelcase
    const pagingInput: Chms_CursorPagingInput = {
      size: 25,
    };
    let pagingOutput: Pick<CursorPagingOutput, 'hasNextPage' | 'last'> | undefined;

    do {
      pagingInput.after = pagingOutput?.last;
      // eslint-disable-next-line no-await-in-loop
      const { data } = await getProcesses({
        variables: {
          organizationKey,
          paging: pagingInput,
        },
      });

      if (data?.organization?.processes?.items) {
        processes.push(...data.organization.processes.items);
      }

      pagingOutput = data?.organization?.processes?.paging;
    } while (pagingOutput?.hasNextPage);

    return processes;
  }, [getProcesses, organizationKey]);
};
