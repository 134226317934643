import * as React from 'react';

import { useLocalStorage } from '@pushpay/utils';

import { emptyFilters } from '@src/Attendance/AttendanceOverview/consts';
import { DashboardAttendanceViewBy } from '@src/components/DashboardFilterModal/types';
import { useFeature } from '@src/featureFlags';
import { AttendanceFilters } from '@src/types/Filters';

export const useDashboardFilterMenu = () => {
  const isAggregatedAttendanceDashboardEnabled = useFeature('AggregatedAttendanceDashboard');

  const [dashboardAttendanceViewBy, setDashboardAttendanceViewBy] = useLocalStorage<DashboardAttendanceViewBy>(
    'insights/dashboardAttendanceViewBy',
    DashboardAttendanceViewBy.AttenderSnapshots
  );

  const [attendanceFilters, setAttendanceFilters] = useLocalStorage<AttendanceFilters>(
    'insights/dashboardAttendanceFilters',
    emptyFilters
  );

  const attendanceFilterCount = React.useMemo(() => {
    let badge = 0;
    for (const value of Object.values(attendanceFilters)) {
      if (Array.isArray(value) && value.length > 0) {
        badge += 1;
      }
    }
    return badge;
  }, [attendanceFilters]);

  return {
    dashboardAttendanceViewBy: isAggregatedAttendanceDashboardEnabled
      ? dashboardAttendanceViewBy
      : DashboardAttendanceViewBy.AttenderSnapshots,
    setDashboardAttendanceViewBy,
    attendanceFilters,
    setAttendanceFilters,
    attendanceFilterCount,
  };
};
