import * as React from 'react';

import { DonorListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { DirectionIsGood } from '@src/constants/trend';
import { ExportJobViewBy, GivingDashboardDonorDevOnlyQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { formatCountOrNull, getOnlineOfflineGiving } from '@src/utils/calculateSnapshots';

import { useGetTrendProps } from '../../../hooks';
import { SnapshotDefinition } from '../types';

export const useDonorDevOnlyDefinitions = (
  viewByMode: ExportJobViewBy
): SnapshotDefinition<GivingDashboardDonorDevOnlyQuery>[] => {
  const { translate } = useTranslation();
  const getTrendProps = useGetTrendProps();

  return React.useMemo(
    () => [
      {
        listType: DonorListType.RecentNew,
        title: translate(`snapshots.${ViewByDomain.Donors}.recentNewDonors.${viewByMode}`),
        tooltip: translate(`infoText.giving.peopleList.RecentNew.${viewByMode}`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Donors].RecentNew,
            data?.Donors?.RecentNewLast?.paging.totalItemCount ?? 0,
            data?.Donors?.RecentNewPrevious?.paging.totalItemCount ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Donors?.RecentNewLast?.paging.totalItemCount),
      },
      {
        listType: DonorListType.AtRisk,
        title: translate(`snapshots.${ViewByDomain.Donors}.atRiskDonors.${viewByMode}`),
        tooltip: translate(`infoText.giving.peopleList.AtRisk.${viewByMode}`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Donors].AtRisk,
            data?.Donors?.LastData?.aggregations?.AtRisk ?? 0,
            data?.Donors?.PreviousData?.aggregations?.AtRisk ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Donors?.LastData?.aggregations?.AtRisk),
      },
      {
        title: translate(`snapshots.${ViewByDomain.Donors}.onlineOfflineGiving`),
        tooltip: translate(`infoText.giving.ratioOnlineOffline`),
        getValue: data =>
          getOnlineOfflineGiving(
            data.Donors?.LastData?.aggregations.Digital,
            data.Donors?.LastData?.aggregations.Offline
          ),
      },
    ],
    [getTrendProps, translate, viewByMode]
  );
};
