import * as React from 'react';

import { useIsMounted, wait } from '@pushpay/utils';

export const useOnViewed = (onViewed?: () => void) => {
  const isMounted = useIsMounted();

  React.useEffect(() => {
    if (onViewed) {
      const viewed = async () => {
        await wait(1000);
        if (isMounted()) {
          onViewed();
        }
      };
      viewed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
