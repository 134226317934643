import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { AttenderListType } from '@churchcommunitybuilder/insights-people-list-filtering';

import { ExportJobViewBy } from '@src/graphql/generated';

import { useCommunityMemberAttenderPeopleListTotalsLazyQuery } from './hooks/useCommunityMemberAttenderPeopleListTotalsLazyQuery';
import { useHouseholdAttenderPeopleListTotalsLazyQuery } from './hooks/useHouseholdAttenderPeopleListTotalsLazyQuery';

type AttenderPeopleListTotals = Record<AttenderListType, number>;
export type AttenderPeopleListTotalsQueryResult = {
  error: ApolloError[];
  loading: boolean;
  totals: AttenderPeopleListTotals;
};

export const useAttenderPeopleListTotalsLazyQuery = (
  viewByMode: ExportJobViewBy
): [() => Promise<AttenderPeopleListTotalsQueryResult>, AttenderPeopleListTotalsQueryResult] => {
  const [error, setError] = React.useState([] as ApolloError[]);
  const [loading, setLoading] = React.useState(false);
  const [totals, setTotals] = React.useState({} as AttenderPeopleListTotals);
  const [getCommunityMemberTotals] = useCommunityMemberAttenderPeopleListTotalsLazyQuery();
  const [getHouseholdTotals] = useHouseholdAttenderPeopleListTotalsLazyQuery();

  const getPeopleListTotals = React.useCallback(async () => {
    setLoading(true);
    let result: AttenderPeopleListTotalsQueryResult;
    switch (viewByMode) {
      case ExportJobViewBy.CommunityMember:
        result = await getCommunityMemberTotals();
        break;
      case ExportJobViewBy.Household:
        result = await getHouseholdTotals();
        break;
      default:
        throw new Error('Unknown domain');
    }

    setError(result.error);
    setTotals(result.totals);
    setLoading(false);

    return { loading: false, error: result.error, totals: result.totals };
  }, [getCommunityMemberTotals, getHouseholdTotals, viewByMode]);

  return [
    getPeopleListTotals,
    {
      loading,
      error,
      totals,
    },
  ];
};
