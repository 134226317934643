import * as React from 'react';

import {
  ViewByDomain,
  ListType,
  // eslint-disable-next-line no-restricted-imports
  getPeopleListFilters,
  ExtraPeopleListFilters,
} from '@churchcommunitybuilder/insights-people-list-filtering';

import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

export const usePeopleListFilters = () => {
  const { selectedMembershipTypes } = useDataCustomization();

  return React.useCallback(
    (viewByDomain: ViewByDomain, listType: ListType, extraPeopleListFilters?: ExtraPeopleListFilters) => {
      const globalMembershipTypes = selectedMembershipTypes ?? undefined;
      const peopleListMembershipTypeFilterIsEmpty =
        !extraPeopleListFilters?.membershipTypeIds ||
        (extraPeopleListFilters.membershipTypeIds.values.length === 0 &&
          !extraPeopleListFilters.membershipTypeIds.isEmpty);

      const extraFilters = {
        ...extraPeopleListFilters,
        membershipTypeIds: peopleListMembershipTypeFilterIsEmpty
          ? globalMembershipTypes
          : extraPeopleListFilters.membershipTypeIds,
      };

      return getPeopleListFilters(viewByDomain, listType, extraFilters);
    },
    [selectedMembershipTypes]
  );
};
