import * as React from 'react';

import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { ServingDashboardTilesQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { formatCountOrNull, getVolunteerOpportunities } from '@src/utils/calculateSnapshots';

import { SnapshotDefinition } from '../types';

export const useVolunteerTileDefinitions = (): SnapshotDefinition<ServingDashboardTilesQuery>[] => {
  const { translate } = useTranslation();
  return React.useMemo(
    () => [
      {
        title: translate(`snapshots.${ViewByDomain.Volunteers}.volunteersOpportunities`),
        tooltip: translate(`infoText.serving.ratioVolunteersOpportunities`),
        getValue: data =>
          getVolunteerOpportunities(
            data?.organizationServingSnapshots?.LastData?.availableVolunteers,
            data?.organizationServingSnapshots?.LastData?.servingOpportunities
          ),
      },
      {
        title: translate(`snapshots.${ViewByDomain.Volunteers}.unfilledRoles`),
        tooltip: translate(`infoText.serving.rolesUnfilled`),
        getValue: data => formatCountOrNull(data?.organizationServingSnapshots?.LastData?.servingOpportunitiesUnfilled),
      },
    ],
    [translate]
  );
};
