import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { SnapshotTileProps } from '@src/components/SnapshotTiles/SnapshotTiles';
import colors from '@src/constants/colors';
import {
  AttendanceDashboardCardQuery,
  ExportJobViewBy,
  GivingDashboardCardQuery,
  GivingHouseholdDashboardCardQuery,
  ServingDashboardCardQuery,
} from '@src/graphql/generated';

import { SnapshotDefinition } from './types';

export const getAttendingCount = (data?: AttendanceDashboardCardQuery) => {
  // Purposeful == vs. === to check for null OR undefined
  if (
    data?.Attenders?.LastData?.aggregations.Curious == null ||
    data?.Attenders?.LastData?.aggregations.FirstTime == null ||
    data?.Attenders?.LastData?.aggregations.Regular == null ||
    data?.Attenders?.LastData?.aggregations.Returning == null ||
    data?.Attenders?.LastData?.aggregations.SecondTime == null
  ) {
    return null;
  }
  return (
    data.Attenders.LastData.aggregations.Curious +
    data.Attenders.LastData.aggregations.FirstTime +
    data.Attenders.LastData.aggregations.Regular +
    data.Attenders.LastData.aggregations.Returning +
    data.Attenders.LastData.aggregations.SecondTime
  );
};

export const getGivingCount = (data?: GivingDashboardCardQuery | GivingHouseholdDashboardCardQuery) => {
  // Purposeful == vs. === to check for null OR undefined
  if (
    data?.Donors?.LastData?.aggregations.FirstTime == null ||
    data?.Donors?.LastData?.aggregations.SecondTime == null ||
    data?.Donors?.LastData?.aggregations.Regular == null ||
    data?.Donors?.LastData?.aggregations.Recurring == null
  ) {
    return null;
  }
  return (
    data.Donors.LastData.aggregations.FirstTime +
    data.Donors.LastData.aggregations.SecondTime +
    data.Donors.LastData.aggregations.Regular +
    data.Donors.LastData.aggregations.Recurring
  );
};

export const getServingCount = (data?: ServingDashboardCardQuery) => {
  // Purposeful == vs. === to check for null OR undefined
  if (
    data?.Volunteers?.LastData?.aggregations.Curious == null ||
    data?.Volunteers?.LastData?.aggregations.FirstTime == null ||
    data?.Volunteers?.LastData?.aggregations.Regular == null ||
    data?.Volunteers?.LastData?.aggregations.Returning == null ||
    data?.Volunteers?.LastData?.aggregations.SecondTime == null
  ) {
    return null;
  }
  return (
    data.Volunteers.LastData.aggregations.Curious +
    data.Volunteers.LastData.aggregations.FirstTime +
    data.Volunteers.LastData.aggregations.Regular +
    data.Volunteers.LastData.aggregations.Returning +
    data.Volunteers.LastData.aggregations.SecondTime
  );
};

export const buildSnapshotData = <T>(
  domain: ViewByDomain,
  snapshotDefinitions: SnapshotDefinition<T>[],
  loading: boolean,
  data?: T,
  viewByMode: ExportJobViewBy = ExportJobViewBy.CommunityMember
): SnapshotTileProps[] =>
  snapshotDefinitions.map(item => ({
    color: colors[domain].graph,
    domain,
    loading,
    peopleListParams: item.listType
      ? {
          viewByMode,
          viewByDomain: domain,
          listType: item.listType,
        }
      : undefined,
    title: item.title,
    tooltip: item.tooltip,
    trend: data ? item.getTrend?.(data) : undefined,
    value: data ? item.getValue(data) : null,
    isPromotional: !!item.isPromotional,
  }));
