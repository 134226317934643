import { OrganizationGivingPeriodSummaryNew } from '@src/graphql/generated';

export type PeriodSummaryForTotals = Omit<OrganizationGivingPeriodSummaryNew, 'nonCashGiftsAggregate'>;
export const getTotalsForPeriodData = (
  periods: Array<PeriodSummaryForTotals>
): {
  totalDonations: number;
  totalGiving: number;
} => {
  const totalDonations = 0;
  const totalGiving = 0.0;
  return periods.reduce(
    (accumulator, currentPeriod) => ({
      totalDonations: accumulator.totalDonations + currentPeriod.paymentsAggregate.count,
      totalGiving: accumulator.totalGiving + parseFloat(currentPeriod.paymentsAggregate.totalValue.amount),
    }),
    {
      totalDonations,
      totalGiving,
    }
  );
};
