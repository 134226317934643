import * as React from 'react';

import { Columns, Column } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { CUSTOM_DESKTOP_MIN_WIDTH } from '@src/constants/customBreakpoints';
import { useCustomBreakpoint } from '@src/hooks';

import { useStyles } from './headerStyles';

export interface HeaderProps {
  leftContent?: React.ReactNode | string;
  rightContent?: React.ReactNode | string;
}

type HeaderComponentProps = ComponentProps<HeaderProps, typeof useStyles>;

export const Header = ({ leftContent = null, rightContent = null, classes: classesProps }: HeaderComponentProps) => {
  const classes = useStyles(classesProps);
  const wrapper = React.useRef(null);
  const isDesktop = useCustomBreakpoint(CUSTOM_DESKTOP_MIN_WIDTH);
  const columnsClasses = clsx(classes.columns, {
    [classes.mobileAndTabletColumns]: !isDesktop,
  });
  const innerRightClasses = clsx(classes.innerRightBase, {
    [classes.mobileAndTabletInnerRight]: !isDesktop,
    [classes.desktopInnerRight]: isDesktop,
  });

  return (
    <div className={classes.header} ref={wrapper}>
      <Columns className={columnsClasses} stackWhen="NEVER">
        <Column classes={{ root: classes.column, inner: classes.inner }}>{leftContent}</Column>
        <Column classes={{ root: classes.column, inner: innerRightClasses }}>{rightContent}</Column>
      </Columns>
    </div>
  );
};
