import * as React from 'react';

import { AttenderListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { DirectionIsGood } from '@src/constants/trend';
import { AttendanceDashboardCardQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { formatCountOrNull, getPotentialAttenders } from '@src/utils/calculateSnapshots';

import { useGetTrendProps } from '../../../hooks';
import { SnapshotDefinition } from '../types';

export const useAttenderSnapshotDefinitions = (): SnapshotDefinition<AttendanceDashboardCardQuery>[] => {
  const { translate } = useTranslation();
  const getTrendProps = useGetTrendProps();

  return React.useMemo(
    () => [
      {
        listType: AttenderListType.RecentNew,
        title: translate(`snapshots.${ViewByDomain.Attenders}.recentNewAttenders`),
        tooltip: translate(`infoText.attendance.peopleList.RecentNew.CommunityMember`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Attenders].RecentNew,
            data?.Attenders?.LastData?.aggregations?.RecentNew ?? 0,
            data?.Attenders?.PreviousData?.aggregations?.RecentNew ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Attenders?.LastData?.aggregations?.RecentNew),
      },
      {
        listType: AttenderListType.AtRisk,
        title: translate(`snapshots.${ViewByDomain.Attenders}.atRiskAttenders`),
        tooltip: translate(`infoText.attendance.peopleList.AtRisk.CommunityMember`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Attenders].AtRisk,
            data?.Attenders?.LastData?.aggregations?.AtRisk ?? 0,
            data?.Attenders?.PreviousData?.aggregations?.AtRisk ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Attenders?.LastData?.aggregations?.AtRisk),
      },
      {
        listType: AttenderListType.Potential,
        title: translate(`snapshots.${ViewByDomain.Attenders}.potentialAttenders`),
        tooltip: translate(`infoText.attendance.peopleList.Potential.CommunityMember`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Attenders].Potential,
            (data?.Attenders?.LastData?.aggregations?.Curious ?? 0) +
              (data?.Attenders?.LastData?.aggregations?.Returning ?? 0),
            (data?.Attenders?.PreviousData?.aggregations?.Curious ?? 0) +
              (data?.Attenders?.PreviousData?.aggregations?.Returning ?? 0)
          ),
        getValue: data =>
          getPotentialAttenders(
            data?.Attenders?.LastData?.aggregations?.Curious,
            data?.Attenders?.LastData?.aggregations?.Returning
          ),
      },
    ],
    [getTrendProps, translate]
  );
};
