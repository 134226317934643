import * as React from 'react';

import { useFeature } from '@src/featureFlags';
import { MyOrganizationQuery } from '@src/graphql/generated';
import { useMyIdentity } from '@src/myContext';

import { useValidatePendoGuide, ValidatePendoGuide } from './useValidatePendoGuide';

type PendoVisitorMetadata = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type PendoAccountMetadata = {
  id: string;
  name: string;
  organizationId: string;
  ccbTenantUrl: string;
};

type PendoInitializeArgs = {
  visitor: PendoVisitorMetadata;
  account: PendoAccountMetadata;
  events?: {
    ready?(): void;
  };
};

declare const pendo: {
  initialize(args: PendoInitializeArgs): void;
  events: {
    validateGuide(validateGuide: ValidatePendoGuide): void;
  };
  isInitialized: boolean;
};

declare global {
  interface Window {
    Cypress: unknown;
  }
}

export function useInitializePendo() {
  const { validatePendoGuide } = useValidatePendoGuide();
  const myIdentity = useMyIdentity();
  const disablePendoWhiteListValidation = useFeature('DisablePendoWhiteListValidation');

  const initializePendo = React.useCallback(
    (organization: MyOrganizationQuery['organization']) => {
      if (SKIP_PENDO || window.Cypress) {
        return;
      }

      const { identityKey, primaryEmailAddress, lastName, firstName } = myIdentity;
      const key = organization?.key;
      const details = organization?.details;

      if (!pendo.isInitialized) {
        pendo.initialize({
          visitor: {
            id: identityKey,
            email: primaryEmailAddress?.emailAddress ?? '',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
          },
          account: {
            id: details?.ecgId ?? '',
            name: details?.name ?? '',
            // this is named organizationId for backwards compatibility
            // with Platform and MAS
            organizationId: key ?? '',
            ccbTenantUrl: organization?.details?.productInformation?.chmsInformation?.tenantUrl ?? '',
          },
          ...(!disablePendoWhiteListValidation && {
            events: {
              ready: () => pendo.events.validateGuide(validatePendoGuide),
            },
          }),
        });
        pendo.isInitialized = true;
      }
    },
    [disablePendoWhiteListValidation, myIdentity, validatePendoGuide]
  );

  return { initializePendo };
}
