import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { desktopGutterWidth, tabletGutterWidth } from '@src/pages/Dashboard/styleConsts';

export const useStyles = createUseStyles((theme: Theme) => ({
  aboutIcons: {
    color: theme.colors['grey-800'],
    fontSize: theme.SPACING.MEDIUM,
  },
  centerDot: {
    fontSize: theme.SPACING.XXSMALL,
  },
  collapsibleBannerHeader: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.SPACING.SMALL_ICON_SIZE,
    color: theme.colors['grey-1000'],
  },
  collapsibleBannerRoot: {
    borderLeft: 'none',
    borderTop: 'none',
    paddingBottom: theme.SPACING.XSMALL,
    paddingTop: '11px',
  },
  columnInner: {
    paddingLeft: theme.SPACING.NONE,
  },
  footerRoot: {
    height: 'initial',
    paddingBottom: theme.SPACING.SMALL,
    paddingTop: theme.SPACING.SMALL,
    marginTop: theme.SPACING.MEDIUM,
  },
  pageContent: {
    marginBottom: '50px',
    marginTop: theme.SPACING.MEDIUM,
    marginInline: `${desktopGutterWidth}px`,
    width: 'auto',

    [theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
      marginInline: `${tabletGutterWidth}px`,
    },
  },
  pageHeaderContainer: {
    paddingBottom: '40px',
    [theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
      paddingBottom: theme.SPACING.NONE,
    },
  },
  rightSide: {
    whiteSpace: 'nowrap',
  },
}));
