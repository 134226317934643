import * as React from 'react';

import type { NotificationType } from '@pushpay/notification';

type Notification = {
  autoCloses: boolean;
  message: string;
  title: string;
  type: NotificationType;
};

type NotificationContextValue = {
  isOpen: boolean;
  notification: Notification;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
};

export const defaultNotification: Notification = {
  autoCloses: true,
  message: '',
  title: '',
  type: 'info',
};

const NotificationContext = React.createContext<NotificationContextValue | undefined>(undefined);

export const NotificationProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [notification, setNotification] = React.useState<Notification>(defaultNotification);

  const value = {
    isOpen,
    notification,
    setIsOpen,
    setNotification,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationContextProvider');
  }

  return context;
};
