import * as React from 'react';

import { ExportJob, useCancelExportJobMutation } from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';

export const useCancelExportJob = () => {
  const { key: organizationKey } = useMyOrganization();
  const [onCancelExportJobMutation] = useCancelExportJobMutation();

  const onCancelExportJob = React.useCallback(
    (job: ExportJob) =>
      onCancelExportJobMutation({
        variables: {
          organizationKey,
          cancelExportJobInput: {
            jobKey: job.jobKey,
          },
        },
      }),
    [onCancelExportJobMutation, organizationKey]
  );

  return onCancelExportJob;
};
