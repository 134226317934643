import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { DonorListType, ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { Permissions } from '@pushpay/identity.permissions';

import { useFeature } from '@src/featureFlags';
import { TOP_DATE_FORMAT } from '@src/Giving/TopDonorsOverview/components/TopDonorsOverviewTable/constants';
import {
  CursorPagingInput,
  CursorPagingOutput,
  MembershipType,
  usePeopleListByCommunityMemberLazyQuery,
  useTopCommunityMemberDonorSummaryLazyQuery,
} from '@src/graphql/generated';
import { useChmsMembershipTypes, usePeopleListFilters } from '@src/hooks';
import { useMyOrganization, useMyPermissions } from '@src/myContext';
import { initializedPagingOutput, PAGE_SIZE } from '@src/pages/PeopleList/components/PeopleListDesktopView/constants';
import { PeopleListTableRowsResult, TableRow } from '@src/types/PeopleList';

import { getSort, mapCampusKeyToName } from './utils';
import { PeopleListSearchParams } from '../usePeopleListTableRowsLazyQuery';

export const useCommunityMemberTableRowsLazyQuery = (): [
  (
    pagingInput: CursorPagingInput,
    params: PeopleListSearchParams,
    viewByDomain: ViewByDomain,
    listType: ListType
  ) => Promise<PeopleListTableRowsResult>,
  PeopleListTableRowsResult
] => {
  const [error, setError] = React.useState<ApolloError>();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableRow[]>([]);
  const [paging, setPaging] = React.useState<CursorPagingOutput>(initializedPagingOutput);
  const disablePQS = useFeature('DisablePQS');
  const isPeopleListExtraColumnsEnabled = useFeature('PeopleListExtraColumns');
  const { hasOrganizationPermission } = useMyPermissions();
  const hasGivingFullAccess = hasOrganizationPermission(Permissions.givingFullAccess);

  const getPeopleListFilters = usePeopleListFilters();
  const { key, details } = useMyOrganization();
  const membershipTypes = useChmsMembershipTypes();

  const [getCommunityMembers] = usePeopleListByCommunityMemberLazyQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: PAGE_SIZE,
      },
      isPeopleListExtraColumnsEnabled,
    },
  });

  const [getTopDonorCommunityMembers] = useTopCommunityMemberDonorSummaryLazyQuery();

  const mapMembershipTypeIdToName = React.useCallback(
    (id: number | null | undefined, membershipTypeOptions: MembershipType[]): string => {
      if (!id) return '';
      const idString = id.toString();
      const membershipTypeOption = membershipTypeOptions.find(option => option.id.toString() === idString);
      return membershipTypeOption ? membershipTypeOption.name : '';
    },
    []
  );

  const getRows = React.useCallback(
    async (
      pagingInput: CursorPagingInput,
      params: PeopleListSearchParams,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      viewByDomain: ViewByDomain,
      listType: ListType
    ) => {
      setLoading(true);

      if (listType === DonorListType.TopDonors) {
        const { startDate, endDate } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.TopDonors);

        if (!startDate || !endDate) {
          setLoading(false);

          return {
            loading: false,
            error: new Error('Invalid date range for Top Donors'),
            rows: [],
            paging: {
              hasNextPage: false,
              hasPreviousPage: false,
              size: pagingInput.size,
              totalItemCount: 0,
            },
          };
        }

        const { error: newError, data } = await getTopDonorCommunityMembers({
          variables: {
            organizationKey: key,
            cursor: {
              size: pagingInput.size,
              after: pagingInput.after,
              before: pagingInput.before,
            },
            queryFilter: {
              dateRange: {
                startDate: dateFns.format(startDate, TOP_DATE_FORMAT),
                endDate: dateFns.format(endDate, TOP_DATE_FORMAT),
              },
            },
            startDate,
            endDate,
            disablePQS,
          },
        });
        const newPaging =
          data?.organization?.organizationCursoredTopCommunityMembers?.paging || initializedPagingOutput;
        const newRows: TableRow[] =
          data?.organization?.organizationCursoredTopCommunityMembers?.items.map(item => ({
            id: item.communityMemberKey,
            individualId: item.communityMember?.ccbIndividual?.individualId || '',
            firstName: item.communityMember?.firstName || '',
            nickname: item.communityMember?.nickname || '',
            lastName: item.communityMember?.lastName || '',
            email: item.communityMember?.email?.address || '',
            phoneNumber: item.communityMember?.phoneNumber?.number || '',
            attendanceStatus: item.communityMember?.attendanceStatus || '',
            givingStatus: item.communityMember?.givingStatus || '',
            servingStatus: item.communityMember?.servingStatus || '',
            ccbIndividualId: item.communityMember?.ccbIndividual?.individualId.toString() || '',
            ccbMembershipTypeName:
              mapMembershipTypeIdToName(item.communityMember?.ccbMembershipTypeId, membershipTypes) || '',
            homeCampusName: mapCampusKeyToName(item.communityMember?.campusKey, details?.campuses),
            givingTotal: item.giftAmount.amount,
            numberOfGifts: item.paymentCount,
            lifeTimeGivingTotal: item.communityMember?.lifeTimeGivingTotal || '',
            ytdLargestGiftAmount: item.communityMember?.yearToDateLargestGiftAmount || '',
            ytdLargestGiftDate: item.communityMember?.yearToDateLargestGiftDate
              ? dateFns.format(new Date(item.communityMember?.yearToDateLargestGiftDate), 'dd MMM yyyy')
              : '',
            ytdLargestGiftFund: item.communityMember?.ytdLargestGiftFund?.name || '',
            ytdLargestGiftListing: item.communityMember?.ytdLargestGiftMerchant?.name || '',
            lastGiftAmount: item.communityMember?.lastGiftAmount || '',
            lastGiftDate: item.communityMember?.lastGiftDate
              ? dateFns.format(new Date(item.communityMember?.lastGiftDate), 'dd MMM yyyy')
              : '',
            lastGiftFund: item.communityMember?.lastGiftFund?.name || '',
            lastGiftListing: item.communityMember?.lastGiftMerchant?.name || '',
            createdOn: item.communityMember?.createdOn
              ? dateFns.format(new Date(item.communityMember?.createdOn), 'dd MMM yyyy')
              : '',
          })) || [];
        setError(error);

        setRows(newRows);
        setPaging(newPaging);
        setLoading(false);

        return {
          loading: false,
          error: newError,
          rows: newRows,
          paging: newPaging,
        };
      }

      const extraColumnsForPeopleList =
        isPeopleListExtraColumnsEnabled && viewByDomain === 'Donors' && hasGivingFullAccess;

      const { error: newError, data } = await getCommunityMembers({
        variables: {
          organizationKey: key,
          campusKeys: params.campusKeys,
          paging: {
            size: pagingInput.size,
            after: pagingInput.after,
            before: pagingInput.before,
            sortings: getSort(params.sort),
          },
          queryParams: {
            endDate: params.endDate ?? undefined,
            filters: params.filters,
            search: params.searchTerm,
            startDate: params.startDate ?? undefined,
          },
          isPeopleListExtraColumnsEnabled: extraColumnsForPeopleList,
        },
      });
      const newPaging = data?.organization?.communityMembersCursor?.paging || initializedPagingOutput;
      const newRows: TableRow[] =
        data?.organization?.communityMembersCursor?.items.map(item => ({
          id: item.communityMemberKey,
          individualId: item.ccbIndividual?.individualId || '',
          firstName: item.firstName || '',
          nickname: item.nickname || '',
          lastName: item.lastName || '',
          email: item.email?.address || '',
          phoneNumber: item.phoneNumber?.number || '',
          attendanceStatus: item.attendanceStatus || '',
          createdOn: item.createdOn ? dateFns.format(new Date(item.createdOn), 'dd MMM yyyy') : '',
          givingStatus: item.givingStatus || '',
          servingStatus: item.servingStatus || '',
          ccbIndividualId: item.ccbIndividual?.individualId.toString() || '',
          ccbMembershipTypeName: mapMembershipTypeIdToName(item.ccbMembershipTypeId, membershipTypes) || '',
          homeCampusName: mapCampusKeyToName(item.campusKey, details?.campuses),
          lifeTimeGivingTotal: item.lifeTimeGivingTotal || '',
          ytdLargestGiftAmount: item.yearToDateLargestGiftAmount || '',
          ytdLargestGiftDate: item.yearToDateLargestGiftDate
            ? dateFns.format(new Date(item.yearToDateLargestGiftDate), 'dd MMM yyyy')
            : '',
          ytdLargestGiftFund: item.ytdLargestGiftFund?.name || '',
          ytdLargestGiftListing: item.ytdLargestGiftMerchant?.name || '',
          lastGiftAmount: item.lastGiftAmount || '',
          lastGiftDate: item.lastGiftDate ? dateFns.format(new Date(item.lastGiftDate), 'dd MMM yyyy') : '',
          lastGiftFund: item.lastGiftFund?.name || '',
          lastGiftListing: item.lastGiftMerchant?.name || '',
        })) || [];
      setError(error);

      setRows(newRows);
      setPaging(newPaging);
      setLoading(false);

      return {
        loading: false,
        error: newError,
        rows: newRows,
        paging: newPaging,
      };
    },
    [
      getCommunityMembers,
      key,
      isPeopleListExtraColumnsEnabled,
      hasGivingFullAccess,
      error,
      getPeopleListFilters,
      getTopDonorCommunityMembers,
      disablePQS,
      mapMembershipTypeIdToName,
      membershipTypes,
      details?.campuses,
    ]
  );

  return [
    getRows,
    {
      error,
      loading,
      rows,
      paging,
    },
  ];
};
