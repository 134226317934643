import {
  AttendanceQueryFilters,
  DateRangeInput,
  Granularity,
  useOrganizationAttendanceOverviewWithPreviousQuery,
} from '@src/graphql/generated';
import { getChartDataFromOrganizationStagesOverview } from '@src/utils/chartData';

import { useAttendanceQueryFiltersWithGlobalSettingsApplied } from './useAttendanceQueryFiltersWithGlobalSettingsApplied';
import { AttendanceChartWithComparison } from '../types/attendance';

type AttendanceHookProperties = {
  granularity: Granularity;
  lastDateRange: DateRangeInput;
  selectedFilters: AttendanceQueryFilters;
  organizationKey: string;
  previousDateRange: DateRangeInput;
};

export const useAttendanceChartWithComparison = ({
  granularity,
  lastDateRange,
  selectedFilters,
  organizationKey,
  previousDateRange,
}: AttendanceHookProperties): AttendanceChartWithComparison => {
  const attendanceQueryFilters = useAttendanceQueryFiltersWithGlobalSettingsApplied(selectedFilters);

  const {
    data: rawData,
    error,
    loading,
  } = useOrganizationAttendanceOverviewWithPreviousQuery({
    variables: {
      organizationKey,
      attendanceOverviewInput: {
        dateRange: lastDateRange,
        granularity,
        attendanceQueryFilters,
      },
      comparisonDatesInput: {
        last: lastDateRange,
        previous: previousDateRange,
      },
      previousAttendanceOverviewInput: {
        dateRange: previousDateRange,
        granularity,
        attendanceQueryFilters,
      },
    },
  });

  const { comparisonHighlight, data, previousData } = rawData?.organizationAttendanceOverview || {};

  const lastChartData = data ? getChartDataFromOrganizationStagesOverview(data) : [];
  const previousChartData = previousData ? getChartDataFromOrganizationStagesOverview(previousData) : [];

  return {
    data: {
      comparisonHighlight,
      highlights: rawData?.organizationAttendanceOverview?.highlights,
      lastChartData,
      previousChartData,
    },
    error,
    loading,
  };
};
