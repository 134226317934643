import * as React from 'react';

import {
  // eslint-disable-next-line camelcase
  Chms_CursorPagingInput,
  CursorPagingOutput,
  ProcessQueue,
  useQueuesByProcessIdLazyQuery,
} from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';

export const useChmsQueues = () => {
  const { key: organizationKey } = useMyOrganization();
  const [getQueues] = useQueuesByProcessIdLazyQuery();

  return React.useCallback(
    async (processId?: number) => {
      const queues: ProcessQueue[] = [];
      // eslint-disable-next-line camelcase
      const pagingInput: Chms_CursorPagingInput = {
        size: 25,
      };
      let pagingOutput: Pick<CursorPagingOutput, 'hasNextPage' | 'last'> | undefined;

      if (!processId) {
        return undefined;
      }

      do {
        pagingInput.after = pagingOutput?.last;
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getQueues({
          variables: {
            organizationKey,
            processId,
            paging: pagingInput,
          },
        });

        const queuesPage = data?.organization?.queues;
        if (queuesPage?.items) {
          queues.push(...queuesPage.items);
        }

        pagingOutput = queuesPage?.paging;
      } while (pagingOutput?.hasNextPage);

      return queues;
    },
    [getQueues, organizationKey]
  );
};
