import * as React from 'react';

import { ExportJobOptions, ExportJobSortInput, useQueueExportJobMutation } from '@src/graphql/generated';
import { useMyIdentity, useMyOrganization } from '@src/myContext';

export const useQueueExportJob = () => {
  const [queueExportJob] = useQueueExportJobMutation({});
  const { key: organizationKey } = useMyOrganization();
  const profile = useMyIdentity();

  const onQueueExportJob = React.useCallback(
    (jobOptions: ExportJobOptions) => {
      const sortInput = jobOptions?.sort
        ? ({
            columnName: jobOptions.sort?.columnName,
            direction: jobOptions.sort?.direction,
          } as ExportJobSortInput)
        : undefined;

      return queueExportJob({
        variables: {
          organizationKey,
          createExportInput: {
            type: jobOptions?.type,
            membershipTypeIds: jobOptions.membershipTypeIds,
            searchTerm: jobOptions.searchTerm,
            sort: sortInput,
            viewBy: jobOptions.viewBy,
            attendanceStatus: jobOptions.attendanceStatus,
            campusKeys: jobOptions.campusKeys,
            donorStatus: jobOptions.donorStatus,
            volunteerStatus: jobOptions.volunteerStatus,
            timeZoneId: profile.olsonTimeZone,
          },
        },
      });
    },
    [organizationKey, profile.olsonTimeZone, queueExportJob]
  );

  return onQueueExportJob;
};
