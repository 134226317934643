import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { DonorListType, isDonor, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { useToggle } from '@pushpay/utils';

import { useFeature } from '@src/featureFlags';
import { TOP_DATE_FORMAT } from '@src/Giving/TopDonorsOverview/components/TopDonorsOverviewTable/constants';
import { useCommunityMemberDonorListTotalsLazyQuery, usePeopleCountLazyQuery } from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { usePeopleList } from '@src/pages/PeopleList/components/PeopleListProvider';
import { convertClFilterConfigsToFilterMenuFilters } from '@src/pages/PeopleList/components/PeopleListProvider/utils';

import { DonorPeopleListTotals, DonorPeopleListTotalsQueryResult, emptyTotals } from '../types';

const isApolloError = (item: ApolloError | undefined | null): item is ApolloError => !!item;

export const useCommunityMemberDonorPeopleListTotalsLazyQuery = (): [
  () => Promise<DonorPeopleListTotalsQueryResult>,
  DonorPeopleListTotalsQueryResult
] => {
  const [error, setError] = React.useState([] as ApolloError[]);
  const { isOn: loading, toggle: setLoading } = useToggle();
  const getPeopleListFilters = usePeopleListFilters();
  const disablePQS = useFeature('DisablePQS');
  const { clFilterConfigs } = usePeopleList();
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);
  const [totals, setTotals] = React.useState<DonorPeopleListTotals>(emptyTotals);

  const { startDate: topDonorsStartDate, endDate: topDonorsEndDate } = getPeopleListFilters(
    ViewByDomain.Donors,
    DonorListType.TopDonors
  );

  if (!topDonorsStartDate || !topDonorsEndDate) {
    throw new Error('No date range for top donors');
  }

  const { key } = useMyOrganization();

  const { filters: donorTotalsFilters } = getPeopleListFilters(
    ViewByDomain.Donors,
    DonorListType.All,
    filterMenuFilters
  );
  const [getDonorListTotals] = useCommunityMemberDonorListTotalsLazyQuery({
    variables: {
      disablePQS,
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      topDonorsCursor: {
        size: 0,
      },
      queryParams: {
        filters: [...donorTotalsFilters, isDonor],
      },
      allIndividualsQueryParams: {
        filters: donorTotalsFilters,
      },
      topDonorQueryFilter: {
        dateRange: {
          startDate: dateFns.format(topDonorsStartDate, TOP_DATE_FORMAT),
          endDate: dateFns.format(topDonorsEndDate, TOP_DATE_FORMAT),
        },
      },
    },
  });

  const {
    endDate: newRecurringDonorsEndDate,
    filters: newRecurringDonorsFilters,
    startDate: newRecurringDonorsStartDate,
  } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.NewRecurring, filterMenuFilters);
  const [getNewRecurringDonorsCount] = usePeopleCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        endDate: newRecurringDonorsEndDate,
        filters: newRecurringDonorsFilters,
        startDate: newRecurringDonorsStartDate,
      },
    },
  });

  const { filters: nonDonorFilters } = getPeopleListFilters(
    ViewByDomain.Donors,
    DonorListType.NonDonors,
    filterMenuFilters
  );
  const [getNonDonorCount] = usePeopleCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: nonDonorFilters,
      },
    },
  });

  const {
    endDate: recentNewDonorsEndDate,
    filters: recentNewDonorsFilters,
    startDate: recentNewDonorsStartDate,
  } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.RecentNew, filterMenuFilters);
  const [getRecentNewCounts] = usePeopleCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: { size: 0 },
      queryParams: {
        endDate: recentNewDonorsEndDate,
        filters: recentNewDonorsFilters,
        startDate: recentNewDonorsStartDate,
      },
    },
  });

  const getTotals = React.useCallback(async () => {
    setLoading(true);

    const { error: nonDonorError, data: nonDonorData } = await getNonDonorCount();
    const { error: listTotalsError, data: listTotalsData } = await getDonorListTotals();
    const { error: recentNewError, data: recentNewData } = await getRecentNewCounts();
    const { error: newRecurringError, data: newRecurringData } = await getNewRecurringDonorsCount();

    const newError = [listTotalsError, newRecurringError, nonDonorError, recentNewError].filter(isApolloError);
    setError(newError);

    const nonDonorCount = nonDonorData?.organization?.communityMembersCursor?.paging.totalItemCount;
    const isDonorAggregations = listTotalsData?.organization?.IsDonorCounts?.aggregations;
    const allPeople = listTotalsData?.organization?.AllCount?.aggregations.allPeople;
    const recentNewDonorsCount = recentNewData?.organization?.communityMembersCursor?.paging.totalItemCount;
    const newRecurringDonorsCount = newRecurringData?.organization?.communityMembersCursor?.paging.totalItemCount;
    const topDonorsCount = listTotalsData?.organization?.TopDonorsCount?.paging.totalItemCount;

    let newTotals = emptyTotals;
    if (nonDonorCount) {
      newTotals = {
        ...newTotals,
        NonDonors: nonDonorCount,
      };
    }

    if (topDonorsCount) {
      newTotals = {
        ...newTotals,
        TopDonors: topDonorsCount,
      };
    }

    if (recentNewDonorsCount) {
      newTotals = {
        ...newTotals,
        RecentNew: recentNewDonorsCount,
      };
    }

    if (newRecurringDonorsCount) {
      newTotals = {
        ...newTotals,
        NewRecurring: newRecurringDonorsCount,
      };
    }

    if (isDonorAggregations) {
      newTotals = {
        ...newTotals,
        AtRisk: isDonorAggregations.atRiskDonors ?? 0,
        FirstTime: isDonorAggregations.firstTimeDonors ?? 0,
        Lapsed: isDonorAggregations.lapsedDonors ?? 0,
        Occasional: isDonorAggregations.occasionalDonors ?? 0,
        Potential: isDonorAggregations.potentialDonors ?? 0,
        Recurring: isDonorAggregations.recurringDonors ?? 0,
        Regular: isDonorAggregations.regularDonors ?? 0,
        SecondTime: isDonorAggregations.secondTimeDonors ?? 0,
      };
    }

    if (allPeople) {
      newTotals = {
        ...newTotals,
        All: allPeople,
      };
    }

    setTotals(newTotals);
    setLoading(false);

    return { loading: false, error: newError, totals: newTotals };

    // Do not add setLoading as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDonorListTotals, getNonDonorCount]);

  return [
    getTotals,
    {
      error,
      loading,
      totals,
    },
  ];
};
