import { Direction } from '@src/types/Trend';

export const DirectionIsGood = {
  Attenders: {
    AtRisk: Direction.Down,
    AttendanceChurn: Direction.Down,
    AverageDailyAttendance: Direction.Up,
    AverageMonthlyAttendance: Direction.Up,
    AverageWeeklyAttendance: Direction.Up,
    BurnoutRisk: Direction.Down,
    FirstTime: Direction.Up,
    HeadcountAttendance: Direction.Up,
    IndividualAttendance: Direction.Up,
    Lapsed: Direction.Down,
    Non: Direction.Down,
    Occasional: Direction.Down,
    PercentOfPeople: Direction.Up,
    Potential: Direction.Up,
    PotentialInactive: Direction.Down,
    RecentNew: Direction.Up,
    Regular: Direction.Up,
    SecondTime: Direction.Up,
    StreamingAttendance: Direction.Up,
    UniqueHouseholds: Direction.Up,
    UniqueIndividuals: Direction.Up,
  },
  Donors: {
    AtRisk: Direction.Down,
    AverageDonation: Direction.Up,
    BurnoutRisk: Direction.Down,
    FirstTime: Direction.Up,
    GivingPerAttendee: Direction.Up,
    Lapsed: Direction.Down,
    NewRecurringDonor: Direction.Up,
    Non: Direction.Down,
    Occasional: Direction.Down,
    PercentOfPeople: Direction.Up,
    PhysicalPerOnlineGiving: Direction.Down,
    Potential: Direction.Up,
    RecentNew: Direction.Up,
    RecurringDonor: Direction.Up,
    Regular: Direction.Up,
    SecondTime: Direction.Up,
    TotalDonations: Direction.Up,
    UniqueHouseholds: Direction.Up,
    UniqueIndividuals: Direction.Up,
  },
  Volunteers: {
    AtRisk: Direction.Down,
    BurnoutRisk: Direction.Down,
    FirstTime: Direction.Up,
    Lapsed: Direction.Down,
    Non: Direction.Down,
    Occasional: Direction.Down,
    PercentOfPeople: Direction.Up,
    Potential: Direction.Up,
    RecentNew: Direction.Up,
    Regular: Direction.Up,
    SecondTime: Direction.Up,
    UnfilledServingRoles: Direction.Down,
    UniqueHouseholds: Direction.Up,
    UniqueIndividuals: Direction.Up,
    UniquePositions: Direction.Up,
    UniqueTeams: Direction.Up,
    VolunteersPerOpportunity: Direction.Up,
  },
};
