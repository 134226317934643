export enum AxisLegendPosition {
  Start = 'start',
  Middle = 'middle',
  End = 'end',
}

export enum LegendDataFrom {
  Keys = 'keys',
  Indexes = 'indexes',
}

export enum GroupMode {
  Grouped = 'grouped',
  Stacked = 'stacked',
}

export enum LegendAnchor {
  Top = 'top',
  TopRight = 'top-right',
  Right = 'right',
  BottomRight = 'bottom-right',
  Bottom = 'bottom',
  BottomLeft = 'bottom-left',
  Left = 'left',
  TopLeft = 'top-left',
  Center = 'center',
}

export enum LegendDirection {
  Column = 'column',
  Row = 'row',
}

export enum LegendItemDirection {
  LeftToRight = 'left-to-right',
  RightToLeft = 'right-to-left',
  TopToBottom = 'top-to-bottom',
  BottomToTop = 'bottom-to-top',
}

export enum SymbolShape {
  Circle = 'circle',
  Diamond = 'diamond',
  Square = 'square',
  Triangle = 'triangle',
}

type BoxLegendSymbolProps = Partial<{
  symbolShape: SymbolShape; // | React.FC<SymbolProps>;
  symbolSize: number;
  symbolSpacing: number;
  symbolBorderWidth: number;
  symbolBorderColor: string;
}>;

type EffectProps = {
  on: 'hover';
  style: Partial<{
    itemTextColor: string;
    itemBackground: string;
    itemOpacity: number;
    symbolSize: number;
    symbolBorderWidth: number;
    symbolBorderColor: string;
  }>;
};

type LayoutCommonLegendProps = {
  direction: LegendDirection;
  padding?: number | Partial<Record<'top' | 'right' | 'bottom' | 'left', number>>;
  justify?: boolean;
  itemWidth: number;
  itemHeight: number;
  itemDirection?: LegendItemDirection;
  itemTextColor?: string;
  itemBackground?: string;
  itemOpacity?: number;
  itemsSpacing?: number;
  effects?: EffectProps[];
};

type LegendProps = {
  translateX?: number;
  translateY?: number;
  anchor: LegendAnchor;
  toggleSerie?: boolean;
} & LayoutCommonLegendProps &
  BoxLegendSymbolProps;

export interface ChartLegendProps extends LegendProps {
  dataFrom: LegendDataFrom;
}
