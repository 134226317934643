import {
  desktopGutterWidth,
  gapWidth,
  minCardWidth,
  sidebarWidth,
  tabletGutterWidth,
} from '@src/pages/Dashboard/styleConsts';

export const MIN_EXPANDED_XLARGE_AND_ABOVE = '(min-width: 1260px)';

export const MIN_MEDIUM_AND_ABOVE = '(min-width: 1170px)';

const minTabletWidth = sidebarWidth + minCardWidth * 2 + gapWidth + tabletGutterWidth * 2;
const minDesktopWidth = sidebarWidth + minCardWidth * 4 + gapWidth * 3 + desktopGutterWidth * 3;

export const CUSTOM_DESKTOP_MIN_WIDTH = `(min-width: ${minDesktopWidth}px)`;
export const CUSTOM_TABLET_RANGE = `(min-width: ${minTabletWidth}px) and (max-width: ${minDesktopWidth - 1}px)`;
export const CUSTOM_MOBILE_MAX_WIDTH = `(max-width: ${minTabletWidth - 1}px)`;
