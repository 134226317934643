import * as React from 'react';

import { AttenderListType, ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import { useSearchParams } from 'react-router-dom';

import { ExpandStatus, useExpand } from '@pushpay/expand';
import { FilterConfig, FiltersValue } from '@pushpay/filters';
import { t } from '@pushpay/i18n';
import { PaginationConfig } from '@pushpay/pagination';

import { useFeature } from '@src/featureFlags';
import { CursorPagingInput, DonorListType, ExportJobViewBy, Filter } from '@src/graphql/generated';
import {
  PeopleListSearchParams,
  useGlobalViewByMode,
  useMediaBreakpoint,
  usePeopleListFilters,
  usePeopleListTableRowsLazyQuery,
} from '@src/hooks';
import { useNotification } from '@src/pages/shell/NotificationProvider';
import useTranslation from '@src/translations';
import { TableRow } from '@src/types/PeopleList';
import { getFilterGroupCount } from '@src/utils/filters';

import { useGetClFilterConfigs } from './hooks/useGetClFilterConfigs';
import { PeopleListFilters, SortingState } from './types';
import {
  convertCLFiltersValueToFilterMenuFilters,
  convertCLFilterConfigsToCLFilterValues,
  defaultCLFiltersValue,
  domainListContainsListType,
  convertClFilterConfigsToFilterMenuFilters,
} from './utils';
import { useHasChmsAccess } from '../../../../hooks/permissions/useHasChmsAccess';
import { PAGE_SIZE } from '../PeopleListDesktopView/constants';

type Rows = Record<number, TableRow[]>;

export type Pagination = Omit<PaginationConfig, 'onNext'> & {
  deprecated?: false;
  onNext: () => Promise<TableRow[]>;
  after?: string;
  before?: string;
};
interface PeopleListAPIOverrides {
  endDate?: Date;
  filters?: Filter[];
  searchTerm?: string;
  sort?: SortingState;
  startDate?: Date;
  viewByMode?: ExportJobViewBy;
  campusKeys?: string[];
  listType?: ListType;
}
export interface PeopleListContextValue {
  clFilterConfigs: FilterConfig<PeopleListFilters>[];
  communityFilters: Filter[];
  filterBadgeCount: number;
  filterMenuExpandStatus: ExpandStatus;
  endDate?: Date;
  error: boolean;
  hasData: boolean;
  listType: ListType;
  loading: boolean;
  onToggleFilterMenuExpandStatus: () => void;
  pagination: Pagination;
  rows: Rows;
  searchTerm: string;
  startDate?: Date;
  sort?: SortingState;
  viewByDomain: ViewByDomain;
  viewByMode: ExportJobViewBy;
  getFirstPage: (overrides?: PeopleListAPIOverrides) => Promise<TableRow[]>;
  onUpdateFilters: (updatedFilters: FiltersValue<PeopleListFilters>) => void;
  setListType: (value: ListType) => void;
  setSearchTerm: (value: string) => void;
  setSort: (value?: SortingState) => void;
  setViewByDomain: (value: ViewByDomain) => void;
  setViewByMode: (value: ExportJobViewBy) => void;
}

type PeopleListProviderProps = React.PropsWithChildren;

const PeopleListContext = React.createContext<PeopleListContextValue | undefined>(undefined);

const PQSLists: ListType[] = [DonorListType.TopDonors];

export const PeopleListProvider = ({ children }: PeopleListProviderProps) => {
  const { viewByMode: viewByModeLocal, setViewByMode: setViewByModeLocal } = useGlobalViewByMode();
  const { translate } = useTranslation();

  // Helper functions
  const getPeopleListFilters = usePeopleListFilters();
  const [getTableRows] = usePeopleListTableRowsLazyQuery();

  // Filter Menu Variables
  const isMobile = useMediaBreakpoint('TABLET_AND_BELOW');
  const hasChmsAccess = useHasChmsAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  const listType = (searchParams.get('listType') as ListType) || AttenderListType.All;
  const viewByDomain = (searchParams.get('viewByDomain') as ViewByDomain) || ViewByDomain.Attenders;
  const viewByMode =
    (searchParams.get('viewByMode') as ExportJobViewBy) || viewByModeLocal || ExportJobViewBy.CommunityMember;
  const { setIsOpen, setNotification } = useNotification();
  const [filterMenuExpandStatus, onToggleFilterMenuExpandStatus] = useExpand(
    !hasChmsAccess || isMobile || listType === DonorListType.TopDonors ? 'collapsed' : 'preexpanded'
  );

  const getClFilterConfigs = useGetClFilterConfigs();

  const [clFilterConfigs, setCLFilterConfigs] = React.useState<FilterConfig<PeopleListFilters>[]>(
    getClFilterConfigs(viewByMode, viewByDomain, listType, defaultCLFiltersValue)
  );
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);
  const filterBadgeCount = getFilterGroupCount(clFilterConfigs);

  const initializedPagination: Pagination = {
    currentPage: 1,
    totalCount: 0,
    pageSize: PAGE_SIZE,
    hasPrevious: false,
    hasNext: false,
    onNext: () => new Promise(() => []),
    onPrevious: () => null,
    recordsLabel: '',
    pageLinkTitles: {
      nextPage: translate('peopleList.tableActions.next'),
      previousPage: translate('peopleList.tableActions.previous'),
    },
  };

  // Community Variables
  const { endDate, filters: listFilters, startDate } = getPeopleListFilters(viewByDomain, listType, filterMenuFilters);
  const [pagination, setPagination] = React.useState<Pagination>(initializedPagination);
  const [searchTerm, setSearchTermState] = React.useState('');
  const [sort, setSortState] = React.useState<SortingState>();
  const [communityFilters, setCommunityFilters] = React.useState<Filter[]>(listFilters);
  const [rows, setRows] = React.useState<Rows>({});
  const [loading, setLoading] = React.useState(false);
  const isDisablePQSEnabled = useFeature('DisablePQS');
  const [error, setError] = React.useState(false);

  const getPage = React.useCallback(
    async (page: number, pagingInput: CursorPagingInput, overrides?: PeopleListAPIOverrides) => {
      setLoading(true);

      const params = {
        endDate,
        filters: communityFilters,
        searchTerm,
        sort,
        startDate,
        campusKeys: filterMenuFilters.campusKeys,
        ...overrides,
      } as PeopleListSearchParams;

      const {
        rows: newRows,
        paging: pagingOutput,
        error: newError,
      } = await getTableRows(
        overrides?.viewByMode || viewByMode,
        viewByDomain,
        overrides?.listType || listType,
        pagingInput,
        params
      );

      setRows({ ...rows, [page]: newRows });
      setPagination({
        ...pagination,
        currentPage: page,
        totalCount: pagingOutput.totalItemCount,
        pageSize: PAGE_SIZE,
        hasPrevious: pagingOutput.hasPreviousPage,
        hasNext: pagingOutput.hasNextPage,
        after: pagingOutput.last || '',
        before: pagingOutput.first || '',
      });

      setError(!!newError || (isDisablePQSEnabled && PQSLists.includes(overrides?.listType || listType)));
      setLoading(false);

      return newRows;
    },
    [
      endDate,
      communityFilters,
      searchTerm,
      sort,
      startDate,
      filterMenuFilters.campusKeys,
      getTableRows,
      viewByMode,
      viewByDomain,
      listType,
      rows,
      pagination,
      isDisablePQSEnabled,
    ]
  );

  const renderNotificationToast = React.useCallback(() => {
    setNotification({
      autoCloses: true,
      message: t('filters.toasts.information.message'),
      title: t('filters.toasts.information.title'),
      type: 'info',
    });
    setIsOpen(true);
  }, [setIsOpen, setNotification, t]);

  const renderNotificationToastIfSelectedFiltersWereDisabled = React.useCallback(
    (newFilterMenuFilters: PeopleListFilters) => {
      const currentFilterMenuFilters: PeopleListFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);

      Object.entries(currentFilterMenuFilters).forEach(([key, value]) => {
        const { membershipTypeIds } = newFilterMenuFilters;
        const newMembershipTypeIdsIsEmpty =
          !membershipTypeIds || (membershipTypeIds?.values.length === 0 && !membershipTypeIds?.isEmpty);
        const currentStagesFiltersHasValues = value && value.length > 0;
        const newStagesFilterIsEmpty = !newFilterMenuFilters[key as keyof PeopleListFilters];
        if (key === 'membershipTypeIds') {
          const currentMembershipTypeIdsHasValues = value?.values.length > 0 || value?.isEmpty;
          if (currentMembershipTypeIdsHasValues && newMembershipTypeIdsIsEmpty) {
            renderNotificationToast();
          }
        } else if (currentStagesFiltersHasValues && newStagesFilterIsEmpty) {
          renderNotificationToast();
        }
      });
    },
    [clFilterConfigs, renderNotificationToast]
  );

  const getFirstPage = React.useCallback(
    async (overrides?: PeopleListAPIOverrides) => {
      const newRows = await getPage(1, { size: PAGE_SIZE }, overrides);
      setRows({ 1: newRows });
      return newRows;
    },
    [getPage]
  );
  const getNextPage = React.useCallback(
    (overrides?: PeopleListAPIOverrides) =>
      getPage(pagination.currentPage + 1, { size: PAGE_SIZE, after: pagination.after }, overrides),
    [getPage, pagination.after, pagination.currentPage]
  );
  const getPreviousPage = React.useCallback(
    (overrides?: PeopleListAPIOverrides) =>
      getPage(pagination.currentPage - 1, { size: PAGE_SIZE, before: pagination.before }, overrides),
    [getPage, pagination.before, pagination.currentPage]
  );

  const onUpdateFilters = React.useCallback(
    (updatedFilters: FiltersValue<PeopleListFilters>) => {
      const newCLFilterConfigs = getClFilterConfigs(viewByMode, viewByDomain, listType, updatedFilters);
      setCLFilterConfigs(newCLFilterConfigs);

      const newFilterMenuFilters = convertCLFiltersValueToFilterMenuFilters(updatedFilters);

      const { filters: newCommunityFilters } = getPeopleListFilters(viewByDomain, listType, newFilterMenuFilters);

      setCommunityFilters(newCommunityFilters);
      getFirstPage({ filters: newCommunityFilters, campusKeys: newFilterMenuFilters.campusKeys });
    },
    [getFirstPage, getPeopleListFilters, listType, viewByDomain]
  );

  const setListType = React.useCallback(
    (newListType: ListType) => {
      const filterMenuExpanded = filterMenuExpandStatus === 'expanded' || filterMenuExpandStatus === 'preexpanded';
      if (newListType === DonorListType.TopDonors && filterMenuExpanded) {
        onToggleFilterMenuExpandStatus();
      }

      const currentClFilterValues = convertCLFilterConfigsToCLFilterValues(clFilterConfigs);
      const newClFilterConfigs = getClFilterConfigs(viewByMode, viewByDomain, newListType, currentClFilterValues);
      const newFilterMenuFilters = convertClFilterConfigsToFilterMenuFilters(newClFilterConfigs);
      const {
        endDate: newEndDate,
        filters: newCommunityFilters,
        startDate: newStartDate,
      } = getPeopleListFilters(viewByDomain, newListType, newFilterMenuFilters);

      renderNotificationToastIfSelectedFiltersWereDisabled(newFilterMenuFilters);
      setCommunityFilters(newCommunityFilters);
      setCLFilterConfigs(newClFilterConfigs);
      setSearchParams({ listType: newListType, viewByDomain, viewByMode });
      setSortState(undefined);
      getFirstPage({
        endDate: newEndDate,
        filters: newCommunityFilters,
        startDate: newStartDate,
        listType: newListType,
      });
    },
    [getFirstPage, getPeopleListFilters, setSearchParams, viewByDomain, viewByMode, filterMenuExpandStatus]
  );

  const setViewByDomain = React.useCallback(
    (newViewByDomain: ViewByDomain) => {
      let newListType = listType;
      if (!domainListContainsListType(newViewByDomain, listType)) {
        newListType = AttenderListType.All;
      }

      const newCLFiltersValue = convertCLFilterConfigsToCLFilterValues(clFilterConfigs);
      const newCLFilterConfigs = getClFilterConfigs(viewByMode, newViewByDomain, newListType, newCLFiltersValue);
      setCLFilterConfigs(newCLFilterConfigs);

      const newFilterMenuFilters = convertClFilterConfigsToFilterMenuFilters(newCLFilterConfigs);

      const {
        endDate: newEndDate,
        filters: newCommunityFilters,
        startDate: newStartDate,
      } = getPeopleListFilters(newViewByDomain, newListType, newFilterMenuFilters);

      renderNotificationToastIfSelectedFiltersWereDisabled(newFilterMenuFilters);

      setCommunityFilters(newCommunityFilters);

      setSearchParams({ listType: newListType, viewByDomain: newViewByDomain, viewByMode });

      getFirstPage({
        endDate: newEndDate,
        filters: newCommunityFilters,
        startDate: newStartDate,
        listType: newListType,
      });
    },
    [getFirstPage, getPeopleListFilters, listType, setSearchParams, viewByMode]
  );

  const setViewByMode = React.useCallback(
    (newViewByMode: ExportJobViewBy) => {
      setViewByModeLocal(newViewByMode);
      const newCLFiltersValue = convertCLFilterConfigsToCLFilterValues(clFilterConfigs);

      const newCLFilterConfigs = getClFilterConfigs(newViewByMode, viewByDomain, listType, newCLFiltersValue);

      setCLFilterConfigs(newCLFilterConfigs);
      setSearchParams({
        listType,
        viewByDomain,
        viewByMode: newViewByMode,
      });
      const newFilterMenuFilters = convertClFilterConfigsToFilterMenuFilters(newCLFilterConfigs);

      const { filters: newCommunityFilters } = getPeopleListFilters(viewByDomain, listType, newFilterMenuFilters);
      renderNotificationToastIfSelectedFiltersWereDisabled(newFilterMenuFilters);
      setCommunityFilters(newCommunityFilters);
      setSortState(undefined);

      getFirstPage({
        viewByMode: newViewByMode,
        filters: newCommunityFilters,
        campusKeys: newFilterMenuFilters.campusKeys,
        sort: undefined,
      });
    },
    [getFirstPage, getPeopleListFilters, listType, setSearchParams, viewByDomain, setViewByModeLocal]
  );

  const setSort = React.useCallback(
    (newSort?: SortingState) => {
      setSortState(newSort);
      getFirstPage({ sort: newSort });
    },
    [getFirstPage]
  );

  const setSearchTerm = React.useCallback(
    (newSearchTerm: string) => {
      setSearchTermState(newSearchTerm);
      getFirstPage({ searchTerm: newSearchTerm });
    },
    [getFirstPage]
  );

  const value = {
    clFilterConfigs,
    endDate,
    error,
    filterBadgeCount,
    filterMenuExpandStatus,
    communityFilters,
    getFirstPage,
    hasData: rows[1] && rows[1].length > 0,
    listType,
    loading,
    onToggleFilterMenuExpandStatus,
    pagination: { ...pagination, onNext: getNextPage, onPrevious: getPreviousPage },
    rows,
    searchTerm,
    onUpdateFilters,
    setListType,
    setSearchTerm,
    setSort,
    setViewByDomain,
    setViewByMode,
    sort,
    startDate,
    viewByDomain,
    viewByMode,
  } as PeopleListContextValue;

  return <PeopleListContext.Provider value={value}>{children}</PeopleListContext.Provider>;
};

export const usePeopleList = () => {
  const context = React.useContext(PeopleListContext);

  if (context === undefined) {
    throw new Error('usePeopleList must be used within a PeopleListContextProvider');
  }

  return context;
};
