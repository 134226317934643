import { ApolloError } from '@apollo/client';

import { Attendance, AttendanceComparisonHighlight, Granularity } from '@src/graphql/generated';
import { ChartData } from '@src/types/ChartAndHighlights';
import { AttendanceFilters } from '@src/types/Filters';

export type AttendanceOverviewContentProperties = {
  attendanceData: AttendanceChartWithComparison | AttendanceChartWithoutComparison;
  selectedFilters: AttendanceFilters;
  setSelectedFilters: React.Dispatch<AttendanceFilters>;
  granularity: Granularity;
};

export type AttendanceChartWithoutComparisonData = {
  comparisonHighlight?: AttendanceComparisonHighlight | null;
  highlights?: Attendance | undefined;
  lastChartData: ChartData;
};

export type AttendanceChartWithComparisonData = AttendanceChartWithoutComparisonData & {
  previousChartData: ChartData;
};

export type AttendanceChartWithComparison = {
  data?: AttendanceChartWithComparisonData;
  error?: ApolloError;
  loading: boolean;
};

export type AttendanceChartWithoutComparison = {
  data?: AttendanceChartWithoutComparisonData;
  error?: ApolloError;
  loading: boolean;
};

export function isAttendanceChartWithComparisonData(
  attendanceChartData: AttendanceChartWithComparisonData | AttendanceChartWithoutComparisonData
): attendanceChartData is AttendanceChartWithComparisonData {
  return (attendanceChartData as AttendanceChartWithComparisonData).previousChartData !== undefined;
}
