import { ApolloError } from '@apollo/client';
import { DonorListType } from '@churchcommunitybuilder/insights-people-list-filtering';

export type DonorPeopleListTotals = Record<DonorListType, number>;
export type DonorPeopleListTotalsQueryResult = {
  error: ApolloError[];
  loading: boolean;
  totals: DonorPeopleListTotals;
};

export const emptyTotals: DonorPeopleListTotals = {
  All: 0,
  FirstTime: 0,
  SecondTime: 0,
  RecentNew: 0,
  Occasional: 0,
  Regular: 0,
  Recurring: 0,
  NewRecurring: 0,
  AtRisk: 0,
  Lapsed: 0,
  Potential: 0,
  NonDonors: 0,
  TopDonors: 0,
};
