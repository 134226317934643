import * as React from 'react';

import { FilterConfig, MultiSelectSection } from '@pushpay/filters';

import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';
import useTranslation from '@src/translations';
import { AvailableFilters } from '@src/types/ChartAndHighlights';
import { AttendanceFilters } from '@src/types/Filters';
import {
  getMembershipTypeFilterValuesStateForCLFilters,
  getMembershipTypeOptionsForCLFilters,
} from '@src/utils/filters';

import { useAttendanceFilterOptions } from './useAttendanceFilterOptions';
import { convertAttendanceTimeFilterToCLTimeFilter } from '../utils';

export function useGetAttendanceClFilterConfigs(selectedFilters: AttendanceFilters) {
  const { translate } = useTranslation();
  const {
    membershipTypeOptions: chmsMembershipTypeOptions,
    selectedMembershipTypes: globalMembershipTypeFilter,
    resiStreamingAttendanceEnabled,
  } = useDataCustomization();
  const attendanceFilters = useAttendanceFilterOptions();
  const membershipTypeOptions = getMembershipTypeOptionsForCLFilters(
    chmsMembershipTypeOptions,
    globalMembershipTypeFilter
  );

  const getEventNameOptions = React.useCallback(
    (filterMenuOptions: Record<keyof AttendanceFilters, Map<string, string>>): MultiSelectSection[] => [
      {
        id: 1,
        options: Array.from(filterMenuOptions.eventNames.values()).map(eventName => ({
          key: eventName,
          name: eventName,
        })),
        sectionTitle: translate('filters.labels.attendance.eventNames.inPersonSectionTitle'),
        selectAllOptionLabel: translate('filters.labels.attendance.eventNames.inPersonSelectAll'),
      },
      {
        id: 2,
        options: Array.from(filterMenuOptions.streamingEventNames.values()).map(streamingEventName => ({
          key: streamingEventName,
          name: streamingEventName,
        })),
        sectionTitle: translate('filters.labels.attendance.eventNames.streamingSectionTitle'),
        selectAllOptionLabel: translate('filters.labels.attendance.eventNames.streamingSelectAll'),
      },
    ],
    [translate]
  );

  const getEventNameFilter = React.useCallback(
    (filterMenuOptions: Record<keyof AttendanceFilters, Map<string, string>>): FilterConfig<AvailableFilters> =>
      resiStreamingAttendanceEnabled
        ? {
            type: 'multiSelect',
            field: 'eventNames',
            defaultLabel: translate(`filters.labels.attendance.eventNames.filterTitle`),
            options: getEventNameOptions(filterMenuOptions),
            filterState: { values: selectedFilters.eventNames || [] },
            hasSelectAllOption: true,
            showSearch: true,
          }
        : {
            type: 'toggle',
            field: 'eventNames',
            defaultLabel: translate(`filters.labels.attendance.eventNames.filterTitle`),
            options: filterMenuOptions.eventNames,
            filterState: { values: selectedFilters.eventNames ?? [] },
            showSearch: true,
          },
    [getEventNameOptions, resiStreamingAttendanceEnabled, selectedFilters.eventNames, translate]
  );

  const clFilters: FilterConfig<AvailableFilters>[] | undefined = React.useMemo(
    () =>
      attendanceFilters.data
        ? [
            {
              type: 'toggle',
              field: 'campusNames',
              defaultLabel: translate(`filters.labels.attendance.campusNames`),
              options: attendanceFilters.data.campusNames,
              filterState: { values: selectedFilters.campusNames ?? [] },
              showSearch: true,
            },
            {
              type: 'toggle',
              field: 'attendanceGroupingNames',
              defaultLabel: translate(`filters.labels.attendance.attendanceGroupingNames`),
              options: attendanceFilters.data.attendanceGroupingNames,
              filterState: { values: selectedFilters.attendanceGroupingNames ?? [] },
              showSearch: true,
            },
            {
              type: 'toggle',
              field: 'departmentNames',
              defaultLabel: translate(`filters.labels.attendance.departmentNames`),
              options: attendanceFilters.data.departmentNames,
              filterState: { values: selectedFilters.departmentNames ?? [] },
              showSearch: true,
            },
            {
              type: 'toggle',
              field: 'groupNames',
              defaultLabel: translate(`filters.labels.attendance.groupNames`),
              options: attendanceFilters.data.groupNames,
              filterState: { values: selectedFilters.groupNames ?? [] },
              showSearch: true,
            },
            {
              type: 'toggle',
              field: 'groupTypeNames',
              defaultLabel: translate(`filters.labels.attendance.groupTypeNames`),
              options: attendanceFilters.data.groupTypeNames,
              filterState: { values: selectedFilters.groupTypeNames ?? [] },
              showSearch: true,
            },
            getEventNameFilter(attendanceFilters.data),
            {
              type: 'toggle',
              field: 'eventDays',
              defaultLabel: translate(`filters.labels.attendance.eventDays`),
              options: attendanceFilters.data.eventDays,
              filterState: { values: selectedFilters.eventDays ?? [] },
              showSearch: true,
            },
            convertAttendanceTimeFilterToCLTimeFilter('eventStartTimes', selectedFilters.eventStartTimes),
            {
              type: 'toggle',
              field: 'membershipTypeIds',
              defaultLabel: translate(`filters.labels.attendance.membershipTypeIds`),
              options: membershipTypeOptions,
              filterState: {
                values: getMembershipTypeFilterValuesStateForCLFilters(
                  membershipTypeOptions,
                  selectedFilters.membershipTypeIds
                ),
              },
              showSearch: true,
            },
            {
              type: 'toggle',
              field: 'attendanceType',
              defaultLabel: translate(`filters.labels.attendance.attendanceType`),
              options: attendanceFilters.data.attendanceType,
              filterState: { values: selectedFilters.attendanceType ?? [] },
              showSearch: true,
            },
          ]
        : undefined,
    [attendanceFilters.data, getEventNameFilter, membershipTypeOptions, selectedFilters, translate]
  );

  return clFilters;
}
