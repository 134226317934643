import * as React from 'react';

import { AttenderListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { DashboardAttendanceViewBy } from '@src/components/DashboardFilterModal/types';
import { AttendanceDashboardTilesQuery } from '@src/graphql/generated';
import { useDashboardFilterMenu } from '@src/hooks';
import useTranslation from '@src/translations';
import { formatCountOrNull, getAttendanceChurn } from '@src/utils/calculateSnapshots';

import { SnapshotDefinition } from '../types';

export const useAttenderTileDefinitions = (): SnapshotDefinition<AttendanceDashboardTilesQuery>[] => {
  const { translate } = useTranslation();
  const { dashboardAttendanceViewBy } = useDashboardFilterMenu();
  return React.useMemo(
    () => [
      dashboardAttendanceViewBy === DashboardAttendanceViewBy.AttenderSnapshots
        ? {
            title: translate(`snapshots.${ViewByDomain.Attenders}.attendanceChurn`),
            tooltip: translate(`infoText.attendance.churn`),
            getValue: data =>
              getAttendanceChurn(
                data.organization?.AttendanceChurnLapsedLastData?.aggregations.count,
                data.organization?.AttendanceChurnRegularLastData?.aggregations.count
              ),
          }
        : {
            title: translate(`snapshots.${ViewByDomain.Attenders}.averageWeeklyAttendance`),
            tooltip: translate(`infoText.attendance.averageWeeklyAttendance`),
            getValue: data => formatCountOrNull(data.organizationAttendanceOverview?.highlights.averageAttendance),
          },
      {
        listType: AttenderListType.PotentialInactive,
        title: translate(`snapshots.${ViewByDomain.Attenders}.potentialInactive`),
        tooltip: translate(`infoText.attendance.peopleList.PotentialInactive.CommunityMember`),
        getValue: data => formatCountOrNull(data?.organization?.PotentialInactive?.aggregations?.count),
      },
    ],
    [dashboardAttendanceViewBy, translate]
  );
};
