import { Theme } from '@pushpay/theming';

export const getBaseHeaderStyles = (theme: Theme) => ({
  flexContainer: {
    display: 'flex',
  },
  h1: {
    fontSize: '32px',
    fontWeight: '400',
  },
  headerContainer: {
    display: 'flex',
  },
  leftAlign: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  margin: {
    margin: '0 0.3em',
  },
  outlineButton: {
    border: `1px solid ${theme.colors['teal-500']}`,
    borderRadius: '20px',
    color: theme.colors['teal-500'],
    marginLeft: '16px',
    padding: '8px 24px',
  },
  rightAlign: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.SPACING.XSMALL,
    paddingTop: theme.SPACING.SMALL,
    [theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
      paddingTop: theme.SPACING.MEDIUM,
    },
  },
  thinline: {
    border: '0',
    borderTop: `1px solid ${theme.colors['grey-300']}`,
    height: '1px',
    margin: '20px 0 16px',
  },
});
