import { DateFormats, formatDate } from '@churchcommunitybuilder/js-utils';

import { DateRangeInput, GranularTimeRangeInput, PaymentQueryGranularity, StartOfWeek } from '@src/graphql/generated';

// Donor Dates - used with Community queries
export const getDonorCurrentTimeRange = (dateRange: DateRangeInput): GranularTimeRangeInput => ({
  fromDate: formatDate(dateRange.startDate, DateFormats.YYYY_MM_DD),
  toDate: formatDate(dateRange.endDate, DateFormats.YYYY_MM_DD),
  startDay: StartOfWeek.Monday,
  granularity: PaymentQueryGranularity.Week,
  timeZoneId: dateRange.timeZoneId,
});
