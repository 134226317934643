import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { AttenderListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useHouseholdAttendanceListTotalsLazyQuery, useHouseholdCountLazyQuery } from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { usePeopleList } from '@src/pages/PeopleList/components/PeopleListProvider';
import { convertClFilterConfigsToFilterMenuFilters } from '@src/pages/PeopleList/components/PeopleListProvider/utils';

type AttenderPeopleListTotals = Record<AttenderListType, number>;
export type AttenderPeopleListTotalsQueryResult = {
  error: ApolloError[];
  loading: boolean;
  totals: AttenderPeopleListTotals;
};

const emptyTotals: AttenderPeopleListTotals = {
  All: 0,
  FirstTime: 0,
  SecondTime: 0,
  RecentNew: 0,
  Occasional: 0,
  Curious: 0,
  Regular: 0,
  AtRisk: 0,
  Lapsed: 0,
  Returning: 0,
  Potential: 0,
  NonAttenders: 0,
  PotentialInactive: 0,
};

const isApolloError = (item: ApolloError | undefined | null): item is ApolloError => !!item;

export const useHouseholdAttenderPeopleListTotalsLazyQuery = (): [
  () => Promise<AttenderPeopleListTotalsQueryResult>,
  AttenderPeopleListTotalsQueryResult
] => {
  const [error, setError] = React.useState([] as ApolloError[]);
  const [loading, setLoading] = React.useState(false);
  const [totals, setTotals] = React.useState<AttenderPeopleListTotals>(emptyTotals);
  const getPeopleListFilters = usePeopleListFilters();
  const { clFilterConfigs } = usePeopleList();
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);

  const { key } = useMyOrganization();

  const { filters: allAttendanceFilters } = getPeopleListFilters(
    ViewByDomain.Attenders,
    AttenderListType.All,
    filterMenuFilters
  );
  const [getAttendanceListTotals] = useHouseholdAttendanceListTotalsLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: allAttendanceFilters,
      },
    },
  });

  const { filters: nonAttenderFilters } = getPeopleListFilters(
    ViewByDomain.Attenders,
    AttenderListType.NonAttenders,
    filterMenuFilters
  );
  const [getNonAttenderCount] = useHouseholdCountLazyQuery({
    variables: {
      organizationKey: key,
      campusKeys: filterMenuFilters.campusKeys,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: nonAttenderFilters,
      },
    },
  });

  const getTotals = React.useCallback(async () => {
    setLoading(true);
    const { error: nonAttenderError, data: nonAttenderData } = await getNonAttenderCount();
    const { error: listTotalsError, data: listTotalsData } = await getAttendanceListTotals();
    const newError = [nonAttenderError, listTotalsError].filter(isApolloError);
    setError(newError);

    const nonAttenderCount = nonAttenderData?.organization?.households?.paging.totalItemCount;
    const aggregations = listTotalsData?.organization?.households?.aggregations;
    let newTotals = emptyTotals;
    if (nonAttenderCount) {
      newTotals = {
        ...newTotals,
        NonAttenders: nonAttenderCount,
      };
    }

    if (aggregations) {
      newTotals = {
        ...newTotals,
        FirstTime: aggregations.firstTimeAttenders ?? 0,
        SecondTime: aggregations.secondTimeAttenders ?? 0,
        RecentNew: aggregations.recentNewAttenders ?? 0,
        Occasional: aggregations.occasionalAttenders ?? 0,
        Curious: aggregations.curiousAttenders ?? 0,
        Regular: aggregations.regularAttenders ?? 0,
        AtRisk: aggregations.atRiskAttenders ?? 0,
        Lapsed: aggregations.lapsedAttenders ?? 0,
        Returning: aggregations.returningAttenders ?? 0,
        Potential: (aggregations.curiousAttenders ?? 0) + (aggregations.returningAttenders ?? 0),
        PotentialInactive: aggregations.potentialInactive ?? 0,
      };
    }

    newTotals.All =
      newTotals.FirstTime +
      newTotals.SecondTime +
      newTotals.Regular +
      newTotals.Lapsed +
      newTotals.Occasional +
      newTotals.NonAttenders;

    setTotals(newTotals);
    setLoading(false);

    return { loading: false, error: newError, totals: newTotals };
  }, [getAttendanceListTotals, getNonAttenderCount]);

  return [
    getTotals,
    {
      error,
      loading,
      totals,
    },
  ];
};
