import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { AttenderListType, useServingDashboardCardQuery } from '@src/graphql/generated';
import { useBuildGaugeData, usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';

import { useVolunteerSnapshotDefinitions } from './useVolunteerSnapshotDefinitions';
import { useLastAndPreviousDates } from '../useLastAndPreviousDates';
import { buildSnapshotData, getServingCount } from '../utils';

export const useServingDashboardCard = () => {
  const { key } = useMyOrganization();
  const buildGaugeData = useBuildGaugeData();

  const getPeopleListFilters = usePeopleListFilters();
  const { filters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);
  const volunteerSnapshotDefinitions = useVolunteerSnapshotDefinitions();

  const { previousWeekComparedToTodayDateRange } = useLastAndPreviousDates();

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const { data, error, loading } = useServingDashboardCardQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: 0,
      },
      lastQueryParams: {
        filters,
      },
      previousQueryParams: {
        endDate: previousWeekComparedToTodayDateRange.endDate,
        filters,
        startDate: previousWeekComparedToTodayDateRange.startDate,
      },
    },
  });

  const allEligibleIndividuals = data?.AllInsightEligibleIndividuals?.communityMembersCursor?.aggregations.All ?? null;
  const servingCount = getServingCount(data);

  return {
    error,
    loading,
    data: {
      gauge: buildGaugeData(ViewByDomain.Volunteers, allEligibleIndividuals, servingCount),
      snapshots: buildSnapshotData(ViewByDomain.Volunteers, volunteerSnapshotDefinitions, loading, data),
    },
  };
};
