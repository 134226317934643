import { AttendanceType, Granularity, OrganizationAttendanceOverview } from '@src/graphql/generated';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';
import useTranslation from '@src/translations';
import { Highlight } from '@src/types/ChartAndHighlights';
import { formatCount } from '@src/utils/formatter';

export function useAttendanceHighlights(
  highlightsFromOverview: OrganizationAttendanceOverview['highlights'] | undefined,
  granularity: Granularity,
  attendanceTypes?: AttendanceType[] | null
): Highlight[] {
  const { translate } = useTranslation();
  const { resiStreamingAttendanceEnabled } = useDataCustomization();

  const attendanceTypeFilterIsEmpty = !attendanceTypes || attendanceTypes.length === 0;
  const includeHeadcountHighlights = attendanceTypeFilterIsEmpty || attendanceTypes.includes(AttendanceType.Headcount);
  const includeIndividualHighlights =
    attendanceTypeFilterIsEmpty || attendanceTypes.includes(AttendanceType.Individual);
  const includeStreamingHighlights =
    resiStreamingAttendanceEnabled &&
    (attendanceTypeFilterIsEmpty || attendanceTypes.includes(AttendanceType.Streaming));
  const highlights: Highlight[] = [];

  if (includeHeadcountHighlights) {
    highlights.push({
      title: translate(`widget.highlights.AttendanceOverview.headCountAttendance`),
      tooltip: translate(`infoText.attendance.headcount`),
      value:
        typeof highlightsFromOverview?.headCountAttendance === 'number'
          ? formatCount(highlightsFromOverview.headCountAttendance)
          : null,
    });
  }

  if (includeIndividualHighlights) {
    highlights.push({
      title: translate(`widget.highlights.AttendanceOverview.individualAttendance`),
      tooltip: translate(`infoText.attendance.individual`),
      value:
        typeof highlightsFromOverview?.individualAttendance === 'number'
          ? formatCount(highlightsFromOverview?.individualAttendance)
          : null,
    });
  }

  if (includeStreamingHighlights) {
    highlights.push({
      title: translate(`widget.highlights.AttendanceOverview.streamingAttendance`),
      tooltip: translate(`infoText.attendance.streaming`),
      value:
        typeof highlightsFromOverview?.streamingAttendance === 'number'
          ? formatCount(highlightsFromOverview.streamingAttendance)
          : null,
    });
  }

  if (includeIndividualHighlights) {
    highlights.push(
      {
        title: translate(`widget.highlights.AttendanceOverview.uniqueIndividualAttendance`),
        tooltip: translate(`infoText.attendance.individualUnique`),
        value:
          typeof highlightsFromOverview?.uniqueIndividualAttendance === 'number'
            ? formatCount(highlightsFromOverview.uniqueIndividualAttendance)
            : null,
      },
      {
        title: translate(`widget.highlights.AttendanceOverview.uniqueFamilyAttendance`),
        tooltip: translate(`infoText.attendance.householdUnique`),
        value:
          typeof highlightsFromOverview?.uniqueFamilyAttendance === 'number'
            ? formatCount(highlightsFromOverview?.uniqueFamilyAttendance)
            : null,
      }
    );
  }
  highlights.push({
    title: translate(`widget.highlights.AttendanceOverview.averageAttendance.${granularity}`),
    tooltip: translate(`infoText.attendance.averages.${granularity}`),
    value:
      typeof highlightsFromOverview?.averageAttendance === 'number'
        ? formatCount(highlightsFromOverview?.averageAttendance)
        : null,
  });

  return highlights;
}
