import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
  column: { margin: theme.SPACING.NONE },
  columns: { margin: theme.SPACING.NONE },
  mobileAndTabletColumns: {
    margin: theme.SPACING.NONE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  header: {
    alignSelf: 'center',
    backgroundColor: theme.colors['common-transparent'],
    border: theme.SPACING.NONE,
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.SPACING.NONE,
    paddingLeft: theme.SPACING.NONE,
  },
  innerRightBase: {
    display: 'flex',
    paddingLeft: theme.SPACING.NONE,
  },
  desktopInnerRight: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    margin: theme.SPACING.NONE,
  },
  mobileAndTabletInnerRight: {
    flexDirection: 'column',
  },
}));
