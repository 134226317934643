import * as React from 'react';

import { ApolloError } from '@apollo/client';

import { useToggle } from '@pushpay/utils';

import { ExportJobViewBy } from '@src/graphql/generated';

import { useCommunityMemberDonorPeopleListTotalsLazyQuery } from './hooks/useCommunityMemberDonorPeopleListTotalsLazyQuery';
import { useHouseholdDonorPeopleListTotalsLazyQuery } from './hooks/useHouseholdDonorPeopleListTotalsLazyQuery';
import { DonorPeopleListTotals, DonorPeopleListTotalsQueryResult, emptyTotals } from './types';

export const useDonorPeopleListTotalsLazyQuery = (
  viewByMode: ExportJobViewBy
): [() => Promise<DonorPeopleListTotalsQueryResult>, DonorPeopleListTotalsQueryResult] => {
  const [error, setError] = React.useState([] as ApolloError[]);
  const { isOn: loading, toggle: setLoading } = useToggle();
  const [totals, setTotals] = React.useState<DonorPeopleListTotals>(emptyTotals);
  const [getCommunityMemberTotals] = useCommunityMemberDonorPeopleListTotalsLazyQuery();
  const [getHouseHoldMemberTotals] = useHouseholdDonorPeopleListTotalsLazyQuery();

  const getPeopleListTotals = React.useCallback(async () => {
    setLoading(true);
    let result: DonorPeopleListTotalsQueryResult;
    switch (viewByMode) {
      case ExportJobViewBy.CommunityMember:
        result = await getCommunityMemberTotals();
        break;
      case ExportJobViewBy.Household:
        result = await getHouseHoldMemberTotals();
        break;
      default:
        throw new Error('Unknown domain');
    }

    setError(result.error);
    setTotals(result.totals);
    setLoading(false);

    return { loading: false, error: result.error, totals: result.totals };

    // Do not add setLoading as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCommunityMemberTotals, getHouseHoldMemberTotals, viewByMode]);

  return [
    getPeopleListTotals,
    {
      error,
      loading,
      totals,
    },
  ];
};
