import { AttendanceFilters } from '@src/types/Filters';

export const emptyFilters: AttendanceFilters = {
  attendanceGroupingNames: [],
  attendanceType: [],
  campusNames: [],
  departmentNames: [],
  eventNames: [],
  groupNames: [],
  groupTypeNames: [],
  membershipTypeIds: undefined,
  eventDays: [],
  eventStartTimes: undefined,
  streamingEventNames: [],
};
