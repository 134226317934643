import * as React from 'react';

import { ProcessQueue } from '@src/graphql/generated';
import { useChmsProcesses, useChmsQueues } from '@src/hooks';
import { ProcessWithoutQueues } from '@src/types/Processes';

interface SendToProcessQueueContextValue {
  processes?: ProcessWithoutQueues[];
  selectedProcessId?: number;
  setSelectedProcessId: (processId?: number) => void;
  queues?: ProcessQueue[];
  selectedQueueId?: number;
  setSelectedQueueId: (queueId?: number) => void;
  isSuccess?: boolean;
  setIsSuccess: (success: boolean | undefined) => void;
}

type SendToProcessQueueProviderProps = React.PropsWithChildren;

const SendToProcessQueueContext = React.createContext<SendToProcessQueueContextValue | undefined>(undefined);

export const SendToProcessQueueProvider = ({ children }: SendToProcessQueueProviderProps) => {
  const getProcesses = useChmsProcesses();
  const getQueues = useChmsQueues();
  const [processes, setProcesses] = React.useState<ProcessWithoutQueues[] | undefined>(undefined);
  const [selectedProcessId, setSelectedProcessIdState] = React.useState<number | undefined>();
  const [queues, setQueues] = React.useState<ProcessQueue[] | undefined>(undefined);
  const [selectedQueueId, setSelectedQueueId] = React.useState<number | undefined>();
  const [isSuccess, setIsSuccess] = React.useState<boolean | undefined>();

  React.useEffect(() => {
    async function fetchProcesses() {
      setProcesses(await getProcesses());
    }
    fetchProcesses();
  }, [getProcesses]);

  const setSelectedProcessId = React.useCallback(
    async (processId?: number) => {
      if (processId !== selectedProcessId) {
        setQueues(undefined);
        setSelectedQueueId(undefined);
      }
      setSelectedProcessIdState(processId);
      const newQueues = await getQueues(processId);
      setQueues(newQueues);
    },
    [getQueues, selectedProcessId]
  );

  const value = {
    processes,
    selectedProcessId,
    setSelectedProcessId,
    queues,
    selectedQueueId,
    setSelectedQueueId,
    isSuccess,
    setIsSuccess,
  } as SendToProcessQueueContextValue;
  return <SendToProcessQueueContext.Provider value={value}>{children}</SendToProcessQueueContext.Provider>;
};

export const useSendToProcessQueueContext = () => {
  const context = React.useContext(SendToProcessQueueContext);

  if (context === undefined) {
    throw new Error('useSendToProcessQueueContext must be used within a SendToProcessQueueProvider');
  }

  return context;
};
