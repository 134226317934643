import { t } from '@pushpay/i18n';

import { formatGivingRatio } from '@src/components/SnapshotItem/utils';
import { getTotalsForPeriodData, PeriodSummaryForTotals } from '@src/Giving/GivingOverview/utils';

import { formatCount, formatPercentage, formatRatio } from './formatter';
import { getAverageDonation } from './math';

export const formatCountOrNull = (value: number | null | undefined) => {
  if (typeof value === 'number') {
    return formatCount(value);
  }

  return null;
};

export const getPotentialAttenders = (curiousAttenders?: number | null, returningAttenders?: number | null) => {
  if (typeof curiousAttenders === 'number' && typeof returningAttenders === 'number') {
    return formatCount(curiousAttenders + returningAttenders);
  }

  return null;
};

export const getAttendanceChurn = (lapsedAttenders?: number | null, regularAttenders?: number | null) => {
  /*
   * NOTE: The lapsed attenders count currently may contain duplicates and/or may contain people who are no longer lapsed.
   * Will need work from diamond mint to get a unique count of people who are current lapsed and lapsed within last 13 weeks.
   */

  if (typeof lapsedAttenders === 'number' && typeof regularAttenders === 'number') {
    if (!lapsedAttenders && !regularAttenders) {
      return formatPercentage(0);
    }
    return formatPercentage(lapsedAttenders / (regularAttenders + lapsedAttenders));
  }

  return null;
};

const isNullOrUndefined = (value: any) => value === null || value === undefined;

export const getAverageDonationSnapshotValue = (
  totalAttendance?: number | null,
  periods?: PeriodSummaryForTotals[]
) => {
  if (isNullOrUndefined(totalAttendance) || !periods) {
    return null;
  }

  const { totalGiving } = getTotalsForPeriodData(periods);

  if (!totalAttendance || !totalGiving) {
    return t('noData');
  }

  const averageDonation = getAverageDonation(totalGiving, totalAttendance);
  if (!averageDonation) {
    return t('noData');
  }

  return averageDonation;
};

export const getOnlineOfflineGiving = (online?: number | null, offline?: number | null) => {
  if (typeof online === 'number' && typeof offline === 'number') {
    return formatGivingRatio([online, offline]);
  }

  return null;
};

export const getVolunteerOpportunities = (
  availableVolunteers?: number | null,
  servingOpportunities?: number | null
) => {
  if (typeof availableVolunteers === 'number' && typeof servingOpportunities === 'number') {
    return formatRatio([availableVolunteers, servingOpportunities]);
  }

  return null;
};
