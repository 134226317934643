import { ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { t } from '@pushpay/i18n';

import { AttendanceType, useAttendanceQueryFiltersQuery } from '@src/graphql/generated';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

type UseAttendanceFilterOptionsReturn = {
  loading: boolean;
  error?: ApolloError;
  data?: Record<string, Map<string, string>>;
};

export enum WeekDay {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

const eventDaysOptions = {
  [WeekDay.Sunday]: 'filters.staticFilters.eventDays.options.sunday',
  [WeekDay.Monday]: 'filters.staticFilters.eventDays.options.monday',
  [WeekDay.Tuesday]: 'filters.staticFilters.eventDays.options.tuesday',
  [WeekDay.Wednesday]: 'filters.staticFilters.eventDays.options.wednesday',
  [WeekDay.Thursday]: 'filters.staticFilters.eventDays.options.thursday',
  [WeekDay.Friday]: 'filters.staticFilters.eventDays.options.friday',
  [WeekDay.Saturday]: 'filters.staticFilters.eventDays.options.saturday',
};

const attendanceTypeOptions = {
  [AttendanceType.Headcount]: 'filters.staticFilters.attendanceType.options.headcount',
  [AttendanceType.Individual]: 'filters.staticFilters.attendanceType.options.individual',
  [AttendanceType.Streaming]: 'filters.staticFilters.attendanceType.options.streaming',
};

export const getTranslatedStaticOptions = (resiStreamingAttendanceEnabled: boolean) => ({
  attendanceType: new Map(
    Object.values(AttendanceType)
      .filter(option => resiStreamingAttendanceEnabled || option !== AttendanceType.Streaming)
      .map(option => [option, t(attendanceTypeOptions[option]) as string])
  ),
  eventDays: new Map(Object.values(WeekDay).map(option => [option, t(eventDaysOptions[option]) as string])),
});

export function useAttendanceFilterOptions(): UseAttendanceFilterOptionsReturn {
  const { organizationKey } = useParams<'organizationKey'>();
  const { resiStreamingAttendanceEnabled } = useDataCustomization();

  if (!organizationKey) {
    throw new Error('No organization key provided.');
  }

  const { loading, error, data } = useAttendanceQueryFiltersQuery({
    variables: {
      organizationKey,
    },
  });

  if (data?.attendanceQueryFilters) {
    const { __typename, ...attendanceQueryFilters } = data.attendanceQueryFilters;

    const customizableOptions = Object.entries(attendanceQueryFilters).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: new Map(value.map(option => [option, option])) }),
      {}
    );

    return {
      loading,
      error,
      data: {
        ...customizableOptions,
        ...getTranslatedStaticOptions(resiStreamingAttendanceEnabled),
      },
    };
  }

  return {
    loading,
    error,
    data: undefined,
  };
}
