import { isDonor, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useAttendanceQueryFiltersWithGlobalSettingsApplied } from '@src/Attendance/hooks';
import { useFeature } from '@src/featureFlags';
import { getMembershipTypeFilterForPQS } from '@src/Giving/utils/filters';
import {
  AttenderListType,
  CcbMembershipTypeFilterInput,
  Granularity,
  useGivingDashboardTilesQuery,
} from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

import { getDonorCurrentTimeRange } from './dateUtils';
import { useDonorTileDefinitions } from './useDonorTileDefinitions';
import { useHasChmsAccess } from '../../../hooks/permissions/useHasChmsAccess';
import { useLastAndPreviousDates } from '../useLastAndPreviousDates';
import { buildSnapshotData } from '../utils';

export const useGivingTiles = () => {
  const { key } = useMyOrganization();
  const isDisablePQSEnabled = useFeature('DisablePQS');
  const donorTileDefinitions = useDonorTileDefinitions();
  const hasChmsAccess = useHasChmsAccess();

  const { selectedMembershipTypes } = useDataCustomization();
  const ccbMembershipTypeFiltersForPQS: CcbMembershipTypeFilterInput =
    getMembershipTypeFilterForPQS(selectedMembershipTypes);

  const getPeopleListFilters = usePeopleListFilters();
  const { filters: unarchivedIndividualsFilters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);
  const { lastCompletedWeekDateRange } = useLastAndPreviousDates();
  const donorCurrentTimeRange = getDonorCurrentTimeRange(lastCompletedWeekDateRange);
  const attendanceQueryFilters = useAttendanceQueryFiltersWithGlobalSettingsApplied();

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const { data, error, loading } = useGivingDashboardTilesQuery({
    variables: {
      organizationKey: key,
      isDisablePQSEnabled,
      paging: {
        size: 0,
      },
      attendanceOverviewInput: {
        attendanceQueryFilters,
        dateRange: lastCompletedWeekDateRange,
        granularity: Granularity.Weekly,
      },
      donorCurrentTimeRange: {
        ...donorCurrentTimeRange,
      },
      donorOrganizationQueryFilter: {
        ccbMembershipTypeFilter: ccbMembershipTypeFiltersForPQS,
      },
      donorLastQueryParams: {
        filters: [...unarchivedIndividualsFilters, isDonor],
      },
      disableChms: !hasChmsAccess,
    },
  });

  return {
    error,
    loading,
    data: buildSnapshotData(ViewByDomain.Donors, donorTileDefinitions, loading, data),
  };
};
