import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { AxisProps } from '@pushpay/charts';

import { ChartData, Colors, DataDisplayType } from '@src/types/ChartAndHighlights';
import type { AxisLegendPosition, ChartLegendProps, GroupMode } from '@src/types/ChartLayout';
import { DateConfig } from '@src/types/DateConfig';

export enum ChartKey {
  Last = 'last',
  Previous = 'previous',
}

export interface TooltipDataPoint {
  label: string;
  value: number;
  date: Date;
}

export interface TooltipData {
  last?: TooltipDataPoint;
  previous?: TooltipDataPoint;
}

export interface BarChartPoint {
  label: string;
  last: number;
  previous?: number;
  tooltip: TooltipData;
  date: Date;
}

export interface LineChartPoint {
  x: number | string;
  y: number | string;
  date: Date;
}

export interface LineChartLineData {
  id: string;
  data: LineChartPoint[];
}

export type ChartLayout = {
  axisLeft: AxisProps;
  axisBottom: AxisProps;
  enableLabel: boolean;
  groupMode: GroupMode.Grouped;
  height: number;
  indexBy: string;
  legendPosition: AxisLegendPosition;
  legends: ChartLegendProps[];
  lineChartPointSize: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  padding: number;
};

export interface IChartLegendProps {
  includePrevious: boolean;
}

export type RuleNames = 'dateRangeText' | 'legendPadding' | 'legendKeyLast' | 'legendKeyPrevious' | 'legendText';

export interface LegendProps {
  colors?: Colors;
}

export type BuildChartProps = {
  layout: ChartLayout;
  markerIndex: number;
  updateMarkerIndex: (newIndex: number) => void;
  lastChartData: ChartData;
  previousChartData?: ChartData;
  viewByDomain: ViewByDomain;
  dateConfig: DateConfig;
  dataDisplayType: DataDisplayType;
};
