import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { CursorPagingInput, CursorPagingOutput, Filter, ExportJobViewBy } from '@src/graphql/generated';
import { initializedPagingOutput } from '@src/pages/PeopleList/components/PeopleListDesktopView/constants';
import { SortingState } from '@src/pages/PeopleList/components/PeopleListProvider/types';
import { PeopleListTableRowsResult, TableRow } from '@src/types/PeopleList';

import { useCommunityMemberTableRowsLazyQuery } from './hooks/useCommunityMemberTableRowsLazyQuery';
import { useHouseholdTableRowsLazyQuery } from './hooks/useHouseholdTableRowsLazyQuery';

export type PeopleListSearchParams = {
  endDate?: Date;
  filters: Filter[];
  searchTerm: string;
  sort?: SortingState;
  startDate?: Date;
  campusKeys?: string[];
};

export function usePeopleListTableRowsLazyQuery(): [
  (
    viewByMode: ExportJobViewBy,
    viewByDomain: ViewByDomain,
    listType: ListType,
    pagingInput: CursorPagingInput,
    params: PeopleListSearchParams
  ) => Promise<PeopleListTableRowsResult>,
  PeopleListTableRowsResult
] {
  const [error, setError] = React.useState<ApolloError | Error>();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableRow[]>([]);
  const [paging, setPaging] = React.useState<CursorPagingOutput>(initializedPagingOutput);

  const [getCommunityMemberTableRows] = useCommunityMemberTableRowsLazyQuery();
  const [getHouseholdTableRows] = useHouseholdTableRowsLazyQuery();

  const getTableRows = React.useCallback(
    async (
      viewByMode: ExportJobViewBy,
      viewByDomain: ViewByDomain,
      listType: ListType,
      pagingInput: CursorPagingInput,
      params: PeopleListSearchParams
    ) => {
      setLoading(true);
      let result: PeopleListTableRowsResult;
      switch (viewByMode) {
        case ExportJobViewBy.CommunityMember:
          result = await getCommunityMemberTableRows(pagingInput, params, viewByDomain, listType);
          break;
        case ExportJobViewBy.Household:
          result = await getHouseholdTableRows(pagingInput, params, viewByDomain, listType);
          break;
        default:
          throw new Error('Unknown View By Mode');
      }

      setError(result.error);
      setRows(result.rows);
      setPaging(result.paging);
      setLoading(false);

      return { loading: false, error: result.error, rows: result.rows, paging: result.paging };
    },
    [getCommunityMemberTableRows, getHouseholdTableRows]
  );

  return [
    getTableRows,
    {
      loading,
      error,
      rows,
      paging,
    },
  ];
}
