import { AttendanceQueryFilters, AttendanceType } from '@src/graphql/generated';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

import { mergeGlobalAndAttendanceMembershipTypeFilters } from './utils';

export function useAttendanceQueryFiltersWithGlobalSettingsApplied(
  userFilters?: AttendanceQueryFilters
): AttendanceQueryFilters {
  const { selectedMembershipTypes, resiStreamingAttendanceEnabled } = useDataCustomization();

  const mergedFilters = mergeGlobalAndAttendanceMembershipTypeFilters(selectedMembershipTypes, userFilters);

  const defaultAttendanceType: AttendanceType[] = [AttendanceType.Headcount, AttendanceType.Individual];
  if (resiStreamingAttendanceEnabled) {
    defaultAttendanceType.push(AttendanceType.Streaming);
  }

  if (!mergedFilters.attendanceType || mergedFilters.attendanceType.length === 0) {
    return { ...mergedFilters, attendanceType: defaultAttendanceType };
  }

  return mergedFilters;
}
