import { AttenderListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { useAttendanceStagesQuery, usePeopleCountQuery } from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';

export function useAttendanceStages() {
  const getPeopleListFilters = usePeopleListFilters();
  const { key } = useMyOrganization();
  const { filters: allAttendersFilters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);

  const {
    loading: stagesLoading,
    error: stagesError,
    data: stagesData,
  } = useAttendanceStagesQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: allAttendersFilters,
      },
    },
  });

  const { filters: nonAttendersFilters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.NonAttenders);

  const {
    loading: peopleLoading,
    error: peopleError,
    data: peopleData,
  } = usePeopleCountQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: 0,
      },
      queryParams: {
        filters: nonAttendersFilters,
      },
    },
  });

  const error = stagesError || peopleError;
  const loading = stagesLoading || peopleLoading;

  const { firstTimeAttenders, lapsedAttenders, occasionalAttenders, regularAttenders, secondTimeAttenders } =
    stagesData?.organization?.communityMembersCursor?.aggregations || {};

  const { totalItemCount: nonAttendersCount } = peopleData?.organization?.communityMembersCursor?.paging || {};

  const values = {
    firstTimeAttenders: firstTimeAttenders ?? 0,
    lapsedAttenders: lapsedAttenders ?? 0,
    nonAttenders: nonAttendersCount ?? 0,
    occasionalAttenders: occasionalAttenders ?? 0,
    regularAttenders: regularAttenders ?? 0,
    secondTimeAttenders: secondTimeAttenders ?? 0,
  };

  return {
    loading,
    error,
    values,
  };
}
