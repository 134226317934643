import { useMyOrganization } from '@src/myContext';
import { useDataCustomization } from '@src/pages/DataCustomization/components/DataCustomizationProvider';

import { PeopleListFilterMenuFilters } from '../types';
import { getAttendanceStatuses, getDonorStatuses, getServingStatuses } from '../utils';

export function usePeopleListFilterMenuFilterOptions(): PeopleListFilterMenuFilters {
  const { globallyFilteredMembershipTypeOptions } = useDataCustomization();
  const organization = useMyOrganization();
  const campuses = organization.details?.campuses;
  const filters: PeopleListFilterMenuFilters = {
    membershipTypeFilters: globallyFilteredMembershipTypeOptions,
    attendanceStatusFilters: getAttendanceStatuses(),
    donorStatusFilters: getDonorStatuses(),
    servingStatusFilters: getServingStatuses(),
  };

  if (campuses && campuses.length > 1) {
    filters.campusFilters = new Map(campuses.map(campus => [campus.key, campus.name]));
  }

  return filters;
}
