import { useMembershipTypesQuery } from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';

import { useHasChmsAccess } from './permissions/useHasChmsAccess';

export const useChmsMembershipTypes = () => {
  const { key } = useMyOrganization();
  const hasChms = useHasChmsAccess();

  const { data } = useMembershipTypesQuery({
    variables: {
      organizationKey: key,
      hasChms,
    },
  });

  return data?.organization?.membershipTypes || [];
};
