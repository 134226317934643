import * as React from 'react';

import * as ReactRouterDOM from 'react-router-dom';

import { Breadcrumb } from '@pushpay/breadcrumb';
import { Heading } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import Header from '@src/components/Header';
import { CUSTOM_DESKTOP_MIN_WIDTH } from '@src/constants/customBreakpoints';
import { useCustomBreakpoint } from '@src/hooks';
import { getPath } from '@src/router';
import useTranslation from '@src/translations';
import { Destination } from '@src/types/Routing';

import { useStyles } from './PageHeaderStyles';
import DataConfidence from '../DataConfidence';
import { DataConfidenceProps } from '../DataConfidence/types';

type PageHeaderProps = {
  innerRightContent?: string | React.ReactNode;
  outerRightContent?: string | React.ReactNode;
  pageName?: string;
  returnTo?: string;
  topRightContent?: string | React.ReactNode;
  title: string;
  dataConfidenceProps?: DataConfidenceProps;
};

type PageHeaderComponentProps = ComponentProps<PageHeaderProps, typeof useStyles>;

export const PageHeader = ({
  classes: classesProp,
  innerRightContent,
  outerRightContent = undefined,
  pageName = '',
  returnTo = undefined,
  topRightContent = undefined,
  title,
  dataConfidenceProps,
}: PageHeaderComponentProps) => {
  const { organizationKey } = ReactRouterDOM.useParams<{
    organizationKey: string;
  }>();

  const { translate } = useTranslation();
  const classes = useStyles(classesProp);
  const isDesktop = useCustomBreakpoint(CUSTOM_DESKTOP_MIN_WIDTH);
  const breadCrumbContainerClasses = clsx(classes.breadcrumbContainer, {
    [classes.breadcrumbContainerDesktop]: isDesktop,
  });

  const crumbList = React.useMemo(
    () => [
      {
        classes: { text: classes.itemLink },
        name: translate('dashboard'),
        to: getPath(Destination.Dashboard, organizationKey),
      },
      {
        name: pageName,
        to: '',
      },
    ],
    [classes.itemLink, organizationKey, pageName, translate]
  );

  const containerClasses = clsx(classes.headerContainer, classes.pageHeaderContainer);
  const rightContentClasses = clsx(classes.rightAlign, classes.rightContent);
  const titleClass = clsx(classes.flexContainer, classes.title);

  const breadcrumbs = React.useMemo(
    () => (
      <Breadcrumb
        breadcrumbItems={crumbList}
        classes={{ root: classes.breadcrumbRoot, breadcrumb: classes.breadcrumb, itemLink: classes.itemLink }}
        data-pp-at-target="insights-breadcrumbs"
      />
    ),
    [classes.breadcrumbRoot, classes.breadcrumb, classes.itemLink, crumbList]
  );

  const titleArea = React.useMemo(
    () => (
      <div className={titleClass}>
        <Heading className={classes.titleRoot} data-pp-at-target="pageTitle" level="1">
          {title}
          {dataConfidenceProps && <DataConfidence {...dataConfidenceProps} />}
        </Heading>
      </div>
    ),
    [classes.titleRoot, dataConfidenceProps, title, titleClass]
  );

  const rightContent = React.useMemo(
    () => <div className={rightContentClasses}>{innerRightContent}</div>,
    [innerRightContent, rightContentClasses]
  );

  return (
    <div className={containerClasses}>
      <div className={breadCrumbContainerClasses}>
        {returnTo && <Header classes={{ columns: classes.columns }} leftContent={breadcrumbs} />}
        {topRightContent && <div className={classes.topRightContent}>{topRightContent}</div>}
      </div>
      <div className={classes.titleContainer}>
        <Header leftContent={titleArea} rightContent={rightContent} />
        {outerRightContent && <div className={classes.outerRightContent}>{outerRightContent}</div>}
      </div>
    </div>
  );
};
