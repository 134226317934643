import {
  AttenderListType,
  DonorListType,
  ExtraPeopleListFilters,
  ListType,
  MembershipTypeIdFilter,
  ViewByDomain,
  VolunteerListType,
} from '@churchcommunitybuilder/insights-people-list-filtering';

import { FilterConfig, FiltersValue } from '@pushpay/filters';
import { t } from '@pushpay/i18n';

import { isToggleTypeValues, noMembershipTypeId } from '@src/utils/filters';

import { PeopleListFilters } from './types';
import { emptyFilterMenuFilters } from '../../consts';

export const defaultCLFiltersValue = {
  attendanceStatus: { values: undefined },
  campusKeys: { values: undefined },
  donorStatus: { values: undefined },
  membershipTypeIds: { values: undefined },
  servingStatus: { values: undefined },
};

export const domainListContainsListType = (viewByDomain: ViewByDomain, selectedListType: ListType) => {
  switch (viewByDomain) {
    case ViewByDomain.Attenders:
      return Object.values(AttenderListType).find(type => type === selectedListType);
    case ViewByDomain.Donors:
      return Object.values(DonorListType).find(type => type === selectedListType);
    case ViewByDomain.Volunteers:
      return Object.values(VolunteerListType).find(type => type === selectedListType);
    default:
      return false;
  }
};

const setDisabledToggleFilter = <T>(filter: FilterConfig<T>, disabled: boolean) => {
  const enabledToggleFilter = {
    ...filter,
    disabled: false,
    tooltip: undefined,
  };

  const disabledToggleFilter = {
    ...filter,
    filterState: { values: undefined },
    disabled: true,
    tooltip: { content: t(`filters.tooltips.disabled.${filter.field}`), placement: 'top' },
  };

  return disabled ? disabledToggleFilter : enabledToggleFilter;
};

export const setDisabledCLFilter = <T>(
  field: string,
  filters: FilterConfig<T>[],
  disabled: boolean
): FilterConfig<T>[] =>
  filters.map(filter => {
    if (filter.field === field) {
      if (filter.type === 'toggle') {
        return setDisabledToggleFilter(filter, disabled);
      }
      throw new Error('Unsupported filter type');
    }
    return filter;
  }) as FilterConfig<T>[];

export const convertCLFilterConfigsToCLFilterValues = <T>(cLFiltersConfig: FilterConfig<T>[]): FiltersValue<T> => {
  const cLFiltersValue = cLFiltersConfig.reduce(
    (acc, item) => ({ ...acc, [item.field]: item.filterState }),
    {} as FiltersValue<T>
  );

  return cLFiltersValue;
};

export const convertCLFiltersValueToFilterMenuFilters = (
  filters: FiltersValue<PeopleListFilters>
): PeopleListFilters => {
  const filterValues = Object.entries(filters);

  return filterValues.reduce(
    (acc, [key, value]) => {
      if (key === 'membershipTypeIds' && isToggleTypeValues(value)) {
        return {
          ...acc,
          membershipTypeIds: {
            values: value.values
              ?.filter(membershipTypeId => membershipTypeId !== noMembershipTypeId)
              .map(membershipTypeId => parseInt(membershipTypeId, 10)),
            isEmpty: !!value.values?.includes(noMembershipTypeId),
          } as MembershipTypeIdFilter,
        };
      }
      if (key === 'campusKeys' && isToggleTypeValues(value)) {
        return {
          ...acc,
          campusKeys: value.values,
        };
      }
      if (key === 'attendanceStatus' && isToggleTypeValues(value)) {
        return {
          ...acc,
          attendanceStatus: value.values as ExtraPeopleListFilters['attendanceStatus'],
        };
      }
      if (key === 'donorStatus' && isToggleTypeValues(value)) {
        return {
          ...acc,
          donorStatus: value.values as ExtraPeopleListFilters['donorStatus'],
        };
      }

      if (key === 'servingStatus' && isToggleTypeValues(value)) {
        return {
          ...acc,
          servingStatus: value.values as ExtraPeopleListFilters['servingStatus'],
        };
      }
      return acc;
    },
    { ...emptyFilterMenuFilters }
  );
};

export const convertClFilterConfigsToFilterMenuFilters = (
  filterConfigs: FilterConfig<PeopleListFilters>[]
): PeopleListFilters => convertCLFiltersValueToFilterMenuFilters(convertCLFilterConfigsToCLFilterValues(filterConfigs));

export const getAttendanceStatuses = () => {
  const statuses = [
    AttenderListType.FirstTime,
    AttenderListType.SecondTime,
    AttenderListType.Occasional,
    AttenderListType.Regular,
    AttenderListType.Lapsed,
  ];

  return new Map<string, string>(
    statuses.map(status => [status, t(`filters.staticFilters.attendanceStatus.options.${status}`)])
  );
};

export const getDonorStatuses = () => {
  const statuses = [
    DonorListType.FirstTime,
    DonorListType.SecondTime,
    DonorListType.Occasional,
    DonorListType.Regular,
    DonorListType.Recurring,
    DonorListType.Lapsed,
  ];

  return new Map<string, string>(
    statuses.map(status => [status, t(`filters.staticFilters.donorStatus.options.${status}`)])
  );
};

export const getServingStatuses = () => {
  const statuses = [
    VolunteerListType.FirstTime,
    VolunteerListType.SecondTime,
    VolunteerListType.Occasional,
    VolunteerListType.Regular,
    VolunteerListType.Lapsed,
  ];

  return new Map<string, string>(
    statuses.map(status => [status, t(`filters.staticFilters.servingStatus.options.${status}`)])
  );
};

const defaultTimeFilterState: { relationship: 'is'; times: string[] } = { relationship: 'is', times: [] };

export const getEmptyFilterValues = <T>(filters: FilterConfig<T>[]): FiltersValue<T> =>
  filters.reduce((acc, filter) => {
    if (filter.type === 'toggle' || filter.type === 'multiSelect') {
      return { ...acc, [filter.field]: { values: [] } };
    }

    if (filter.type === 'time') {
      return { ...acc, [filter.field]: defaultTimeFilterState };
    }

    // TODO: add additional logic for each of the filter values shapes

    throw new Error('Unsupported filter type');
  }, {} as FiltersValue<T>);
