import { DateComparisonType, getDateRangeFromDurationAndPeriod, RangeType } from '@churchcommunitybuilder/js-utils';

import { DateConfig } from '@src/types/DateConfig';
import { DateRangeOptionType, DateRangeSelectorConfig } from '@src/types/DateRangeSelector';

import { getDateRangeOptionFromSimpleDateConfig, getDurationPeriodAndCurrentFromDateRangeOption } from './getDateRange';

export const getDateRangeSelectorConfigFromDateConfig = (dateConfig: DateConfig): DateRangeSelectorConfig => {
  let dateRange: DateRangeOptionType;
  let startDate: Date;
  let endDate: Date;

  if (dateConfig.rangeType === RangeType.Custom) {
    dateRange = DateRangeOptionType.Custom;
    startDate = dateConfig.startDate;
    endDate = dateConfig.endDate;
  } else {
    const { duration, period, includeCurrent } = dateConfig;

    dateRange = getDateRangeOptionFromSimpleDateConfig(duration, period, includeCurrent);
    ({ startDate, endDate } = getDateRangeFromDurationAndPeriod(duration, period, includeCurrent));
  }

  return {
    comparison: dateConfig.comparison,
    dateRange,
    endDate,
    startDate,
  };
};

export const getDateConfigFromDateRangeSelectorConfig = (
  dateRangeSelectorConfig: DateRangeSelectorConfig
): DateConfig => {
  if (dateRangeSelectorConfig.dateRange === DateRangeOptionType.Custom) {
    return {
      comparison: DateComparisonType.None,
      endDate: new Date(dateRangeSelectorConfig?.endDate),
      rangeType: RangeType.Custom,
      startDate: new Date(dateRangeSelectorConfig?.startDate),
    } as const;
  }

  const { duration, period, includeCurrent } = getDurationPeriodAndCurrentFromDateRangeOption(
    dateRangeSelectorConfig.dateRange
  );

  return {
    comparison: dateRangeSelectorConfig?.comparison,
    duration,
    includeCurrent,
    period,
    rangeType: RangeType.Simple,
  } as const;
};
