import * as React from 'react';

import { DonorListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { DirectionIsGood } from '@src/constants/trend';
import { ExportJobViewBy, GivingDashboardCardQuery, GivingHouseholdDashboardCardQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { isGivingDashboardCardQuery } from '@src/types/QueryTypeGuards';
import { formatCountOrNull } from '@src/utils/calculateSnapshots';

import { useGetTrendProps } from '../../../hooks';
import { useHasChmsAccess } from '../../../hooks/permissions/useHasChmsAccess';
import { SnapshotDefinition } from '../types';

export const useDonorSnapshotDefinitions = (
  viewByMode: ExportJobViewBy
): SnapshotDefinition<GivingDashboardCardQuery | GivingHouseholdDashboardCardQuery>[] => {
  const { translate } = useTranslation();
  const hasChmsAccess = useHasChmsAccess();
  const getTrendProps = useGetTrendProps();

  return React.useMemo(
    () => [
      {
        listType: DonorListType.RecentNew,
        title: translate(`snapshots.${ViewByDomain.Donors}.recentNewDonors.${viewByMode}`),
        tooltip: translate(`infoText.giving.peopleList.RecentNew.${viewByMode}`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Donors].RecentNew,
            data?.Donors?.RecentNewLast?.paging.totalItemCount ?? 0,
            data?.Donors?.RecentNewPrevious?.paging.totalItemCount ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Donors?.RecentNewLast?.paging.totalItemCount),
      },
      {
        listType: DonorListType.AtRisk,
        title: translate(`snapshots.${ViewByDomain.Donors}.atRiskDonors.${viewByMode}`),
        tooltip: translate(`infoText.giving.peopleList.AtRisk.${viewByMode}`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Donors].AtRisk,
            data?.Donors?.LastData?.aggregations?.AtRisk ?? 0,
            data?.Donors?.PreviousData?.aggregations?.AtRisk ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Donors?.LastData?.aggregations?.AtRisk),
      },
      {
        listType: DonorListType.Potential,
        title: translate(`snapshots.${ViewByDomain.Donors}.potentialDonors.${viewByMode}`),
        tooltip: translate(`infoText.giving.peopleList.Potential.${viewByMode}`),
        getValue: data => {
          const potentialDonorCount = isGivingDashboardCardQuery(data)
            ? data.Donors?.LastData?.aggregations.Potential
            : data.Donors?.Potential?.paging.totalItemCount;
          return formatCountOrNull(potentialDonorCount);
        },
        isPromotional: !hasChmsAccess,
      },
    ],
    [getTrendProps, hasChmsAccess, translate, viewByMode]
  );
};
