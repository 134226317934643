import * as React from 'react';

import { ViewByDomain, VolunteerListType } from '@churchcommunitybuilder/insights-people-list-filtering';

import { DirectionIsGood } from '@src/constants/trend';
import { ServingDashboardCardQuery } from '@src/graphql/generated';
import useTranslation from '@src/translations';
import { formatCountOrNull } from '@src/utils/calculateSnapshots';

import { useGetTrendProps } from '../../../hooks';
import { SnapshotDefinition } from '../types';

export const useVolunteerSnapshotDefinitions = (): SnapshotDefinition<ServingDashboardCardQuery>[] => {
  const { translate } = useTranslation();
  const getTrendProps = useGetTrendProps();

  return React.useMemo(
    () => [
      {
        listType: VolunteerListType.RecentNew,
        title: translate(`snapshots.${ViewByDomain.Volunteers}.recentNewVolunteers`),
        tooltip: translate(`infoText.serving.peopleList.RecentNew.CommunityMember`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Volunteers].RecentNew,
            data?.Volunteers?.LastData?.aggregations?.RecentNew ?? 0,
            data?.Volunteers?.PreviousData?.aggregations?.RecentNew ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Volunteers?.LastData?.aggregations?.RecentNew),
      },
      {
        listType: VolunteerListType.AtRisk,
        title: translate(`snapshots.${ViewByDomain.Volunteers}.atRiskVolunteers`),
        tooltip: translate(`infoText.serving.peopleList.AtRisk.CommunityMember`),
        getTrend: data =>
          getTrendProps(
            DirectionIsGood[ViewByDomain.Volunteers].AtRisk,
            data?.Volunteers?.LastData?.aggregations?.AtRisk ?? 0,
            data?.Volunteers?.PreviousData?.aggregations?.AtRisk ?? 0
          ),
        getValue: data => formatCountOrNull(data?.Volunteers?.LastData?.aggregations?.AtRisk),
      },
      {
        listType: VolunteerListType.Potential,
        title: translate(`snapshots.${ViewByDomain.Volunteers}.potentialVolunteers`),
        tooltip: translate(`infoText.serving.peopleList.Potential.CommunityMember`),
        getValue: data => formatCountOrNull(data?.Volunteers?.LastData?.aggregations?.Potential),
      },
    ],
    [getTrendProps, translate]
  );
};
