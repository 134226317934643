import {
  AttendanceQueryFilters,
  DateRangeInput,
  Granularity,
  useOrganizationAttendanceOverviewWithoutPreviousQuery,
} from '@src/graphql/generated';
import { getChartDataFromOrganizationStagesOverview } from '@src/utils/chartData';

import { useAttendanceQueryFiltersWithGlobalSettingsApplied } from './useAttendanceQueryFiltersWithGlobalSettingsApplied';
import { AttendanceChartWithoutComparison } from '../types/attendance';

type AttendanceHookProperties = {
  granularity: Granularity;
  lastDateRange: DateRangeInput;
  selectedFilters: AttendanceQueryFilters;
  organizationKey: string;
};

export const useAttendanceChartWithoutComparison = ({
  granularity,
  lastDateRange,
  selectedFilters,
  organizationKey,
}: AttendanceHookProperties): AttendanceChartWithoutComparison => {
  const attendanceQueryFilters = useAttendanceQueryFiltersWithGlobalSettingsApplied(selectedFilters);

  const {
    data: rawData,
    error,
    loading,
  } = useOrganizationAttendanceOverviewWithoutPreviousQuery({
    variables: {
      organizationKey,
      attendanceOverviewInput: {
        dateRange: lastDateRange,
        granularity,
        attendanceQueryFilters,
      },
      comparisonDatesInput: {
        last: lastDateRange,
      },
    },
  });

  const { comparisonHighlight, data, highlights } = rawData?.organizationAttendanceOverview || {};

  const lastChartData = data ? getChartDataFromOrganizationStagesOverview(data) : [];

  return {
    data: {
      comparisonHighlight,
      highlights,
      lastChartData,
    },
    error,
    loading,
  };
};
