import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';

import { ComparisonHighlightData } from '@src/components/ComparisonHighlight/ComparisonHighlight';
import { OrganizationAttendanceOverview, AttendanceQueryFilters } from '@src/graphql/generated';

import { emptyFilters } from '../AttendanceOverview/consts';

export const mergeGlobalAndAttendanceMembershipTypeFilters = (
  globalMembershipTypes: MembershipTypeIdFilter | null,
  attendanceOverviewFilters?: AttendanceQueryFilters
): AttendanceQueryFilters => {
  const baseFilters = attendanceOverviewFilters || emptyFilters;
  const attendanceMembershipTypeFilterIsEmpty =
    !attendanceOverviewFilters?.membershipTypeIds ||
    (attendanceOverviewFilters.membershipTypeIds.values.length === 0 &&
      !attendanceOverviewFilters.membershipTypeIds.isEmpty);

  if (attendanceMembershipTypeFilterIsEmpty) {
    return {
      ...baseFilters,
      membershipTypeIds: globalMembershipTypes,
    };
  }

  return baseFilters;
};

export const getComparisonHighlightFromAttendanceOverview = (
  organizationAttendanceOverview: Pick<OrganizationAttendanceOverview, 'comparisonHighlight'>
): ComparisonHighlightData | undefined => {
  if (organizationAttendanceOverview?.comparisonHighlight) {
    return {
      last: organizationAttendanceOverview.comparisonHighlight.last,
      previous: organizationAttendanceOverview.comparisonHighlight.previous ?? null,
    };
  }
  return undefined;
};
