import * as React from 'react';

import { useCarousel } from '@pushpay/layout';

import useTranslation from '@src/translations';
import { Language } from '@src/types/I18n';

type useLanguagesOutput = {
  languages: Language[];
  onSelectLanguage: (newLanguage: string) => void;
  selectedLanguage: Language;
};

export const useLanguages = (onSelect?: () => void): useLanguagesOutput => {
  const { goToCarouselIndex } = useCarousel();
  const { changeLanguage, language, translate } = useTranslation();

  const languages = React.useMemo(
    () => [
      {
        children: null,
        itemValue: 'en',
        label: translate('languages.english'),
      },
      {
        children: null,
        itemValue: 'es',
        label: translate('languages.spanish'),
      },
    ],
    [translate]
  );

  const onSelectLanguage = React.useCallback(
    (newLanguage: string) => {
      changeLanguage(newLanguage);
      goToCarouselIndex(0);
      onSelect?.();
    },
    [changeLanguage, goToCarouselIndex, onSelect]
  );

  const selectedLanguage = languages.filter(lang => lang.itemValue === language)[0];

  return {
    languages,
    onSelectLanguage,
    selectedLanguage,
  };
};
