import * as React from 'react';

import { ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import { Frequency } from '@churchcommunitybuilder/js-utils';

import { getTransformedLineChartData } from '@src/components/Chart/utils/lineChartUtils';
import { TrendData } from '@src/components/SnapshotsCard/types';
import useTranslation from '@src/translations';
import { OrganizationStagesOverview } from '@src/types/OrganizationStagesOverview';
import { getChartDataFromOrganizationStagesOverview } from '@src/utils/chartData';

export const useBuildTrendChartData = () => {
  const { translate } = useTranslation();

  const getDomainLabels = React.useCallback(
    (domain: ViewByDomain) => {
      switch (domain) {
        case ViewByDomain.Attenders:
          return {
            highlightValueLabel: translate(`trendChart.${domain}.highlightValueLabel`),
            trendLabel: translate(`trendChart.${domain}.trendLabel`),
          };
        default:
          throw Error(`Unknown domain: ${domain}`);
      }
    },
    [translate]
  );

  const buildTrendChartData = React.useCallback(
    (domain: ViewByDomain, data?: OrganizationStagesOverview): TrendData => {
      const { highlightValueLabel, trendLabel } = getDomainLabels(domain);

      if (data) {
        const chartData = data ? getChartDataFromOrganizationStagesOverview(data) : [];
        const transformedData = getTransformedLineChartData(Frequency.Weekly, chartData);
        return {
          viewByDomain: domain,
          highlightValue: transformedData?.lineChartData?.[0]?.data?.[0]?.y,
          trendLabel,
          highlightValueLabel,
          data: transformedData.lineChartData,
        };
      }

      return {
        viewByDomain: domain,
        highlightValue: 0,
        trendLabel,
        highlightValueLabel,
        data: null,
      };
    },
    [getDomainLabels]
  );

  return buildTrendChartData;
};
