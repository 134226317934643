import * as React from 'react';

import { DateComparisonType, DatePeriod, RangeType } from '@churchcommunitybuilder/js-utils';

import { DateConfig } from '@src/types/DateConfig';
import { defaultDuration } from '@src/utils/defaults';

type DateConfigContextValue = {
  dateConfig: DateConfig;
  updateDateConfig: (updatedDateConfig: DateConfig) => void;
};

export const DateConfigContext = React.createContext<DateConfigContextValue | null>(null);

interface DateConfigProviderProps {
  defaultDateConfig?: DateConfig;
  setSavedDateConfig?: React.Dispatch<React.SetStateAction<DateConfig>>;
}

export const DateConfigProvider = ({
  children,
  defaultDateConfig = {
    comparison: DateComparisonType.PreviousPeriod,
    duration: defaultDuration,
    includeCurrent: false,
    period: DatePeriod.Week,
    rangeType: RangeType.Simple,
  },
  setSavedDateConfig,
}: React.PropsWithChildren<DateConfigProviderProps>) => {
  const [dateConfig, setDateConfig] = React.useState<DateConfig>(defaultDateConfig);

  const updateDateConfig = React.useCallback(
    (newDateConfig: DateConfig) => {
      if (setSavedDateConfig) {
        setSavedDateConfig(newDateConfig);
      }
      setDateConfig(newDateConfig);
    },
    [setDateConfig, setSavedDateConfig]
  );

  const value = React.useMemo(
    () => ({
      dateConfig,
      updateDateConfig,
    }),
    [dateConfig, updateDateConfig]
  );

  return <DateConfigContext.Provider value={value}>{children}</DateConfigContext.Provider>;
};
