import * as React from 'react';

import {
  CursorPagingInput,
  CursorPagingOutput,
  useMassAddToProcessQueueMutationMutation,
} from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';
import { useSendToProcessQueueContext } from '@src/pages/PeopleList/components/PeopleListActionsMenu/components/SendToProcessQueueModal/components/SendToProcessQueueContext';
import { PAGE_SIZE } from '@src/pages/PeopleList/components/PeopleListDesktopView/constants';
import { usePeopleList } from '@src/pages/PeopleList/components/PeopleListProvider';
import { convertClFilterConfigsToFilterMenuFilters } from '@src/pages/PeopleList/components/PeopleListProvider/utils';
import { TableRow } from '@src/types/PeopleList';

import { PeopleListSearchParams } from './usePeopleListTableRowsLazyQuery';
import { useCommunityMemberTableRowsLazyQuery } from './usePeopleListTableRowsLazyQuery/hooks';

type Page = {
  rows: TableRow[];
  paging: CursorPagingOutput;
};

export const useSendCurrentPeopleListToSelectedProcessQueue = () => {
  const { endDate, startDate, communityFilters, searchTerm, sort, clFilterConfigs, viewByDomain, listType } =
    usePeopleList();
  const [getTableRows] = useCommunityMemberTableRowsLazyQuery();
  const { selectedQueueId } = useSendToProcessQueueContext();
  const [massAddToProcessQueue] = useMassAddToProcessQueueMutationMutation({});
  const { key } = useMyOrganization();
  const filterMenuFilters = convertClFilterConfigsToFilterMenuFilters(clFilterConfigs);

  const getPage = React.useCallback(
    async (pagingInput: CursorPagingInput) => {
      const params: PeopleListSearchParams = {
        endDate,
        filters: communityFilters,
        searchTerm,
        sort,
        startDate,
        campusKeys: filterMenuFilters.campusKeys,
      };
      const { rows, paging } = await getTableRows(pagingInput, params, viewByDomain, listType);

      return {
        rows,
        paging,
      };
    },
    [
      endDate,
      communityFilters,
      searchTerm,
      sort,
      startDate,
      filterMenuFilters.campusKeys,
      getTableRows,
      viewByDomain,
      listType,
    ]
  );

  const getNextPage = React.useCallback(
    (paging?: CursorPagingOutput) => getPage({ size: PAGE_SIZE, after: paging?.last || '' }),
    [getPage]
  );

  const getIndividualIdsFromPage = (currentPage: Page) =>
    currentPage.rows.reduce(
      (acc, row) => (row.ccbIndividualId ? [...acc, row.ccbIndividualId.toString()] : acc),
      [] as string[]
    );

  return React.useCallback(async () => {
    let currentPage: Page | undefined;
    let individualIds: string[] = [];

    do {
      // eslint-disable-next-line no-await-in-loop
      currentPage = await getNextPage(currentPage?.paging);
      individualIds = [...individualIds, ...getIndividualIdsFromPage(currentPage)];
    } while (currentPage.paging.hasNextPage);

    if (!selectedQueueId) {
      return undefined;
    }

    return massAddToProcessQueue({
      variables: {
        organizationKey: key,
        processQueueId: selectedQueueId.toString(),
        individualIds,
      },
    });
  }, [getNextPage, key, massAddToProcessQueue, selectedQueueId]);
};
