import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { DonorListType, ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { Permissions } from '@pushpay/identity.permissions';

import { useFeature } from '@src/featureFlags';
import { TOP_DATE_FORMAT } from '@src/Giving/TopDonorsOverview/components/TopDonorsOverviewTable/constants';
import {
  CursorPagingInput,
  CursorPagingOutput,
  usePeopleListByHouseholdLazyQuery,
  useTopHouseholdDonorSummaryLazyQuery,
} from '@src/graphql/generated';
import { usePeopleListFilters } from '@src/hooks';
import { useMyOrganization, useMyPermissions } from '@src/myContext';
import { PAGE_SIZE, initializedPagingOutput } from '@src/pages/PeopleList/components/PeopleListDesktopView/constants';
import { PeopleListTableRowsResult, TableRow } from '@src/types/PeopleList';

import { getSort, mapCampusKeyToName } from './utils';
import { PeopleListSearchParams } from '../usePeopleListTableRowsLazyQuery';

export const useHouseholdTableRowsLazyQuery = (): [
  (
    pagingInput: CursorPagingInput,
    params: PeopleListSearchParams,
    viewByDomain: ViewByDomain,
    listType: ListType
  ) => Promise<PeopleListTableRowsResult>,
  PeopleListTableRowsResult
] => {
  const [error, setError] = React.useState<ApolloError>();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableRow[]>([]);
  const [paging, setPaging] = React.useState<CursorPagingOutput>(initializedPagingOutput);
  const { key, details } = useMyOrganization();
  const disablePQS = useFeature('DisablePQS');
  const isPeopleListExtraColumnsEnabled = useFeature('PeopleListExtraColumns');
  const { hasOrganizationPermission } = useMyPermissions();
  const hasGivingFullAccess = hasOrganizationPermission(Permissions.givingFullAccess);

  const getPeopleListFilters = usePeopleListFilters();
  const [getHouseholds] = usePeopleListByHouseholdLazyQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: PAGE_SIZE,
      },
      isPeopleListExtraColumnsEnabled,
    },
  });
  const [getTopDonorHouseholds] = useTopHouseholdDonorSummaryLazyQuery();

  const getRows = React.useCallback(
    async (
      pagingInput: CursorPagingInput,
      params: PeopleListSearchParams,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      viewByDomain: ViewByDomain,
      listType: ListType
    ) => {
      setLoading(true);

      if (listType === DonorListType.TopDonors) {
        const { startDate, endDate } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.TopDonors);

        if (!startDate || !endDate) {
          setLoading(false);

          return {
            loading: false,
            error: new Error('Invalid date range for Top Donors'),
            rows: [],
            paging: {
              hasNextPage: false,
              hasPreviousPage: false,
              size: pagingInput.size,
              totalItemCount: 0,
            },
          };
        }

        const { error: newError, data } = await getTopDonorHouseholds({
          variables: {
            organizationKey: key,
            cursor: {
              size: pagingInput.size,
              after: pagingInput.after,
              before: pagingInput.before,
            },
            queryFilter: {
              dateRange: {
                startDate: dateFns.format(startDate, TOP_DATE_FORMAT),
                endDate: dateFns.format(endDate, TOP_DATE_FORMAT),
              },
            },
            startDate,
            endDate,
            disablePQS,
          },
        });

        const newPaging = data?.organization?.organizationCursoredTopHouseholds?.paging || initializedPagingOutput;
        const newRows: TableRow[] =
          data?.organization?.organizationCursoredTopHouseholds?.items.map(item => ({
            id: item.householdKey,
            primaryContactIndividualId:
              item.household?.members.find(member => member.primary)?.communityMember.ccbIndividual?.individualId || '',
            name: item.household?.name || '',
            numberOfMembers: item.household?.numberOfMembers || 0,
            email: item.household?.email?.address || '',
            phone: item.household?.phoneNumber?.number || '',
            attendanceStatus: item.household?.attendanceStatus || '',
            givingStatus: item.household?.givingStatus || '',
            servingStatus: item.household?.servingStatus || '',
            homeCampusName: mapCampusKeyToName(item.household?.campusKey, details?.campuses),
            givingTotal: item.giftAmount.amount,
            numberOfGifts: item.paymentCount,
            lifeTimeGivingTotal: item.household?.lifeTimeGivingTotal || '',
            ytdLargestGiftAmount: item.household?.yearToDateLargestGiftAmount || '',
            ytdLargestGiftDate: item.household?.yearToDateLargestGiftDate
              ? dateFns.format(new Date(item.household?.yearToDateLargestGiftDate), 'dd MMM yyyy')
              : '',
            ytdLargestGiftFund: item.household?.ytdLargestGiftFund?.name || '',
            ytdLargestGiftListing: item.household?.ytdLargestGiftMerchant?.name || '',
            lastGiftAmount: item.household?.lastGiftAmount || '',
            lastGiftDate: item.household?.lastGiftDate
              ? dateFns.format(new Date(item.household?.lastGiftDate), 'dd MMM yyyy')
              : '',
            lastGiftFund: item.household?.lastGiftFund?.name || '',
            lastGiftListing: item.household?.lastGiftMerchant?.name || '',
            createdOn: item.household?.createdOn
              ? dateFns.format(new Date(item.household?.createdOn), 'dd MMM yyyy')
              : '',
          })) || [];
        setError(error);

        setRows(newRows);
        setPaging(newPaging);
        setLoading(false);

        return { loading: false, error: newError, rows: newRows, paging: newPaging };
      }

      const extraColumnsForHouseholdPeopleList =
        isPeopleListExtraColumnsEnabled && viewByDomain === 'Donors' && hasGivingFullAccess;
      const { error: newError, data } = await getHouseholds({
        variables: {
          organizationKey: key,
          campusKeys: params.campusKeys,
          paging: {
            size: pagingInput.size,
            after: pagingInput.after,
            before: pagingInput.before,
            sortings: getSort(params.sort),
          },
          queryParams: {
            endDate: params.endDate ?? undefined,
            filters: params.filters,
            search: params.searchTerm,
            startDate: params.startDate ?? undefined,
          },
          isPeopleListExtraColumnsEnabled: extraColumnsForHouseholdPeopleList,
        },
      });
      const newPaging = data?.organization?.households?.paging || initializedPagingOutput;
      const newRows: TableRow[] =
        data?.organization?.households?.items.map(item => ({
          id: item.householdKey,
          primaryContactIndividualId:
            item.members.find(member => member.primary)?.communityMember.ccbIndividual?.individualId || '',
          name: item.name,
          numberOfMembers: item.numberOfMembers,
          email: item.email?.address || '',
          phone: item.phone?.number || '',
          createdOn: item.createdOn ? dateFns.format(new Date(item.createdOn), 'dd MMM yyyy') : '',
          attendanceStatus: item.attendanceStatus || '',
          givingStatus: item.givingStatus || '',
          servingStatus: item.servingStatus || '',
          homeCampusName: mapCampusKeyToName(item.campusKey, details?.campuses),
          lifeTimeGivingTotal: item.lifeTimeGivingTotal || '',
          ytdLargestGiftAmount: item.yearToDateLargestGiftAmount || '',
          ytdLargestGiftDate: item.yearToDateLargestGiftDate
            ? dateFns.format(new Date(item.yearToDateLargestGiftDate), 'dd MMM yyyy')
            : '',
          ytdLargestGiftFund: item.ytdLargestGiftFund?.name || '',
          ytdLargestGiftListing: item.ytdLargestGiftMerchant?.name || '',
          lastGiftAmount: item.lastGiftAmount || '',
          lastGiftDate: item.lastGiftDate ? dateFns.format(new Date(item.lastGiftDate), 'dd MMM yyyy') : '',
          lastGiftFund: item.lastGiftFund?.name || '',
          lastGiftListing: item.lastGiftMerchant?.name || '',
        })) || [];
      setError(error);

      setRows(newRows);
      setPaging(newPaging);
      setLoading(false);

      return { loading: false, error: newError, rows: newRows, paging: newPaging };
    },
    [
      details?.campuses,
      disablePQS,
      error,
      getHouseholds,
      getPeopleListFilters,
      getTopDonorHouseholds,
      hasGivingFullAccess,
      isPeopleListExtraColumnsEnabled,
      key,
    ]
  );

  return [
    getRows,
    {
      error,
      loading,
      rows,
      paging,
    },
  ];
};
