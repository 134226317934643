import * as React from 'react';

import { useGetAttendanceClFilterConfigs } from '@src/Attendance/hooks';
import { useUserTimeZone } from '@src/hooks';
import useTranslation from '@src/translations';
import { CLFiltersValue, InsightsFilterConfig } from '@src/types/ChartAndHighlights';

import { useAttendanceHighlights } from './useAttendanceHighlights';
import { AttendanceOverviewContentProperties, isAttendanceChartWithComparisonData } from '../types/attendance';
import { convertCLFiltersToAttendanceFilters } from '../utils';

export function useAttendanceChartPrepData({
  attendanceData,
  selectedFilters,
  setSelectedFilters,
  granularity,
}: AttendanceOverviewContentProperties) {
  const { translate } = useTranslation();
  const { data, error, loading } = attendanceData;
  const highlights = useAttendanceHighlights(data?.highlights, granularity, selectedFilters.attendanceType);
  const clFilterConfigs = useGetAttendanceClFilterConfigs(selectedFilters);

  const timezone = useUserTimeZone();

  const updateFilters = React.useCallback(
    (filters: CLFiltersValue) => {
      const convertedFilters = convertCLFiltersToAttendanceFilters(filters, timezone);
      setSelectedFilters(convertedFilters);
    },
    [setSelectedFilters, timezone]
  );

  const filterConfig: InsightsFilterConfig | undefined = clFilterConfigs
    ? {
        filters: clFilterConfigs,
        updateFilters,
      }
    : undefined;

  const comparisonHighlightProps = {
    data: {
      last: data?.comparisonHighlight?.last ?? null,
      previous: data?.comparisonHighlight?.previous ?? null,
    },
    title: translate('widget.comparisonLabels.typeLabel.AttendanceOverview'),
  };

  const subtitle = translate('widget.title.AttendanceOverview.subtitle');
  const title = translate('widget.title.AttendanceOverview.title');

  return {
    comparisonHighlightProps,
    error,
    filterConfig,
    highlights,
    loading,
    lastChartData: data?.lastChartData ?? [],
    previousChartData: data && isAttendanceChartWithComparisonData(data) ? data.previousChartData : undefined,
    subtitle,
    title,
  };
}
