import { DateFormats, Frequency } from '@churchcommunitybuilder/js-utils';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';

import { AxisProps } from '@pushpay/charts';

import { ChartLayout, TooltipData } from '@src/components/Chart/types';
import { ChartData, ChartDataItem } from '@src/types/ChartAndHighlights';
import {
  AxisLegendPosition,
  LegendDataFrom,
  ChartLegendProps,
  GroupMode,
  LegendAnchor,
  LegendDirection,
  LegendItemDirection,
  SymbolShape,
} from '@src/types/ChartLayout';
import { formatDate } from '@src/utils';

export const getGroupByFormat = (frequency: Frequency): DateFormats | undefined => {
  // TODO: Add 'EEE' to DateFormats for periods of 7 days or less and add duration as an arg
  switch (frequency) {
    case Frequency.Daily:
    case Frequency.Weekly:
      return DateFormats.MMM_d;
    case Frequency.Monthly:
      return DateFormats.MMM;
    case Frequency.Yearly:
      return DateFormats.YYYY;
    default:
      return undefined;
  }
};

const buildFormattedTooltipDate = (date: Date): string =>
  `${formatDate(date, DateFormats.MMM_d)}, ${formatDate(date, DateFormats.YYYY)}`;

export const getPointTooltip = (lastChartPoint?: ChartDataItem, previousChartPoint?: ChartDataItem): TooltipData => {
  let tooltip: TooltipData = {};

  if (lastChartPoint) {
    tooltip = {
      ...tooltip,
      last: {
        label: buildFormattedTooltipDate(lastChartPoint.endDate),
        value: lastChartPoint.value,
        date: lastChartPoint.endDate,
      },
    };
  }

  if (previousChartPoint) {
    tooltip = {
      ...tooltip,
      previous: {
        label: buildFormattedTooltipDate(previousChartPoint.endDate),
        value: previousChartPoint.value,
        date: previousChartPoint.endDate,
      },
    };
  }

  return tooltip;
};

export const getLargerChartData = (lastChartData: ChartData, previousChartData?: ChartData): ChartData =>
  previousChartData && previousChartData.length > lastChartData.length ? previousChartData : lastChartData;

export const generateChartLabelsAndDates = (
  frequency: Frequency,
  firstEndDate: Date,
  duration: number
): { label: string; date: Date }[] => {
  const groupByFormat = getGroupByFormat(frequency);
  let dates: Date[] = Array(duration).fill(new Date());
  switch (frequency) {
    case Frequency.Daily:
      dates = dates.map((_, index) => addDays(firstEndDate, index));
      break;
    case Frequency.Monthly:
      dates = dates.map((_, index) => addMonths(firstEndDate, index));
      break;
    case Frequency.Weekly:
      dates = dates.map((_, index) => addWeeks(firstEndDate, index));
      break;
    case Frequency.Yearly:
      dates = dates.map((_, index) => addYears(firstEndDate, index));
      break;
    default:
      throw new Error('Invalid frequency');
  }

  return dates.map(date => ({ label: formatDate(date, groupByFormat), date }));
};

const K = 10 ** 3;
const M = 10 ** 6;
const B = 10 ** 9;

export const formatAxisLabel = (input: string | number | Date): string => {
  const value = +input;
  if (Number.isNaN(value)) {
    return '';
  }

  if (value >= B) {
    return `${value / B}B`;
  }

  if (value >= M) {
    return `${value / M}M`;
  }

  if (value >= K) {
    return `${value / K}K`;
  }

  return Math.floor(value) === value ? `${value}` : '';
};

export const getChartLayout = (
  axisLeftLabel: string,
  rotateXAxisLabels: boolean,
  axisBottomLabel: string,
  isMobileLayout: boolean
): ChartLayout => {
  const axisLeft: AxisProps = {
    format: formatAxisLabel,
    legend: axisLeftLabel,
    legendPosition: 'middle',
    legendOffset: -50,
    tickSize: 0,
    tickValues: 5,
  };

  const axisBottom: AxisProps = {
    tickSize: 0,
    tickPadding: 8,
    tickRotation: rotateXAxisLabels ? -45 : 0,
    legend: axisBottomLabel,
    legendPosition: 'middle',
    legendOffset: rotateXAxisLabels ? 55 : 35,
  };

  const enableLabel = false;
  const groupMode: GroupMode = GroupMode.Grouped;
  const height = 300;
  const indexBy = 'label';
  const legendPosition = AxisLegendPosition.Middle;
  const lineChartPointSize = 11;

  const legends: ChartLegendProps[] = [
    {
      dataFrom: LegendDataFrom.Keys,
      translateX: 35,
      translateY: 50,
      anchor: LegendAnchor.Bottom,
      direction: LegendDirection.Row,
      justify: false,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: LegendItemDirection.LeftToRight,
      itemOpacity: 0.85,
      itemsSpacing: 2,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1,
          },
        },
      ],
      symbolSize: 20,
      symbolShape: SymbolShape.Square,
    },
  ];

  const margin = {
    top: 8,
    right: isMobileLayout ? 16 : 32,
    bottom: rotateXAxisLabels ? 74 : 54,
    left: isMobileLayout ? 58 : 74,
  };

  const padding = 0.3;

  return {
    axisLeft,
    axisBottom,
    enableLabel,
    groupMode,
    height,
    indexBy,
    legendPosition,
    legends,
    lineChartPointSize,
    margin,
    padding,
  };
};
