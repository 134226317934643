import { ApolloError } from '@apollo/client';
import { VolunteerListType } from '@churchcommunitybuilder/insights-people-list-filtering';

export type VolunteerPeopleListTotals = Record<VolunteerListType, number>;
export type VolunteerPeopleListTotalsQueryResult = {
  error: ApolloError[];
  loading: boolean;
  totals: VolunteerPeopleListTotals;
};

export const emptyTotals: VolunteerPeopleListTotals = {
  All: 0,
  FirstTime: 0,
  SecondTime: 0,
  RecentNew: 0,
  Occasional: 0,
  Curious: 0,
  Regular: 0,
  AtRisk: 0,
  Lapsed: 0,
  Returning: 0,
  Potential: 0,
  NonVolunteers: 0,
};
