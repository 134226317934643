import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';

import Filters, { TimeRangeFilterSelection } from '@pushpay/filters';
import { t } from '@pushpay/i18n';

import { InputMaybe, MembershipType, MembershipTypeIdsInput, TimeFilterOperator } from '@src/graphql/generated';
import { CLFilterConfigs } from '@src/types/ChartAndHighlights';

import { allMembershipTypesAreSelected } from './dataCustomization';

export const noMembershipTypeId = 'No membership type';

const noMembershipTypeOption: MembershipType = {
  id: noMembershipTypeId,
  name: t('dataCustomization.membershipType.dropdown.noMembershipTypeOption'),
};

export const getMembershipTypeFilterValuesStateForCLFilters = (
  options: Map<string, string>,
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>
): string[] => {
  if (!membershipTypeIds) return [];
  let filterStateValues = membershipTypeIds.values.map(value => value.toString());
  filterStateValues = membershipTypeIds.isEmpty ? [...filterStateValues, noMembershipTypeId] : filterStateValues;
  return filterStateValues.filter(value => options.has(value));
};

export const getMembershipTypeOptionsForCLFilters = (
  chmsMembershipTypeOptions: MembershipType[],
  globalMembershipTypeFilter: MembershipTypeIdFilter | null
) => {
  let membershipTypeOptionsArray: MembershipType[];

  if (
    !globalMembershipTypeFilter ||
    allMembershipTypesAreSelected(chmsMembershipTypeOptions, globalMembershipTypeFilter)
  ) {
    membershipTypeOptionsArray = [...chmsMembershipTypeOptions, noMembershipTypeOption];
  } else {
    membershipTypeOptionsArray = globalMembershipTypeFilter.values.reduce((acc, selectedId) => {
      const matchingOption = chmsMembershipTypeOptions.find(option => option.id === selectedId.toString());
      if (matchingOption) {
        return [...acc, matchingOption];
      }
      return acc;
    }, [] as MembershipType[]);

    if (globalMembershipTypeFilter.isEmpty) {
      membershipTypeOptionsArray.push(noMembershipTypeOption);
    }
  }

  return new Map(membershipTypeOptionsArray.map(option => [option.id, option.name]));
};

export const getTimeFilterOperator = (clRelationship: TimeRangeFilterSelection<string>['relationship']) => {
  switch (clRelationship) {
    case 'is':
      return TimeFilterOperator.Is;
    case 'isAfter':
      return TimeFilterOperator.IsAfter;
    case 'isBefore':
      return TimeFilterOperator.IsBefore;
    case 'isBetween':
      return TimeFilterOperator.IsBetween;
    default:
      throw new Error('Invalid CL Relationship');
  }
};

export const isToggleTypeValues = (value: any): value is { values: string[] } => Array.isArray(value.values);

export const getFilterGroupCount = (filters: CLFilterConfigs) => {
  let filterGroupCount = 0;
  filters.forEach(value => {
    if (value) {
      if (Filters.isOptionFilterValue(value.filterState)) {
        if (Filters.isOptionFilterValueSelected(value.filterState)) filterGroupCount++;
      }

      if (Filters.isTimeFilterValue(value.filterState)) {
        if (Filters.isTimeFilterValueSelected(value.filterState)) filterGroupCount++;
      }

      // TODO: add additional logic for each of the filter values shapes
    }
  });

  return filterGroupCount;
};
