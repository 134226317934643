import {
  GivingDashboardCardQuery,
  GivingHouseholdDashboardCardQuery,
  GivingStagesQuery,
  HouseholdCountQuery,
  HouseholdGivingStagesQuery,
  PeopleCountQuery,
} from '@src/graphql/generated';

export function isPeopleCountQuery(data: PeopleCountQuery | HouseholdCountQuery): data is PeopleCountQuery {
  return (data as PeopleCountQuery).organization?.communityMembersCursor !== undefined;
}

export function isGivingDashboardCardQuery(
  data: GivingDashboardCardQuery | GivingHouseholdDashboardCardQuery
): data is GivingDashboardCardQuery {
  return (data as GivingDashboardCardQuery).AllInsightEligibleIndividuals !== undefined;
}

export function isGivingStagesQuery(data: GivingStagesQuery | HouseholdGivingStagesQuery): data is GivingStagesQuery {
  return (data as GivingStagesQuery).organization?.AllCount?.aggregations?.allPeople !== undefined;
}
