import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { DonorListType, isDonor, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import {
  AttenderListType,
  ExportJobViewBy,
  GivingDashboardCardQuery,
  GivingHouseholdDashboardCardQuery,
  useGivingDashboardCardLazyQuery,
  useGivingHouseholdDashboardCardLazyQuery,
} from '@src/graphql/generated';
import { useBuildGaugeData, usePeopleListFilters } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';
import { isGivingDashboardCardQuery } from '@src/types/QueryTypeGuards';

import { useDonorSnapshotDefinitions } from './useDonorSnapshotDefinitions';
import { useHasChmsAccess } from '../../../hooks/permissions/useHasChmsAccess';
import { useDonorRecentNewData } from '../useDonorRecentNewData';
import { useLastAndPreviousDates } from '../useLastAndPreviousDates';
import { buildSnapshotData, getGivingCount } from '../utils';

export const useGivingDashboardCard = (viewByMode: ExportJobViewBy) => {
  const { key } = useMyOrganization();

  const getPeopleListFilters = usePeopleListFilters();
  const { filters: unarchivedIndividualsFilters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);
  const { filters: potentialDonorFilters } = getPeopleListFilters(ViewByDomain.Donors, DonorListType.Potential);
  const { previousWeekComparedToTodayDateRange } = useLastAndPreviousDates();
  const donorSnapshotDefinitions = useDonorSnapshotDefinitions(viewByMode);
  const hasChmsAccess = useHasChmsAccess();
  const buildGaugeData = useBuildGaugeData();

  const donorPreviousDates = {
    startDate: previousWeekComparedToTodayDateRange.startDate,
    endDate: previousWeekComparedToTodayDateRange.endDate,
  };
  const { donorRecentNewLastDates, donorRecentNewFilters, donorRecentNewPrevDates } = useDonorRecentNewData();

  const [dashboardCardData, setDashboardCardData] = React.useState<
    GivingDashboardCardQuery | GivingHouseholdDashboardCardQuery
  >();
  const [error, setError] = React.useState<ApolloError>();
  const [loading, setLoading] = React.useState(false);

  const sharedQueryParams = {
    organizationKey: key,
    paging: {
      size: 0,
    },
    donorRecentNewLastParams: {
      endDate: donorRecentNewLastDates.endDate,
      filters: donorRecentNewFilters,
      startDate: donorRecentNewLastDates.startDate,
    },
    donorRecentNewPrevParams: {
      endDate: donorRecentNewPrevDates.endDate,
      filters: donorRecentNewFilters,
      startDate: donorRecentNewPrevDates.startDate,
    },
    donorLastQueryParams: {
      filters: [...unarchivedIndividualsFilters, isDonor],
    },
    donorPreviousQueryParams: {
      endDate: donorPreviousDates.endDate,
      filters: [...unarchivedIndividualsFilters, isDonor],
      startDate: donorPreviousDates.startDate,
    },
  };

  const [getGivingCommunityMemberDashboardCard] = useGivingDashboardCardLazyQuery({
    variables: {
      ...sharedQueryParams,
      allIndividualsQueryParams: {
        filters: unarchivedIndividualsFilters,
      },
      disableChms: !hasChmsAccess,
    },
  });

  const [getGivingHouseholdDashboardCard] = useGivingHouseholdDashboardCardLazyQuery({
    variables: {
      ...sharedQueryParams,
      allHouseholdsQueryParams: {
        filters: unarchivedIndividualsFilters,
      },
      donorPotentialQueryParams: {
        filters: potentialDonorFilters,
      },
    },
  });

  React.useEffect(() => {
    async function getNewRecurringData() {
      setLoading(true);
      if (viewByMode === ExportJobViewBy.CommunityMember) {
        const { data, error: newError } = await getGivingCommunityMemberDashboardCard();
        setDashboardCardData(data);
        setError(newError);
      } else {
        const { data, error: newError } = await getGivingHouseholdDashboardCard();
        setDashboardCardData(data);
        setError(newError);
      }
      setLoading(false);
    }

    getNewRecurringData();
  }, [getGivingCommunityMemberDashboardCard, getGivingHouseholdDashboardCard, viewByMode]);

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const allEligible =
    (dashboardCardData && isGivingDashboardCardQuery(dashboardCardData)
      ? dashboardCardData.AllInsightEligibleIndividuals?.communityMembersCursor?.aggregations.All
      : dashboardCardData?.AllInsightEligibleHouseholds?.households?.paging.totalItemCount) || null;
  const givingCount = getGivingCount(dashboardCardData);

  return {
    error,
    loading,
    data: {
      gauge: buildGaugeData(ViewByDomain.Donors, allEligible, givingCount),
      snapshots: buildSnapshotData(
        ViewByDomain.Donors,
        donorSnapshotDefinitions,
        loading,
        dashboardCardData,
        viewByMode
      ),
    },
  };
};
