import {
  AttenderListType,
  DonorListType,
  ListType,
  ViewByDomain,
  VolunteerListType,
} from '@churchcommunitybuilder/insights-people-list-filtering';

import { FilterConfig, FiltersValue } from '@pushpay/filters';
import { t } from '@pushpay/i18n';

import { ExportJobViewBy } from '@src/graphql/generated';
import { noMembershipTypeId } from '@src/utils/filters';

import { usePeopleListFilterMenuFilterOptions } from './usePeopleListFilterMenuFilterOptions';
import { PeopleListFilters } from '../types';
import { convertCLFiltersValueToFilterMenuFilters, setDisabledCLFilter } from '../utils';

export function useGetClFilterConfigs() {
  const filterMenuOptions = usePeopleListFilterMenuFilterOptions();

  return (
    viewByMode: ExportJobViewBy,
    viewByDomain: ViewByDomain,
    listType: ListType,
    clFiltersValue?: FiltersValue<PeopleListFilters>
  ): FilterConfig<PeopleListFilters>[] => {
    const selectedFilters = clFiltersValue ? convertCLFiltersValueToFilterMenuFilters(clFiltersValue) : {};
    let membershipTypeIds: (string | number)[] | undefined =
      selectedFilters?.membershipTypeIds && Array.from(selectedFilters.membershipTypeIds.values);

    if (selectedFilters?.membershipTypeIds?.isEmpty) {
      if (membershipTypeIds) {
        membershipTypeIds.push(noMembershipTypeId);
      } else {
        membershipTypeIds = [noMembershipTypeId];
      }
    }

    let clFilterConfigs: FilterConfig<PeopleListFilters>[] = [
      {
        type: 'toggle',
        field: 'membershipTypeIds',
        defaultLabel: t(`filters.labels.peopleList.membershipTypeIds`),
        options: filterMenuOptions.membershipTypeFilters,
        filterState: { values: membershipTypeIds?.map(id => id.toString()) },
        showSearch: true,
      },
    ];

    if (filterMenuOptions.campusFilters) {
      clFilterConfigs.push({
        type: 'toggle',
        field: 'campusKeys',
        defaultLabel: t(`filters.labels.peopleList.campusKeys`),
        options: filterMenuOptions.campusFilters,
        filterState: { values: selectedFilters?.campusKeys },
        showSearch: true,
      });
    }

    clFilterConfigs.push(
      {
        type: 'toggle',
        field: 'attendanceStatus',
        defaultLabel: t(`filters.labels.peopleList.attendanceStatus`),
        options: filterMenuOptions.attendanceStatusFilters,
        filterState: { values: selectedFilters?.attendanceStatus },
        showSearch: true,
      },
      {
        type: 'toggle',
        field: 'donorStatus',
        defaultLabel: t(`filters.labels.peopleList.donorStatus`),
        options: filterMenuOptions.donorStatusFilters,
        filterState: { values: selectedFilters?.donorStatus },
        showSearch: true,
      },
      {
        type: 'toggle',
        field: 'servingStatus',
        defaultLabel: t(`filters.labels.peopleList.servingStatus`),
        options: filterMenuOptions.servingStatusFilters,
        filterState: { values: selectedFilters?.servingStatus },
        showSearch: true,
      }
    );

    if (viewByMode === ExportJobViewBy.Household) {
      clFilterConfigs = setDisabledCLFilter('membershipTypeIds', clFilterConfigs, true);
    }

    if (viewByDomain === ViewByDomain.Attenders && listType !== AttenderListType.All) {
      clFilterConfigs = setDisabledCLFilter('attendanceStatus', clFilterConfigs, true);
    }

    if (viewByDomain === ViewByDomain.Donors && listType !== DonorListType.All) {
      clFilterConfigs = setDisabledCLFilter('donorStatus', clFilterConfigs, true);
    }

    if (viewByDomain === ViewByDomain.Volunteers && listType !== VolunteerListType.All) {
      clFilterConfigs = setDisabledCLFilter('servingStatus', clFilterConfigs, true);
    }

    return clFilterConfigs;
  };
}
