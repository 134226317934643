import { useFeature } from '@src/featureFlags';
import { useMyOrganization } from '@src/myContext';

export const useHasChmsAccess = () => {
  const { details } = useMyOrganization();
  const isDonorDevOnlyEnabled = useFeature('DonorDevOnly');
  const organizationHasChms = !!details?.productInformation?.chmsInformation?.tenantUrl;

  return isDonorDevOnlyEnabled ? organizationHasChms : true;
};
