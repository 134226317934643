import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';

import { MembershipType } from '@src/graphql/generated';

export const allMembershipTypesAreSelected = (
  chmsMembershipTypeOptions: MembershipType[],
  membershipTypeIdFilter: MembershipTypeIdFilter | null
) =>
  !membershipTypeIdFilter ||
  (membershipTypeIdFilter.values.length === chmsMembershipTypeOptions.length && membershipTypeIdFilter.isEmpty) ||
  (membershipTypeIdFilter.values.length === 0 && !membershipTypeIdFilter.isEmpty);
