import { MembershipTypeIdFilter } from '@churchcommunitybuilder/insights-people-list-filtering';

import { CcbMembershipTypeFilterInput, FilterMethod } from '@src/graphql/generated';

export const getMembershipTypeFilterForPQS = (
  selectedMembershipTypes: MembershipTypeIdFilter | null
): CcbMembershipTypeFilterInput => {
  let filterMethod;

  if (!selectedMembershipTypes) {
    return {
      filterMethod: FilterMethod.Any,
    };
  }

  if (selectedMembershipTypes.isEmpty === false) {
    // NOTE: Business rules for the UI do not allow an empty values array and isEmpty to be false;
    if (selectedMembershipTypes.values.length === 0) {
      throw new Error('property options with empty values array and isEmpty set to false is not allowed');
    }
    filterMethod = FilterMethod.HasMembershipType;
  } else {
    filterMethod = FilterMethod.Any;
    if (selectedMembershipTypes.values.length === 0) {
      filterMethod = FilterMethod.NoMembershipType;
    }
  }

  const filter: CcbMembershipTypeFilterInput = {
    ccbMembershipTypeIds: selectedMembershipTypes.values,
    filterMethod,
  };
  return filter;
};
