import { useLocalStorage } from '@pushpay/utils';

import { ExportJobViewBy } from '@src/graphql/generated';

export const useGlobalViewByMode = () => {
  const [viewByMode, setViewByMode] = useLocalStorage<ExportJobViewBy>(
    'insights/viewByMode',
    ExportJobViewBy.CommunityMember
  );

  return {
    viewByMode,
    setViewByMode,
  };
};
