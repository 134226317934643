import { t } from '@pushpay/i18n';

import { simplifyComparisonValues } from '@src/utils';

export const formatGivingRatio = (data: number[]): string => {
  const { numerator, denominator } = simplifyComparisonValues(data[0], data[1]);

  if (numerator === '0' && denominator === '0') {
    return t('noData');
  }

  if (numerator === '0' || denominator === '0') {
    return t('incompleteData');
  }

  return `${numerator} : ${denominator}`;
};
