import { getLastNCompletedWeeks, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';
import * as dateFns from 'date-fns';

import { AttenderListType, Granularity, useAttendanceDashboardTilesQuery } from '@src/graphql/generated';
import { usePeopleListFilters, useUserTimeZone } from '@src/hooks';
import { useMyOrganization } from '@src/myContext';

import { useAttenderTileDefinitions } from './useAttenderTileDefinitions';
import { buildSnapshotData } from '../utils';

export const useAttendanceTiles = () => {
  const { key } = useMyOrganization();
  const attenderTileDefinitions = useAttenderTileDefinitions();

  const getPeopleListFilters = usePeopleListFilters();
  const { filters } = getPeopleListFilters(ViewByDomain.Attenders, AttenderListType.All);

  const timeZone = useUserTimeZone();
  const { startDate: last52WeeksStartDate, endDate: last52WeeksEndDate } = getLastNCompletedWeeks(52);
  const { startDate: attendanceChurnLastStartDate, endDate: attendanceChurnLastEndDate } = getLastNCompletedWeeks(13);
  const { startDate: attendanceChurnPrevStartDate } = getLastNCompletedWeeks(26);
  const attendanceChurnLastDates = {
    startDate: attendanceChurnLastStartDate,
    endDate: attendanceChurnLastEndDate,
  };
  const attendanceChurnPrevDates = {
    startDate: attendanceChurnPrevStartDate,
    endDate: dateFns.subMilliseconds(attendanceChurnLastStartDate, 1),
  };
  const attendanceChurnRegularLastDates = {
    startDate: dateFns.subMilliseconds(attendanceChurnLastEndDate, 1),
    endDate: attendanceChurnLastDates.endDate,
  };
  const attendanceChurnRegularPrevDates = {
    startDate: dateFns.subMilliseconds(attendanceChurnLastStartDate, 1), // TODO - verify use of LastStart ???
    endDate: attendanceChurnPrevDates.endDate,
  };

  if (!key) {
    throw new Error('No organization key provided.');
  }

  const { data, error, loading } = useAttendanceDashboardTilesQuery({
    variables: {
      organizationKey: key,
      paging: {
        size: 0,
      },
      attendanceChurnLapsedLastParams: {
        endDate: attendanceChurnLastDates.endDate,
        filters,
        startDate: attendanceChurnLastDates.startDate,
      },
      attendanceChurnLapsedPrevParams: {
        endDate: attendanceChurnPrevDates.endDate,
        filters,
        startDate: attendanceChurnPrevDates.startDate,
      },
      attendanceChurnRegularLastParams: {
        endDate: attendanceChurnRegularLastDates.endDate,
        filters,
        startDate: attendanceChurnRegularLastDates.startDate,
      },
      attendanceChurnRegularPrevParams: {
        endDate: attendanceChurnRegularPrevDates.endDate,
        filters,
        startDate: attendanceChurnRegularPrevDates.startDate,
      },
      potentialInactiveQueryParams: {
        filters,
      },
      attendanceOverviewInput: {
        dateRange: {
          startDate: last52WeeksStartDate,
          endDate: last52WeeksEndDate,
          timeZoneId: timeZone,
        },
        granularity: Granularity.Weekly,
      },
    },
  });

  return {
    error,
    loading,
    data: buildSnapshotData(ViewByDomain.Attenders, attenderTileDefinitions, loading, data),
  };
};
