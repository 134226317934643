import { ListType, ViewByDomain } from '@churchcommunitybuilder/insights-people-list-filtering';

import { FiltersValue, FilterConfig, OptionFilterValue, MultiSelectOptionFilterValue } from '@pushpay/filters';
import { TimeFilterValue } from '@pushpay/filters/lib/types/filters';

import { ComparisonHighlightData } from '@src/components/ComparisonHighlight/ComparisonHighlight';
import { ExportJobViewBy } from '@src/graphql/generated';

export type Colors = {
  light: string;
  medium: string;
  dark: string;
};

export type Highlight = {
  listType?: ListType;
  title: string;
  tooltip: string;
  value: string | null;
  viewByDomain?: ViewByDomain;
  viewByMode?: ExportJobViewBy;
};

export type AvailableFilters = {
  [filter: string]: OptionFilterValue<string> | TimeFilterValue<string> | MultiSelectOptionFilterValue<string>;
};

export type CLFilterConfigs = FilterConfig<AvailableFilters>[];
export type CLFiltersValue = FiltersValue<AvailableFilters>;

export type InsightsFilterConfig = {
  filters: CLFilterConfigs;
  updateFilters: (filterValues: CLFiltersValue) => void;
};

export type ChartDataItem = {
  startDate: Date;
  endDate: Date;
  value: number;
};

export type ChartData = ChartDataItem[];

export type ComparisonHighlightProps = {
  data?: ComparisonHighlightData;
  title: string;
};

export enum DataDisplayType {
  Currency,
  Number,
  Percentage,
  Ratio,
}
