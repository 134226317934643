import * as React from 'react';

import { ExportJob, useDeleteExportJobMutation } from '@src/graphql/generated';
import { useMyOrganization } from '@src/myContext';

export const useDeleteExportJobs = () => {
  const { key: organizationKey } = useMyOrganization();
  const [onDeleteExportJob] = useDeleteExportJobMutation();

  const onDeleteExportJobs = React.useCallback(
    (exportJobs?: ExportJob[]) =>
      exportJobs &&
      Promise.all(
        exportJobs.map(job =>
          onDeleteExportJob({
            variables: {
              organizationKey,
              deleteExportJobInput: {
                jobKey: job.jobKey,
              },
            },
          })
        )
      ),
    [onDeleteExportJob, organizationKey]
  );

  return onDeleteExportJobs;
};
